const joinObjectValues = (arrayOfObjects, key, sep, isArr) => {
  if (
    arrayOfObjects === null ||
    arrayOfObjects === undefined ||
    arrayOfObjects?.length === 0
  ) {
    return '';
  }

  return arrayOfObjects
    .filter(obj => obj?.hasOwnProperty(key))
    .map(obj => obj[key])
    .join(sep === undefined ? ', ' : sep);
};

export default joinObjectValues;
