import React from 'react';
import {
  Icon,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CustomMultiSelectPickerBlock from '../components/CustomMultiSelectPickerBlock';
import CustomRangeDatePickerBlock from '../components/CustomRangeDatePickerBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import LoadingBlock from '../components/LoadingBlock';
import LoadingNextPageBlock from '../components/LoadingNextPageBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as ReadMoreText from '../custom-files/ReadMoreText';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import getColorForStage from '../global-functions/getColorForStage';
import joinObjectValues from '../global-functions/joinObjectValues';
import toastMessage from '../global-functions/toastMessage';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const CFSListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [cfs, setCfs] = React.useState(false);
  const [cfsList, setCfsList] = React.useState([]);
  const [country_filter, setCountry_filter] = React.useState([]);
  const [date_range_filter, setDate_range_filter] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [end_date_filter, setEnd_date_filter] = React.useState('');
  const [firstAddedFilter, setFirstAddedFilter] = React.useState([]);
  const [gics, setGics] = React.useState('');
  const [gics_filter, setGics_filter] = React.useState([]);
  const [gics_options, setGics_options] = React.useState('');
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [lastUpdatedFilter, setLastUpdatedFilter] = React.useState([]);
  const [lastupdated_end, setLastupdated_end] = React.useState('');
  const [lastupdated_start, setLastupdated_start] = React.useState('');
  const [loadingNextPage_temp, setLoadingNextPage_temp] = React.useState(false);
  const [nextPage, setNextPage] = React.useState(0);
  const [numRecs, setNumRecs] = React.useState('');
  const [region_filter, setRegion_filter] = React.useState([]);
  const [screenCode, setScreenCode] = React.useState('');
  const [stage_filter, setStage_filter] = React.useState([]);
  const [start_date_filter, setStart_date_filter] = React.useState('');
  const [status_filter, setStatus_filter] = React.useState('');
  const [suggestionGics, setSuggestionGics] = React.useState([]);
  const [suggestionTargets, setSuggestionTargets] = React.useState([]);
  const [target, setTarget] = React.useState('');
  const convArr = (arr, sep) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    return arr.join(sep);
  };
  const adminGroupDeleteCFSDELETE = AdminGroupApi.useDeleteCFSDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      /* hidden 'Set Variable' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('CFSNavigator', {
                screen: 'CFSCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'Add CFS'}
          showAddButton={true}
        />
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  marginTop: { minWidth: Breakpoints.Laptop, value: 0 },
                  maxWidth: 1200,
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 20 },
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                    marginBottom: { minWidth: Breakpoints.Laptop, value: 5 },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                      } else {
                      }

                      setKeywordSearch(newTextInputValue);
                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
                {/* target search */}
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        const resultTargets = (
                          await AdminGroupApi.getCFSGET(Constants, {
                            page: 1,
                            target: searchKeyword,
                          })
                        )?.json;
                        const suggestion_targets = transformToSearchInputFormat(
                          resultTargets?.items,
                          'company',
                          'id',
                          undefined
                        );
                        setSuggestionTargets(suggestion_targets);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onClear={() => {
                    try {
                      setTarget(null);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onSelect={selectedResult => {
                    try {
                      setTarget(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  customPlaceholder={'Target search...'}
                  defaultValue={gics}
                  multiSelect={false}
                  selectedItem={target}
                  suggestionsList={suggestionTargets}
                />
              </View>
              {/* Filter Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                {/* Stage */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Stage'}
                  </Text>
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setStage_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['CFS_STAGE_OPTIONS']}
                    placeholder={'Select stage'}
                    selectedOptions={stage_filter}
                  />
                </View>
                {/* Country */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Country'}
                  </Text>
                  {/* Custom Multi Select Picker 2 */}
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setCountry_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['COUNTRY_OPTIONS']}
                    placeholder={'Select country'}
                    selectedOptions={country_filter}
                  />
                </View>
                {/* Region */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Region'}
                  </Text>
                  {/* Custom Multi Select Picker 2 */}
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setRegion_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['REGION_OPTIONS']}
                    placeholder={'Select region'}
                    selectedOptions={region_filter}
                  />
                </View>
              </View>
              {/* Filter Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                {/* Date */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'First added'}
                  </Text>
                  <CustomRangeDatePickerBlock
                    onChange={(startDate, endDate) => {
                      try {
                        setStart_date_filter(startDate);
                        setEnd_date_filter(endDate);
                        setNextPage(1);
                        if (startDate === null || endDate === null) {
                          return;
                        }

                        const filtertest = []
                          .concat([
                            formatTimestampToCopenhagen(startDate, 'start'),
                          ])
                          .concat([
                            formatTimestampToCopenhagen(endDate, 'end'),
                          ]);
                        setFirstAddedFilter(filtertest);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    endDate={end_date_filter}
                    startDate={start_date_filter}
                  />
                </View>
                {/* Date 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Last updated'}
                  </Text>
                  <CustomRangeDatePickerBlock
                    onChange={(startDate, endDate) => {
                      try {
                        setLastupdated_start(startDate);
                        setLastupdated_end(endDate);
                        setNextPage(1);
                        if (startDate === null || endDate === null) {
                          return;
                        }

                        const lastFilter = []
                          .concat([
                            formatTimestampToCopenhagen(startDate, 'start'),
                          ])
                          .concat([
                            formatTimestampToCopenhagen(endDate, 'end'),
                          ]);
                        setLastUpdatedFilter(lastFilter);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    endDate={lastupdated_end}
                    startDate={lastupdated_start}
                  />
                </View>
                {/* Blank */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { gap: { minWidth: Breakpoints.Laptop, value: 5 } },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Table Row'].style,
                          {
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_600SemiBold',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Reset filter'}
                    </Text>

                    <Pressable
                      onPress={() => {
                        try {
                          setStatus_filter([]);
                          setCountry_filter([]);
                          setRegion_filter([]);
                          setDate_range_filter([]);
                          setGics_filter([]);
                          setStart_date_filter(null);
                          setEnd_date_filter(null);
                          setKeywordSearch('');
                          setFirstAddedFilter([]);
                          setLastUpdatedFilter([]);
                          setStage_filter([]);
                          setLastupdated_start(null);
                          setLastupdated_end(null);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors.branding.primary,
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 8,
                            },
                            height: { minWidth: Breakpoints.Laptop, value: 50 },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            width: { minWidth: Breakpoints.Laptop, value: 50 },
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={StyleSheet.getWidthValue(
                            [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.White,
                              },
                            ],
                            dimensions.width
                          )}
                          name={'MaterialCommunityIcons/window-close'}
                          size={StyleSheet.getWidthValue(
                            [{ minWidth: Breakpoints.Laptop, value: 26 }],
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <>
            {!numRecs ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Table Row'].style,
                    theme.typography.body1,
                    {
                      alignSelf: {
                        minWidth: Breakpoints.Laptop,
                        value: 'flex-start',
                      },
                      marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {numRecs}
                {' records matching filter'}
              </Text>
            )}
          </>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                marginTop: { minWidth: Breakpoints.Tablet, value: 15 },
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                    marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                    marginRight: { minWidth: Breakpoints.Laptop, value: 34 },
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['table_header'].style,
                      {
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.4 },
                          { minWidth: Breakpoints.Laptop, value: 0.2 },
                        ],
                        flexDirection: 'row',
                        maxWidth: 150,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Created */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['table_header'].style,
                          {
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.5 },
                              { minWidth: Breakpoints.Laptop, value: 0.4 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Created'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Company */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['table_header'].style,
                      { flex: 0.5 }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Company'}
                  </Text>
                </View>
                {/* Owners */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['table_header'].style,
                          { flex: 0.5 }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Owners'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Stage */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['table_header'].style,
                      {
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.5 },
                          { minWidth: Breakpoints.Laptop, value: 0.4 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Stage'}
                  </Text>
                </View>
                {/* Country */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['table_header'].style,
                          {
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.5 },
                              { minWidth: Breakpoints.Laptop, value: 0.4 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Country'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Main Event */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['table_header'].style,
                          { flex: 0.5 }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Main Event'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Other Events */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)['table_header'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['table_header'].style,
                          { flex: 0.5 }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Other Events'}
                      </Text>
                    </View>
                  )}
                </>
              </View>
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetCFSGET
                  country={country_filter}
                  first_added={firstAddedFilter}
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== cfsList) {
                          setCfsList(fetchData?.json?.items);
                        } else {
                        }

                        /* hidden 'Log to Console' action */
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                        setNumRecs(fetchData?.json?.itemsTotal);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword_search={keywordSearch}
                  last_updated={lastUpdatedFilter}
                  page={1}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  region={region_filter}
                  retry={true}
                  stage={stage_filter}
                  staleTime={0}
                  target={target?.title}
                >
                  {({ loading, error, data, refetchGetCFS }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <>
                          {!(cfsList?.length > 0) ? null : (
                            <SimpleStyleFlatList
                              data={cfsList}
                              decelerationRate={'normal'}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(listData)
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={
                                'Linear Gradient->View->Table->Container->Table Content->Fetch->List'
                              }
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReached={() => {
                                const handler = async () => {
                                  try {
                                    /* hidden 'Log to Console' action */
                                    if (nextPage === null) {
                                      return;
                                    }
                                    setLoadingNextPage_temp(true);
                                    const newData = (
                                      await AdminGroupApi.getCFSGET(Constants, {
                                        country: country_filter,
                                        first_added: firstAddedFilter,
                                        gics_sector: gics_filter,
                                        gics_sub_industry: gics_filter,
                                        keyword_search: keywordSearch,
                                        last_updated: lastUpdatedFilter,
                                        page: nextPage,
                                        region: region_filter,
                                        stage: stage_filter,
                                        target: target?.title,
                                      })
                                    )?.json;
                                    setNextPage(newData?.nextPage);
                                    setLastPage(newData?.pageTotal);
                                    setLoadingNextPage_temp(false);
                                    if (
                                      fetchData?.items ===
                                      (0 || cfsList !== fetchData?.items)
                                    ) {
                                      return;
                                    }
                                    setCfsList(cfsList.concat(newData?.items));
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              pagingEnabled={false}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'auto',
                                        },
                                        backgroundColor: getColorByIndex(index),
                                        borderColor: palettes.App.Outline,
                                        borderLeftWidth: 1,
                                        flexDirection: 'row',
                                        marginLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                        marginRight: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Actions */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.4,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.2,
                                            },
                                          ],
                                          flexDirection: 'row',
                                          height: '100%',
                                          justifyContent: 'center',
                                          maxWidth: 150,
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <TableActionsBlock
                                        onPressDelete={() => {
                                          try {
                                            setDeletingItem(listData);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        onPressEdit={() => {
                                          try {
                                            navigation.navigate(
                                              'CFSNavigator',
                                              {
                                                screen: 'CFSEditScreen',
                                                params: { id: listData?.id },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        border={false}
                                      />
                                    </View>
                                    {/* Created */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.4,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.created_at}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Company */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.company}
                                      </Text>
                                    </View>
                                    {/* Owners */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.5,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {joinObjectValues(
                                              listData?.owners,
                                              'name',
                                              undefined,
                                              false
                                            )}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Stage */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.4,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Table Row'
                                                ].style,
                                                {
                                                  color: getColorForStage(
                                                    listData?.stage
                                                  ),
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.stage}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Country */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.5,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.4,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.country}
                                      </Text>
                                    </View>
                                    {/* Main Event */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Utils.CustomCodeErrorBoundary>
                                        <ReadMoreText.Index>
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.main_event_n}
                                          </Text>
                                        </ReadMoreText.Index>
                                      </Utils.CustomCodeErrorBoundary>
                                    </View>
                                    {/* Other Events */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Utils.CustomCodeErrorBoundary>
                                        <ReadMoreText.Index>
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convArr(
                                              listData?.other_events_n,
                                              '\n\n'
                                            )}
                                          </Text>
                                        </ReadMoreText.Index>
                                      </Utils.CustomCodeErrorBoundary>
                                    </View>
                                  </View>
                                );
                              }}
                              snapToAlignment={'start'}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: {
                                    minWidth: Breakpoints.Laptop,
                                    value:
                                      dimensions.width >= Breakpoints.Laptop
                                        ? 0
                                        : undefined,
                                  },
                                  maxHeight: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: dimensions.height - 250,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: dimensions.height - 280,
                                    },
                                  ],
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* loadingNextPageN */}
                        <>
                          {!loadingNextPage_temp ? null : (
                            <LoadingNextPageBlock />
                          )}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetCFSGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteCFS = (
                  await adminGroupDeleteCFSDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteCFS?.error) {
                  toastMessage(resultDeleteCFS?.message, 'error');
                } else {
                  toastMessage('Delete CFS successfully', 'success');
                  setDeletingItem(null);
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'CFS'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CFSListingScreen);
