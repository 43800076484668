/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: true,
  prefixes: [prefix],
  config: {
    screens: {
      path: '',
      initialRouteName: 'SplashScreen',
      SplashScreen: 'splash',

      ResetNavigator: {
        initialRouteName: 'ResetPasswordScreen',
        screens: {
          ResetPasswordScreen: 'reset_password',
        },
      },

      EventNavigator: {
        initialRouteName: 'EventListingScreen',
        screens: {
          EventListingScreen: 'event',
          EventEditScreen: {
            path: 'event/:id',
            parse: { id: id => id },
          },
          EventCreateScreen: 'event/create',
        },
      },

      NewsletterNavigator: {
        initialRouteName: 'NewsletterListingScreen',
        screens: {
          NewsletterListingScreen: 'newsletter',
          NewsletterEditScreen: {
            path: 'newsletter/:id',
            parse: { id: id => id },
          },
          NewsletterCreateScreen: 'newsletter/create',
        },
      },

      POTDNavigator: {
        initialRouteName: 'POTDListingScreen',
        screens: {
          POTDListingScreen: 'potd',
          POTDEditScreen: {
            path: 'potd/:id',
            parse: { id: id => id },
          },
          POTDCreateScreen: 'potd/create',
        },
      },

      PeerGroupNavigator: {
        initialRouteName: 'PeerGroupListingScreen',
        screens: {
          PeerGroupListingScreen: 'peer_group',
          PeerGroupEditScreen: {
            path: 'peer_group/:id',
            parse: { id: id => id },
          },
          PeerGroupCreateScreen: 'peer_group/create',
        },
      },

      CFSNavigator: {
        initialRouteName: 'CFSListingScreen',
        screens: {
          CFSListingScreen: 'cfs',
          CFSEditScreen: {
            path: 'cfs/:id',
            parse: { id: id => id },
          },
          CFSCreateScreen: 'cfs/create',
        },
      },

      PEPFNavigator: {
        initialRouteName: 'PEPFListingScreen',
        screens: {
          PEPFListingScreen: 'pepf',
          PEPFEditScreen: {
            path: 'pepf/:id',
            parse: { id: id => id },
          },
          PEPFCreateScreen: 'pepf/create',
        },
      },

      FundNavigator: {
        initialRouteName: 'FundListingScreen',
        screens: {
          FundListingScreen: 'fund',
          FundEditScreen: {
            path: 'fund/:id',
            parse: { id: id => id },
          },
          FundCreateScreen: 'fund/create',
        },
      },

      InvestorNavigator: {
        initialRouteName: 'InvestorListingScreen',
        screens: {
          InvestorListingScreen: 'investor',
          InvestorEditScreen: {
            path: 'investor/:id',
            parse: { id: id => id },
          },
          InvestorCreateScreen: 'investor/create',
        },
      },

      AdvisorNavigator: {
        initialRouteName: 'AdvisorListingScreen',
        screens: {
          AdvisorListingScreen: 'advisor',
          AdvisorEditScreen: {
            path: 'advisor/:id',
            parse: { id: id => id },
          },
          AdvisorCreateScreen: 'advisor/create',
        },
      },

      CrawlerSourceNavigator: {
        initialRouteName: 'CrawlerSourceListingScreen',
        screens: {
          CrawlerSourceListingScreen: 'crawler_source',
          CrawlerSourceEditScreen: {
            path: 'crawler_source/:id',
            parse: { id: id => id },
          },
          CrawlerSourceCreateScreen: 'crawler_source/create',
        },
      },

      CrawlerKeywordNavigator: {
        initialRouteName: 'CrawlerKeywordListingScreen',
        screens: {
          CrawlerKeywordListingScreen: 'crawler_keyword',
          CrawlerKeywordEditScreen: {
            path: 'crawler_keyword/:id',
            parse: { id: id => id },
          },
          CrawlerKeywordCreateScreen: 'crawler_keyword/create',
        },
      },

      CrawlerResultNavigator: {
        initialRouteName: 'CrawlerResultListingScreen',
        screens: {
          CrawlerResultListingScreen: 'crawler_result',
          CrawlerResultEditScreen: {
            path: 'crawler_result/:id',
            parse: { id: id => id },
          },
          CrawlerResultCreateScreen: 'crawler_result/create',
        },
      },

      PropCaseNavigator: {
        initialRouteName: 'PropCaseListingScreen',
        screens: {
          PropCaseListingScreen: 'prop_case',
          PropCaseEditScreen: {
            path: 'prop_case/:id',
            parse: { id: id => id },
          },
          PropCaseCreateScreen: 'prop_case/create',
        },
      },

      TrendingTopicNavigator: {
        initialRouteName: 'TrendingTopicListingScreen',
        screens: {
          TrendingTopicListingScreen: 'trending_topic',
          TrendingTopicEditScreen: {
            path: 'trending_topic/:id',
            parse: { id: id => id },
          },
          TrendingTopicCreateScreen: 'trending_topic/create',
        },
      },

      UsersClientsNavigator: {
        initialRouteName: 'UsersClientsListingScreen',
        screens: {
          UsersClientsListingScreen: 'users_clients',
          UsersClientsEditScreen: {
            path: 'users_clients/:id',
            parse: { id: id => id },
          },
          UsersClientsCreateScreen: 'users_clients/create',
        },
      },

      PropScheduleNavigator: {
        initialRouteName: 'PropScheduleListingScreen',
        screens: {
          PropScheduleListingScreen: 'prop_schedule',
          PropScheduleEditScreen: {
            path: 'prop_schedule/:id',
            parse: { id: id => id },
          },
          PropScheduleCreateScreen: 'prop_schedule/create',
        },
      },
    },
  },
};

export default linking;
