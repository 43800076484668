import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  View,
  TextInput,
  FlatList,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from 'react-native';
import { useOverlay } from './OverlayContext';
import { debounce } from 'lodash';
import { List, arrayMove, arrayRemove } from 'react-movable';

import { MaterialIcons } from '@expo/vector-icons';

const Index = ({
  options = [],
  onSelect = () => {},
  customPlaceholder = customPlaceholder,
  // props = props,
  selectedItem = null,
  selectedItems: initialSelectedItems = [],
  getSuggestions = () => {},
  onClear = () => {},
  multiSelect = true,
  reorder = reorder,
}) => {
  const [query, setQuery] = useState(() => {
    if (!multiSelect && selectedItem) {
      return selectedItem.title;
    }
    return '';
  });
  const [filteredData, setFilteredData] = useState(options);
  const [placeholdertouse, setplaceholdertouse] = useState(customPlaceholder);
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const { setDropdown, closeDropdown } = useOverlay();
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputContainerRef = useRef(null);
  const [inputContainerWidth, setInputContainerWidth] = useState(0);

  React.useEffect(() => {
    setFilteredData(options);
  }, [options]);

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.measure((x, y, width) => {
        setInputContainerWidth(width);
      });
    }
  }, []);

  const debouncedGetSuggestions = useCallback(
    debounce(text => {
      setIsLoading(true);
      getSuggestions(text);
    }, 300),
    [getSuggestions]
  );

  const handleSearch = useCallback(
    text => {
      setQuery(text);
      setIsLoading(true);
      debouncedGetSuggestions(text);
    },
    [debouncedGetSuggestions]
  );

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [filteredData]);

  const handleSelect = useCallback(
    item => {
      console.log('handleSelect being triggered');
      if (multiSelect) {
        setSelectedItems(prev => {
          const isAlreadySelected = prev.some(
            selected => selected.id === item.id
          );
          let updatedItems;
          if (isAlreadySelected) {
            updatedItems = prev.filter(selected => selected.id !== item.id);
          } else {
            updatedItems = [...prev, item];
          }
          onSelect(updatedItems);
          return updatedItems;
        });
        setIsFocused(true);
        showDropdown();
        setQuery('');
      } else {
        onSelect(item);
        setQuery(item.title);
        setSelectedItems([item]);
        setIsFocused(false);
        closeDropdown();
      }
    },
    [onSelect, closeDropdown, multiSelect]
  );

  const showDropdown = useCallback(() => {
    if (inputContainerRef.current && inputContainerWidth > 0) {
      inputContainerRef.current.measureInWindow((x, y, width, height) => {
        const windowHeight = window.innerHeight;
        const spaceBelow = windowHeight - (y + height);
        const spaceAbove = y;
        const dropdownHeight = Math.min(300, filteredData.length * 44 + 20);
        const showAbove =
          spaceBelow < dropdownHeight && spaceAbove > spaceBelow;

        setDropdown(
          <TouchableWithoutFeedback
            onPress={() => {
              setIsFocused(false);
              closeDropdown();
            }}
          >
            <View style={StyleSheet.absoluteFill}>
              <View style={styles.blackOverlay} />
              <TouchableWithoutFeedback>
                <View
                  style={[
                    styles.dropdown,
                    {
                      // If showing above, position from bottom of input
                      ...(showAbove
                        ? { bottom: windowHeight - y }
                        : { top: y + height }),
                      left: x,
                      width: inputContainerWidth,
                      maxHeight: Math.min(
                        showAbove ? spaceAbove : spaceBelow,
                        300
                      ),
                    },
                  ]}
                >
                  {isLoading ? (
                    <ActivityIndicator style={styles.loadingIndicator} />
                  ) : filteredData.length > 0 ? (
                    <FlatList
                      data={filteredData}
                      keyExtractor={item => item.id.toString()}
                      renderItem={({ item }) => (
                        <TouchableOpacity
                          style={[
                            styles.itemContainer,
                            selectedItems.some(
                              selected => selected.id === item.id
                            ) && styles.selectedItemContainer,
                          ]}
                          onPress={() => handleSelect(item)}
                        >
                          <Text style={styles.itemTitle}>{item.title}</Text>
                          {selectedItems.some(
                            selected => selected.id === item.id
                          ) && <Text style={styles.selectedLabel}>✓</Text>}
                        </TouchableOpacity>
                      )}
                      extraData={selectedItems} // Add this line
                    />
                  ) : (
                    <Text style={styles.noResults}>No results found</Text>
                  )}
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        );
      });
    }
  }, [
    filteredData,
    setDropdown,
    handleSelect,
    selectedItems,
    isLoading,
    inputContainerWidth,
    closeDropdown,
  ]);

  useEffect(() => {
    if (isFocused) {
      showDropdown();
    }
  }, [isFocused, showDropdown, closeDropdown]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    showDropdown();
  }, [showDropdown]);

  const handleClear = useCallback(() => {
    setIsFocused(false);
    selectedItem = null;
    setSelectedItems([]);
    setQuery('');
    handleSearch('');
    onClear();
  }, [selectedItem]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    if (multiSelect == false) {
      handleSearch('');
      setQuery(selectedItem.title);
    }
  }, [selectedItem]);

  const handleMoveList = React.useCallback(
    (oldIndex, newIndex) => {
      setSelectedItems(arrayMove(selectedItems, oldIndex, newIndex));
      onSelect(arrayMove(selectedItems, oldIndex, newIndex));
    },
    [setSelectedItems, selectedItems, onSelect]
  );
  console.log(selectedItems);

  const arrayFromObj = function (obj, key) {
    let tempArr = [];
    for (let i in obj) {
      tempArr.push(obj[i][key]);
    }
    return tempArr;
  };

  const RemovableIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#555"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x-circle"
    >
      <title>Remove</title>
      <circle cx="12" cy="12" r="10" />
      <line x1="15" y1="9" x2="9" y2="15" />
      <line x1="9" y1="9" x2="15" y2="15" />
    </svg>
  );
  const buttonStyles = {
    border: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    overflow: 'visible',
    cursor: 'pointer',
    background: 'transparent',
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer} ref={inputContainerRef}>
        <TextInput
          ref={inputRef}
          style={[
            styles.input,
            !multiSelect && selectedItems.length > 0 && styles.selectedInput,
          ]}
          value={query}
          onChangeText={handleSearch}
          placeholder={placeholdertouse}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholderTextColor="#A5ADB7FF"
        />
        {selectedItems.length > 0 && (
          <TouchableOpacity onPress={handleClear} onClick={handleClear}>
            <MaterialIcons name="clear" size={20} color="gray" />
          </TouchableOpacity>
        )}
      </View>
      {selectedItems.length > 0 && (
        <List
          lockVertically
          values={arrayFromObj(selectedItems, 'title')}
          onChange={({ oldIndex, newIndex }) =>
            handleMoveList(oldIndex, newIndex)
          }
          renderList={({ children, props, isDragged }) => (
            <ul
              {...props}
              style={{
                padding: '0em 0em 1em 0em',
                cursor: isDragged ? 'grabbing' : 'inherit',
              }}
            >
              {children}
            </ul>
          )}
          renderItem={({ value, props, index, isDragged, isSelected }) => (
            <li
              {...props}
              key={props.key}
              style={{
                ...props.style,
                padding: '1.5em',
                margin: '0.5em 0em',
                listStyleType: 'none',
                border: '2px solid #CCC',
                boxShadow: '3px 3px #AAA',
                color: '#333',
                borderRadius: '5px',
                cursor: isDragged ? 'grabbing' : 'grab',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>{value}</div>{' '}
                <button
                  onClick={() => {
                    setSelectedItems(
                      typeof index !== 'undefined'
                        ? arrayRemove(selectedItems, index)
                        : selectedItems
                    );
                    onSelect(arrayRemove(selectedItems, index));
                  }}
                  style={buttonStyles}
                >
                  <RemovableIcon />
                </button>
              </div>
            </li>
          )}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  inputContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: 60,
    borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderColor: '#D7DEE1FF',
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: 'white',
  },
  tag: {
    backgroundColor: '#1B62F8',
    borderRadius: 16,
    paddingVertical: 6,
    paddingHorizontal: 12,
    marginRight: 4,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  tagText: {
    color: 'white',
    fontSize: 14,
    flexShrink: 1,
    marginRight: 4,
    flex: 1,
  },
  tagRemove: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
  input: {
    flex: 1,
    minWidth: 80,
    width: 'fit-content',
    height: 32,
    padding: 0,
    outlineStyle: 'none',
    fontFamily: 'Quicksand_500Medium',
    fontSize: 16,
  },
  dropdown: {
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: '#e0e0e0',
    borderWidth: 1,
    borderRadius: 4,
    maxHeight: 300,
    zIndex: 1000,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#f0f0f0',
  },
  selectedItemContainer: {
    backgroundColor: '#f0f8ff',
  },
  itemTitle: {
    fontSize: 16,
    color: '#333',
  },
  selectedLabel: {
    fontSize: 18,
    color: '#1B62F8',
    fontWeight: 'bold',
  },
  loadingIndicator: {
    padding: 10,
  },
  noResults: {
    padding: 10,
    textAlign: 'center',
    color: 'gray',
  },
  blackOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'transparent',
  },
  selectedInput: {
    // ... styles for selectedInput ...
  },
  singleSelectRemove: {
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: [{ translateY: -10 }],
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#e0e0e0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  singleSelectRemoveText: {
    fontSize: 16,
    color: '#333',
    fontWeight: 'bold',
  },
});

export { Index };
