import React from 'react';
import {
  Button,
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Modal, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import OverlayBlock from '../components/OverlayBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const PropScheduleListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [detailItem, setDetailItem] = React.useState({});
  const [editingItem, setEditingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [note, setNote] = React.useState('');
  const [numRecs, setNumRecs] = React.useState('');
  const [potd_dach, setPotd_dach] = React.useState('');
  const [potd_nordic, setPotd_nordic] = React.useState('');
  const [propSchedule, setPropSchedule] = React.useState(false);
  const [propScheduleList, setPropScheduleList] = React.useState([]);
  const [reg_dach, setReg_dach] = React.useState('');
  const [reg_nordic, setReg_nordic] = React.useState('');
  const [screenCode, setScreenCode] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const adminGroupDeleteNewsletterDELETE =
    AdminGroupApi.useDeleteNewsletterDELETE();
  const adminGroupUpdatePropSchedulePATCH =
    AdminGroupApi.useUpdatePropSchedulePATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      /* hidden 'Set Variable' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('NewsletterNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'Add new'}
          showAddButton={false}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: 15,
                paddingRight: 15,
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              <>
                {!numRecs ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        theme.typography.body1,
                        { marginLeft: 20 }
                      ),
                      dimensions.width
                    )}
                  >
                    {numRecs}
                    {' records matching filter'}
                  </Text>
                )}
              </>
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Edit */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'flex-start',
                          },
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'EDIT'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Date */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.25 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Date'}
                  </Text>
                </View>
                {/* Weekday */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Weekday'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Note */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Note'}
                      </Text>
                    </View>
                  )}
                </>
                {/* PotD Nordic */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'PotD Nordic\n'}
                  </Text>
                </View>
                {/* Reg Nordic */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Reg Nordic\n'}
                      </Text>
                    </View>
                  )}
                </>
                {/* PotD DACH */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'PotD DACH\n'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Reg DACH */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.25 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Reg DACH\n'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetPropScheduleGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== propScheduleList) {
                          setPropScheduleList(fetchData?.json?.items);
                          setNumRecs(fetchData?.json?.itemsTotal);
                        } else {
                        }

                        /* hidden 'Log to Console' action */
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword_search={keywordSearch}
                  page={1}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  retry={true}
                  staleTime={0}
                >
                  {({ loading, error, data, refetchGetPropSchedule }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={propScheduleList}
                        decelerationRate={'normal'}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={
                          'Linear Gradient->Container->Table->Container->Table Content->Fetch->List'
                        }
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReached={() => {
                          const handler = async () => {
                            console.log('List ON_END_REACHED Start');
                            let error = null;
                            try {
                              console.log('Start ON_END_REACHED:0 CONSOLE_LOG');
                              console.log(nextPage);
                              console.log(
                                'Complete ON_END_REACHED:0 CONSOLE_LOG'
                              );
                              console.log(
                                'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                              );
                              if (nextPage === null) {
                                return console.log(
                                  'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:2 FETCH_REQUEST'
                              );
                              const newData = (
                                await AdminGroupApi.getPropScheduleGET(
                                  Constants,
                                  {
                                    keyword_search: keywordSearch,
                                    page: nextPage,
                                  }
                                )
                              )?.json;
                              console.log(
                                'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                { newData }
                              );
                              console.log(
                                'Start ON_END_REACHED:3 SET_VARIABLE'
                              );
                              setNextPage(newData?.nextPage);
                              console.log(
                                'Complete ON_END_REACHED:3 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:4 SET_VARIABLE'
                              );
                              setLastPage(newData?.pageTotal);
                              console.log(
                                'Complete ON_END_REACHED:4 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                              );
                              if (
                                fetchData?.items ===
                                (0 || propScheduleList !== fetchData?.items)
                              ) {
                                return console.log(
                                  'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:6 SET_VARIABLE'
                              );
                              setPropScheduleList(
                                propScheduleList.concat(newData?.items)
                              );
                              console.log(
                                'Complete ON_END_REACHED:6 SET_VARIABLE'
                              );
                            } catch (err) {
                              console.error(err);
                              error = err.message ?? err;
                            }
                            console.log(
                              'List ON_END_REACHED Complete',
                              error ? { error } : 'no error'
                            );
                          };
                          handler();
                        }}
                        pagingEnabled={false}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'auto',
                                  },
                                  backgroundColor: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: getColorByIndex(index),
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: getColorByIndex(index),
                                    },
                                  ],
                                  flexDirection: 'row',
                                  width: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 1200,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Edit */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                    borderRightWidth: 1,
                                    flex: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 0.5,
                                    },
                                    height: '100%',
                                    justifyContent: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <TableActionsBlock
                                  onPressEdit={() => {
                                    try {
                                      setEditingItem(listData);
                                      setNote(listData?.note);
                                      setPotd_nordic(listData?.potd_nordic);
                                      setReg_nordic(listData?.reg_nordic);
                                      setPotd_dach(listData?.potd_dach);
                                      setReg_dach(listData?.reg_dach);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  showDelete={false}
                                  showEdit={true}
                                />
                              </View>
                              {/* Date */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.date}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Weekday */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.weekday}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Note */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.note}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* potd_nordic */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: 1,
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 0.5,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0.5,
                                      },
                                    ],
                                    height: '100%',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Row'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Table Row']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.potd_nordic}
                                </Text>
                              </View>
                              {/* reg_nordic */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.reg_nordic}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* potd_dach */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.potd_dach}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* reg_dach */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: 1,
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 0.25,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0.5,
                                      },
                                    ],
                                    height: '100%',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Row'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Table Row']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.reg_dach}
                                </Text>
                              </View>
                            </View>
                          );
                        }}
                        snapToAlignment={'start'}
                        onEndReachedThreshold={0.5}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value:
                                dimensions.width >= Breakpoints.Laptop
                                  ? 0
                                  : undefined,
                            },
                            maxHeight: [
                              {
                                minWidth: Breakpoints.Tablet,
                                value: dimensions.height - 250,
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: dimensions.height - 280,
                              },
                            ],
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      />
                    );
                  }}
                </AdminGroupApi.FetchGetPropScheduleGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                (
                  await adminGroupDeleteNewsletterDELETE.mutateAsync({
                    newsletter_id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'newsletter'}
          visible={deletingItem !== null}
        />
        {/* Modal Edit */}
        <Modal
          supportedOrientations={['portrait', 'landscape']}
          transparent={false}
          animationType={'fade'}
          transparent={StyleSheet.getWidthValue(
            [{ minWidth: Breakpoints.Laptop, value: true }],
            dimensions.width
          )}
          visible={Boolean(editingItem)}
        >
          <OverlayBlock />
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
              },
              dimensions.width
            )}
          >
            {/* Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.White,
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                  maxHeight: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.height - 60,
                  },
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 30 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
                  width: { minWidth: Breakpoints.Laptop, value: '35%' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Table Row'].style,
                    {
                      fontFamily: {
                        minWidth: Breakpoints.Laptop,
                        value: 'Quicksand_700Bold',
                      },
                      fontSize: { minWidth: Breakpoints.Laptop, value: 25 },
                      paddingLeft: { minWidth: Breakpoints.Laptop, value: 30 },
                      paddingRight: { minWidth: Breakpoints.Laptop, value: 30 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Currently editing '}
                {editingItem?.date}
                {' ('}
                {editingItem?.weekday}
                {'):'}
              </Text>

              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  {
                    marginTop: { minWidth: Breakpoints.Laptop, value: 30 },
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: 30 },
                    paddingRight: { minWidth: Breakpoints.Laptop, value: 30 },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { gap: { minWidth: Breakpoints.Laptop, value: 20 } },
                    dimensions.width
                  )}
                >
                  {/* Note */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'column',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Key */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                      selectable={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Table Row'].style,
                          {
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_600SemiBold',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 16,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Note*:'}
                    </Text>
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        const textInputValue = newTextInputValue;
                        try {
                          setNote(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={note}
                    />
                  </View>
                  {/* Nordic PotD: */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'column',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Key */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                      selectable={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Table Row'].style,
                          {
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_600SemiBold',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 16,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Nordic PotD:\n'}
                    </Text>
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setPotd_nordic(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={potd_nordic}
                    />
                  </View>
                  {/* Nordic Regular: */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'column',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Key */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                      selectable={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Table Row'].style,
                          {
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_600SemiBold',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 16,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Nordic Regular:'}
                    </Text>
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setReg_nordic(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={reg_nordic}
                    />
                  </View>
                  {/* DACH PotD: */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'column',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Key */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                      selectable={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Table Row'].style,
                          {
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_600SemiBold',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 16,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'DACH PotD:'}
                    </Text>
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setPotd_dach(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={potd_dach}
                    />
                  </View>
                  {/* DACH Regular */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'column',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Key */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                      selectable={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Table Row'].style,
                          {
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_600SemiBold',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 16,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'DACH Regular:'}
                    </Text>
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setReg_dach(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={reg_dach}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: { minWidth: Breakpoints.Laptop, value: 1 },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setEditingItem(null);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Strong Button']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Strong Button']
                            .style,
                          {
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '49%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setIsSubmitting(true);
                            const resultUpdated = (
                              await adminGroupUpdatePropSchedulePATCH.mutateAsync(
                                {
                                  note: note,
                                  potd_dach: potd_dach,
                                  potd_nordic: potd_nordic,
                                  prop_schedule_id: editingItem?.id,
                                  reg_dach: reg_dach,
                                  reg_nordic: reg_nordic,
                                }
                              )
                            )?.json;
                            setIsSubmitting(false);
                            if (resultUpdated?.code) {
                              toastMessage(resultUpdated?.message, 'error');
                            } else {
                              toastMessage(
                                'Update prop schedule successfully',
                                'success'
                              );
                              setEditingItem(null);
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Strong Button']
                        .props}
                      disabled={Boolean(isSubmitting)}
                      loading={Boolean(isSubmitting)}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Strong Button']
                            .style,
                          {
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '49%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Submit'}
                    />
                  </View>
                </View>
              </SimpleStyleScrollView>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(PropScheduleListingScreen);
