import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const UsersClientsListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [numRecs, setNumRecs] = React.useState('');
  const [usersClients, setUsersClients] = React.useState(false);
  const [usersClientsList, setUsersClientsList] = React.useState([]);
  const adminGroupDeleteUsersClientsDELETE =
    AdminGroupApi.useDeleteUsersClientsDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      /* hidden 'Set Variable' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('UsersClientsNavigator', {
                screen: 'UsersClientsCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD NEW USER'}
          showAddButton={true}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
              </View>
            </View>
          </View>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              <>
                {!numRecs ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        theme.typography.body1,
                        { marginLeft: 20 }
                      ),
                      dimensions.width
                    )}
                  >
                    {numRecs}
                    {' records matching filter'}
                  </Text>
                )}
              </>
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.4, flexDirection: 'row', padding: 10 },
                    dimensions.width
                  )}
                />
                {/* Welcome email sent */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Welcome email sent\n'}
                      </Text>
                    </View>
                  )}
                </>
                {/* User Status */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'User Status'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Name */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 1 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Name'}
                  </Text>
                </View>
                {/* Email */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Email'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Country */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Country'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Company */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Company'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Company Type */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Company Type'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Accessible Regions */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Accessible Regions\n'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Paid/Trial User */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Paid/Trial User\n'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Last_login */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Last_login'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Trial_end */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Trial end'}
                      </Text>
                    </View>
                  )}
                </>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetUsersClientsGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== usersClientsList) {
                          setUsersClientsList(fetchData?.json?.items);
                          setNumRecs(fetchData?.json?.itemsTotal);
                        } else {
                        }

                        setNextPage(fetchData?.json?.nextPage);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  only_nkp={false}
                  page={1}
                  per_page={25}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  retry={true}
                  staleTime={0}
                >
                  {({ loading, error, data, refetchGetUsersClients }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={usersClientsList}
                        decelerationRate={'normal'}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={
                          'Linear Gradient->Container->Table->Container->Table Content->Fetch->List'
                        }
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReached={() => {
                          const handler = async () => {
                            console.log('List ON_END_REACHED Start');
                            let error = null;
                            try {
                              console.log(
                                'Start ON_END_REACHED:0 CONDITIONAL_STOP'
                              );
                              if (nextPage === null) {
                                return console.log(
                                  'Complete ON_END_REACHED:0 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:0 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:1 FETCH_REQUEST'
                              );
                              const newData = (
                                await AdminGroupApi.getUsersClientsGET(
                                  Constants,
                                  {
                                    keyword: keywordSearch,
                                    only_nkp: false,
                                    page: nextPage,
                                    per_page: 25,
                                  }
                                )
                              )?.json;
                              console.log(
                                'Complete ON_END_REACHED:1 FETCH_REQUEST',
                                { newData }
                              );
                              console.log(
                                'Start ON_END_REACHED:2 SET_VARIABLE'
                              );
                              setNextPage(newData?.nextPage);
                              console.log(
                                'Complete ON_END_REACHED:2 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:3 SET_VARIABLE'
                              );
                              setLastPage(newData?.pageTotal);
                              console.log(
                                'Complete ON_END_REACHED:3 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:4 CONDITIONAL_STOP'
                              );
                              if (
                                fetchData?.items ===
                                (0 || usersClientsList !== fetchData?.items)
                              ) {
                                return console.log(
                                  'Complete ON_END_REACHED:4 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:4 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:5 SET_VARIABLE'
                              );
                              setUsersClientsList(
                                usersClientsList.concat(newData?.items)
                              );
                              console.log(
                                'Complete ON_END_REACHED:5 SET_VARIABLE'
                              );
                            } catch (err) {
                              console.error(err);
                              error = err.message ?? err;
                            }
                            console.log(
                              'List ON_END_REACHED Complete',
                              error ? { error } : 'no error'
                            );
                          };
                          handler();
                        }}
                        pagingEnabled={false}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'auto',
                                  },
                                  backgroundColor: getColorByIndex(index),
                                  borderColor: palettes.App.Outline,
                                  borderLeftWidth: 1,
                                  flexDirection: 'row',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Actions */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: 1,
                                    flex: 0.4,
                                    flexDirection: 'row',
                                    gap: 10,
                                    height: '100%',
                                    justifyContent: 'center',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <TableActionsBlock
                                  onPressDelete={() => {
                                    try {
                                      setDeletingItem(listData);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  onPressEdit={() => {
                                    try {
                                      navigation.navigate(
                                        'UsersClientsNavigator',
                                        {
                                          screen: 'UsersClientsEditScreen',
                                          params: { id: listData?.id },
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  border={false}
                                  showDelete={true}
                                  showEdit={true}
                                />
                              </View>
                              {/* Welcome email sent */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {formatTimestampToCopenhagen(
                                        listData?.date,
                                        undefined
                                      )}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* user_status */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          {
                                            color:
                                              listData?.user_status === 'Active'
                                                ? palettes.Green[700]
                                                : undefined,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.user_status}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* name */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: 1,
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 1,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0.5,
                                      },
                                    ],
                                    height: '100%',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Row'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Table Row']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.name}
                                </Text>
                              </View>
                              {/* email */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* email */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.email}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Country */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* country */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.country}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* company */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* company */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.company}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* company_type */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.company_type}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* accessible_regions */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.accessible_regions}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* paid_or_trial */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.paid_or_trial}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* last_login */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.last_login_ios_app}
                                      {'\n'}
                                      {listData?.last_login_web_app}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* trial_end */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.trial_end}
                                    </Text>
                                  </View>
                                )}
                              </>
                            </View>
                          );
                        }}
                        snapToAlignment={'start'}
                        onEndReachedThreshold={0.5}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value:
                                dimensions.width >= Breakpoints.Laptop
                                  ? 0
                                  : undefined,
                            },
                            maxHeight: [
                              {
                                minWidth: Breakpoints.Tablet,
                                value: dimensions.height - 250,
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: dimensions.height - 280,
                              },
                            ],
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      />
                    );
                  }}
                </AdminGroupApi.FetchGetUsersClientsGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteUserClient = (
                  await adminGroupDeleteUsersClientsDELETE.mutateAsync({
                    users_clients_id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteUserClient?.error) {
                  toastMessage(resultDeleteUserClient?.message, 'error');
                } else {
                  toastMessage('Delete user client successfully', 'success');
                }

                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'user'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(UsersClientsListingScreen);
