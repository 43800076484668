import React from 'react';
import {
  Checkbox,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import LoadingBlock from '../components/LoadingBlock';
import LoadingNextPageBlock from '../components/LoadingNextPageBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import getColorForStatus from '../global-functions/getColorForStatus';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const NewsletterListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [dach, setDach] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [loadingNextPage, setLoadingNextPage] = React.useState(false);
  const [newsletter, setNewsletter] = React.useState(false);
  const [newslettersList, setNewslettersList] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(0);
  const [nordic, setNordic] = React.useState(false);
  const [numRecs, setNumRecs] = React.useState('');
  const [screenCode, setScreenCode] = React.useState('');
  const [weeklyReport, setWeeklyReport] = React.useState(false);
  const adminGroupDeleteNewsletterDELETE =
    AdminGroupApi.useDeleteNewsletterDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      /* hidden 'Set Variable' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('NewsletterNavigator', {
                screen: 'NewsletterCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD NEWSLETTER'}
          localSelectedPage={Constants['selectedPage']}
          setLocalSelectedPage={localSelectedPage =>
            setGlobalVariableValue({
              key: 'selectedPage',
              value: localSelectedPage,
            })
          }
          showAddButton={true}
        />
        {/* Container */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              {
                alignContent: null,
                marginTop: { minWidth: Breakpoints.Laptop, value: 0 },
              }
            ),
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: [
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                      { minWidth: Breakpoints.Laptop, value: 'space-between' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      paddingLeft: { minWidth: Breakpoints.Laptop, value: 8 },
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Laptop, value: '55%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Nordic */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        flexDirection: 'row',
                        gap: 4,
                        padding: 4,
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: 115 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          setNordic(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={theme.colors.text.medium}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                      size={24}
                      status={nordic}
                      uncheckedColor={theme.colors.text.medium}
                    />
                    <Pressable
                      onPress={() => {
                        try {
                          setNordic(nordic ? false : true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['screen_title']
                          .props}
                        disabled={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['screen_title']
                              .style,
                            { fontFamily: 'Quicksand_400Regular', fontSize: 12 }
                          ),
                          dimensions.width
                        )}
                        suppressHighlighting={true}
                      >
                        {'Nordic'}
                      </Text>
                    </Pressable>
                  </View>
                  {/* DACH */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        flexDirection: 'row',
                        gap: 4,
                        padding: 4,
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: 115 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          setDach(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={theme.colors.text.medium}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                      size={24}
                      status={dach}
                      uncheckedColor={theme.colors.text.medium}
                    />
                    <Pressable
                      onPress={() => {
                        try {
                          setDach(dach ? false : true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['screen_title']
                          .props}
                        disabled={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['screen_title']
                              .style,
                            { fontFamily: 'Quicksand_400Regular', fontSize: 12 }
                          ),
                          dimensions.width
                        )}
                        suppressHighlighting={true}
                      >
                        {'DACH'}
                      </Text>
                    </Pressable>
                  </View>
                  {/* Newsletter */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        flexDirection: 'row',
                        gap: 4,
                        padding: 4,
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: 115 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          setNewsletter(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={theme.colors.text.medium}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                      size={24}
                      status={newsletter}
                      uncheckedColor={theme.colors.text.medium}
                    />
                    <Pressable
                      onPress={() => {
                        try {
                          setNewsletter(newsletter ? false : true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['screen_title']
                          .props}
                        disabled={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['screen_title']
                              .style,
                            { fontFamily: 'Quicksand_400Regular', fontSize: 12 }
                          ),
                          dimensions.width
                        )}
                        suppressHighlighting={true}
                      >
                        {'Newsletter'}
                      </Text>
                    </Pressable>
                  </View>
                  {/* Weekly Report */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        flexDirection: 'row',
                        gap: 4,
                        padding: 4,
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: 115 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          setWeeklyReport(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={theme.colors.text.medium}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                      size={24}
                      status={weeklyReport}
                      uncheckedColor={theme.colors.text.medium}
                    />
                    <Pressable
                      onPress={() => {
                        try {
                          setWeeklyReport(weeklyReport ? false : true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      disabled={Boolean(
                        Constants['ME']?.access_regions === 'DACH'
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['screen_title']
                          .props}
                        disabled={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['screen_title']
                              .style,
                            { fontFamily: 'Quicksand_400Regular', fontSize: 12 }
                          ),
                          dimensions.width
                        )}
                        suppressHighlighting={true}
                      >
                        {'Weekly Report'}
                      </Text>
                    </Pressable>
                  </View>
                </View>
              </View>
              <>
                {!numRecs ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        theme.typography.body1,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.BigScreen,
                            value: 'flex-start',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {numRecs}
                    {' records matching filter'}
                  </Text>
                )}
              </>
            </View>
          </View>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Tablet, value: 1 },
                  maxWidth: [
                    { minWidth: Breakpoints.Mobile, value: 1200 },
                    { minWidth: Breakpoints.BigScreen, value: 1200 },
                  ],
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 0.25,
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                />
                {/* Created */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Created'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Version */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Version'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Title */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Title'}
                  </Text>
                </View>
                {/* Teaser */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.7 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Teaser'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Created By */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Created by'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Status */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.25 },
                        { minWidth: Breakpoints.Tablet, value: 0.5 },
                        { minWidth: Breakpoints.Laptop, value: 0.25 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Status'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetNewslettersGET
                  dach={dach}
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== newslettersList) {
                          setNewslettersList(fetchData?.json?.items);
                          setNumRecs(fetchData?.json?.itemsTotal);
                        } else {
                        }

                        /* hidden 'Log to Console' action */
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  newsletter={newsletter}
                  nordic={nordic}
                  page={1}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  retry={true}
                  staleTime={0}
                  weekly_report={weeklyReport}
                >
                  {({ loading, error, data, refetchGetNewsletters }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <SimpleStyleFlatList
                          data={newslettersList}
                          decelerationRate={'normal'}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={
                            'Linear Gradient->Container->Table->Container->Table Content->Fetch->List'
                          }
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              console.log('List ON_END_REACHED Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                /* hidden 'Log to Console' action */ console.log(
                                  'Complete ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(
                                  'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                                if (nextPage === null) {
                                  return console.log(
                                    'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:2 SET_VARIABLE'
                                );
                                setLoadingNextPage(true);
                                console.log(
                                  'Complete ON_END_REACHED:2 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:3 FETCH_REQUEST'
                                );
                                const newData = (
                                  await AdminGroupApi.getNewslettersGET(
                                    Constants,
                                    {
                                      dach: dach,
                                      keyword: keywordSearch,
                                      newsletter: newsletter,
                                      nordic: nordic,
                                      page: nextPage,
                                      weekly_report: weeklyReport,
                                    }
                                  )
                                )?.json;
                                console.log(
                                  'Complete ON_END_REACHED:3 FETCH_REQUEST',
                                  { newData }
                                );
                                console.log(
                                  'Start ON_END_REACHED:4 SET_VARIABLE'
                                );
                                setNextPage(newData?.nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:4 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:5 SET_VARIABLE'
                                );
                                setLastPage(newData?.pageTotal);
                                console.log(
                                  'Complete ON_END_REACHED:5 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:6 SET_VARIABLE'
                                );
                                setLoadingNextPage(false);
                                console.log(
                                  'Complete ON_END_REACHED:6 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:7 CONDITIONAL_STOP'
                                );
                                if (
                                  fetchData?.items ===
                                  (0 || newslettersList !== fetchData?.items)
                                ) {
                                  return console.log(
                                    'Complete ON_END_REACHED:7 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:7 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:8 SET_VARIABLE'
                                );
                                setNewslettersList(
                                  newslettersList.concat(newData?.items)
                                );
                                console.log(
                                  'Complete ON_END_REACHED:8 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'List ON_END_REACHED Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          pagingEnabled={false}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    alignSelf: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'auto',
                                    },
                                    backgroundColor: getColorByIndex(index),
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: 1,
                                    flexDirection: 'row',
                                    width: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 1200,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Action */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 0.25,
                                      height: '100%',
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* tableActions 2 */}
                                  <TableActionsBlock
                                    onPressDelete={() => {
                                      try {
                                        setDeletingItem(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    onPressEdit={() => {
                                      try {
                                        navigation.navigate(
                                          'NewsletterNavigator',
                                          {
                                            screen: 'NewsletterEditScreen',
                                            params: { id: listData?.id },
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  />
                                </View>
                                {/* Created */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {formatTimestampToCopenhagen(
                                          listData?.created_at,
                                          undefined
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Version */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.version}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Title */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 0.5,
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.title}
                                  </Text>
                                </View>
                                {/* Teaser */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.7,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.teaser}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Created By */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?._user_admin?.name}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Status */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.25,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 0.5,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.25,
                                        },
                                      ],
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        {
                                          color: getColorForStatus(
                                            listData?.status
                                          ),
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.status}
                                  </Text>
                                </View>
                              </View>
                            );
                          }}
                          snapToAlignment={'start'}
                          onEndReachedThreshold={0.01}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value:
                                  dimensions.width >= Breakpoints.Laptop
                                    ? 0
                                    : undefined,
                              },
                              maxHeight: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: dimensions.height - 250,
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: dimensions.height - 280,
                                },
                              ],
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        <>
                          {!loadingNextPage ? null : <LoadingNextPageBlock />}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetNewslettersGET>
              </View>
            </View>
          </View>
          <ModalDeleteConfirmationBlock
            onCancel={() => {
              try {
                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            }}
            onConfirmDelete={() => {
              const handler = async () => {
                try {
                  setIsLoadingConfirmDelete(true);
                  const resultDeleteNewsletter = (
                    await adminGroupDeleteNewsletterDELETE.mutateAsync({
                      newsletter_id: deletingItem?.id,
                    })
                  )?.json;
                  setIsLoadingConfirmDelete(false);
                  if (resultDeleteNewsletter?.error) {
                    toastMessage(resultDeleteNewsletter?.message, 'error');
                  } else {
                    toastMessage('Delete newsletter successfully', 'success');
                  }

                  setDeletingItem(null);
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            isLoadingConfirm={isLoadingConfirmDelete}
            targetName={'newsletter'}
            visible={deletingItem !== null}
          />
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(NewsletterListingScreen);
