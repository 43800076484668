import React from 'react';
import { CircularProgress, withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const LoadingNextPageBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [loadingNextPage, setLoadingNextPage] = props.setLoadingNextPage
    ? [
        props.loadingNextPage !== undefined ? props.loadingNextPage : false,
        props.setLoadingNextPage,
      ]
    : React.useState(false);
  const newsLink = url => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return url.replace(/.+\/\/|www.|\..+/, '').split('/')[0];
  };

  const shouldShowResetFilter = () => {
    if (
      status_filter?.length > 0 ||
      country_filter?.length > 0 ||
      gics_filter?.length > 0 ||
      region_filter?.length > 0 ||
      status_filter?.length > 0 ||
      start_date_filter ||
      end_date_filter
    ) {
      return true;
    }
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      <CircularProgress
        animationDuration={500}
        color={theme.colors.branding.primary}
        isAnimated={true}
        lineCap={'round'}
        showTrack={true}
        startPosition={'top'}
        thickness={10}
        trackColor={theme.colors.border.base}
        trackLineCap={'round'}
        indeterminate={true}
        style={StyleSheet.applyWidth(
          {
            minWidth: { minWidth: Breakpoints.Laptop, value: 50 },
            width: { minWidth: Breakpoints.Laptop, value: 50 },
          },
          dimensions.width
        )}
        thickness={StyleSheet.getWidthValue(
          [{ minWidth: Breakpoints.Laptop, value: 5 }],
          dimensions.width
        )}
      />
    </View>
  );
};

export default withTheme(LoadingNextPageBlock);
