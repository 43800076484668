import React from 'react';
import { IconButton, withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  border: true,
  onPressDelete: () => {},
  onPressEdit: () => {},
  showDelete: true,
  showEdit: true,
};

const TableActionsBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          flexDirection: [
            { minWidth: Breakpoints.Mobile, value: 'row' },
            { minWidth: Breakpoints.Laptop, value: 'column' },
          ],
          gap: [
            { minWidth: Breakpoints.Mobile, value: 10 },
            { minWidth: Breakpoints.Laptop, value: 10 },
          ],
          justifyContent: [
            { minWidth: Breakpoints.Mobile, value: 'center' },
            { minWidth: Breakpoints.Laptop, value: 'space-around' },
          ],
        },
        dimensions.width
      )}
    >
      {/* Edit Button */}
      <>
        {!(props.showEdit ?? defaultProps.showEdit) ? null : (
          <IconButton
            onPress={() => {
              try {
                props.onPressEdit?.();
              } catch (err) {
                console.error(err);
              }
            }}
            color={theme.colors.text.medium}
            icon={'Feather/edit'}
            size={StyleSheet.getWidthValue(
              [
                { minWidth: Breakpoints.Laptop, value: 18 },
                { minWidth: Breakpoints.Mobile, value: 24 },
              ],
              dimensions.width
            )}
          />
        )}
      </>
      {/* Delete Button */}
      <>
        {!(props.showDelete ?? defaultProps.showDelete) ? null : (
          <IconButton
            onPress={() => {
              try {
                props.onPressDelete?.();
              } catch (err) {
                console.error(err);
              }
            }}
            color={theme.colors.text.medium}
            icon={'Feather/trash'}
            size={StyleSheet.getWidthValue(
              [
                { minWidth: Breakpoints.Laptop, value: 18 },
                { minWidth: Breakpoints.Mobile, value: 24 },
              ],
              dimensions.width
            )}
          />
        )}
      </>
    </View>
  );
};

export default withTheme(TableActionsBlock);
