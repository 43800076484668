import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CFSFormBlock from '../components/CFSFormBlock';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getIds from '../global-functions/getIds';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id: 2849 };

const CFSEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupUpdateCFSPATCH = AdminGroupApi.useUpdateCFSPATCH();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock showAddButton={false} />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <AdminGroupApi.FetchGetCFSDetailGET
            id={props.route?.params?.id ?? defaultProps.id}
            refetchInterval={0}
            refetchOnMount={false}
            refetchOnReconnect={false}
            refetchOnWindowFocus={false}
            retry={true}
            staleTime={0}
          >
            {({ loading, error, data, refetchGetCFSDetail }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <CurrentEditingSectionBlock
                    editingLabel={fetchData?.company}
                  />
                  <SimpleStyleKeyboardAwareScrollView
                    enableAutomaticScroll={false}
                    enableOnAndroid={false}
                    enableResetScrollToCoords={false}
                    keyboardShouldPersistTaps={'never'}
                    showsVerticalScrollIndicator={true}
                    viewIsInsideTabBar={false}
                    style={StyleSheet.applyWidth(
                      {
                        flex: { minWidth: Breakpoints.Laptop, value: 1 },
                        paddingBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 200,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <CFSFormBlock
                      onSubmit={(
                        first_added,
                        event,
                        stage,
                        company,
                        other_events,
                        timeline,
                        owners,
                        pepf,
                        country,
                        company_description,
                        peer_group,
                        gics,
                        website,
                        logo,
                        source_type,
                        revenue,
                        ebitda,
                        growth,
                        fy_end,
                        currency,
                        link_financials,
                        note_financials,
                        advisors_cf,
                        advisors_legal,
                        transacted,
                        strat_or_invf_owners,
                        logo_url
                      ) => {
                        const handler = async () => {
                          try {
                            setIsSubmitting(true);
                            const resultUpdateCFS = (
                              await adminGroupUpdateCFSPATCH.mutateAsync({
                                advisors_cf: getIds(advisors_cf),
                                advisors_legal: getIds(advisors_legal),
                                company: company,
                                company_description: company_description,
                                country: country,
                                currency: currency,
                                ebitda: ebitda,
                                event: event?.id,
                                first_added: first_added,
                                fy_end: fy_end,
                                gics: gics?.id,
                                growth: growth,
                                id: props.route?.params?.id ?? defaultProps.id,
                                link_financials: link_financials,
                                logo_url: logo_url,
                                note_financials: note_financials,
                                other_events: getIds(other_events),
                                owners: getIds(owners),
                                peer_group: peer_group?.id,
                                pepf: pepf?.id,
                                revenue: revenue,
                                source_type: source_type,
                                stage: stage,
                                strat_or_invf_owner: strat_or_invf_owners,
                                timeline: timeline,
                                transacted: transacted,
                                website: website,
                              })
                            )?.json;
                            setIsSubmitting(false);
                            if (resultUpdateCFS?.code) {
                              toastMessage(resultUpdateCFS?.message, 'error');
                            } else {
                              toastMessage(
                                'Update CFS successfully',
                                'success'
                              );
                              navigation.goBack();
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      initialData={fetchData}
                      isSubmitting={isSubmitting}
                    />
                  </SimpleStyleKeyboardAwareScrollView>
                </>
              );
            }}
          </AdminGroupApi.FetchGetCFSDetailGET>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CFSEditScreen);
