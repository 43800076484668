import React from 'react';
import {
  CircularProgress,
  HStack,
  Icon,
  IconButton,
  LinearGradient,
  Link,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CustomMultiSelectPickerBlock from '../components/CustomMultiSelectPickerBlock';
import CustomRangeDatePickerBlock from '../components/CustomRangeDatePickerBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import InlineEditModalBlock from '../components/InlineEditModalBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as ReadMoreText from '../custom-files/ReadMoreText';
import convertDateRangeToFilters from '../global-functions/convertDateRangeToFilters';
import convertToOptions from '../global-functions/convertToOptions';
import createInlineObject from '../global-functions/createInlineObject';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import getColorForStatus from '../global-functions/getColorForStatus';
import openBrowserTab from '../global-functions/openBrowserTab';
import toastMessage from '../global-functions/toastMessage';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { listi: null };

const EventListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [country_filter, setCountry_filter] = React.useState([]);
  const [dach, setDach] = React.useState(false);
  const [date_range_filter, setDate_range_filter] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [end_date_filter, setEnd_date_filter] = React.useState('');
  const [event, setEvent] = React.useState(false);
  const [event_type_filters, setEvent_type_filters] = React.useState([]);
  const [eventsList, setEventsList] = React.useState([]);
  const [gics, setGics] = React.useState('');
  const [gics_filter, setGics_filter] = React.useState([]);
  const [gics_options, setGics_options] = React.useState([]);
  const [inlineField, setInlineField] = React.useState('');
  const [inlineFieldType, setInlineFieldType] = React.useState('');
  const [inlineIsLoading, setInlineIsLoading] = React.useState(false);
  const [inlinePage, setInlinePage] = React.useState('events');
  const [inlinePickerOptions, setInlinePickerOptions] = React.useState('');
  const [inlineRecID, setInlineRecID] = React.useState('');
  const [inlineValue, setInlineValue] = React.useState('');
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [loadingNextPage, setLoadingNextPage] = React.useState(false);
  const [nextPage, setNextPage] = React.useState(0);
  const [nordic, setNordic] = React.useState(false);
  const [numRecs, setNumRecs] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pageTotal, setPageTotal] = React.useState(0);
  const [region_filter, setRegion_filter] = React.useState([]);
  const [screenCode, setScreenCode] = React.useState('');
  const [showInlineEdit, setShowInlineEdit] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [showSearchIcon, setShowSearchIcon] = React.useState(false);
  const [start_date_filter, setStart_date_filter] = React.useState('');
  const [status_filter, setStatus_filter] = React.useState([]);
  const [suggestionTargets, setSuggestionTargets] = React.useState([]);
  const [target, setTarget] = React.useState('');
  const [weeklyReport, setWeeklyReport] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState(undefined);
  const newsLink = url => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return url.replace(/.+\/\/|www.|\..+/, '').split('/')[0];
  };

  const shouldShowResetFilter = () => {
    if (
      status_filter?.length > 0 ||
      country_filter?.length > 0 ||
      gics_filter?.length > 0 ||
      region_filter?.length > 0 ||
      status_filter?.length > 0 ||
      start_date_filter ||
      end_date_filter ||
      event_type_filters?.length > 0
    ) {
      return true;
    }
    return false;
  };
  const adminGroupInlineEditPATCH = AdminGroupApi.useInlineEditPATCH();
  const adminGroupDeleteEventDELETE = AdminGroupApi.useDeleteEventDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        /* hidden 'Set Variable' action */
        /* hidden 'Set Variable' action */
        const gics_result = (
          await AdminGroupApi.getGicsSubIndustryGET(Constants, {})
        )?.json;
        setGics_options(
          convertToOptions(
            gics_result,
            'GICS_Sector',
            'id',
            'GICS_Sub_Industry'
          )
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('EventNavigator', {
                screen: 'EventCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD EVENT'}
          showAddButton={true}
        />
        {/* Container */}
        <View
          collapsable={true}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <>
            {!showSearch ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    paddingBottom: { minWidth: Breakpoints.Laptop, value: 10 },
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 15 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 15 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: { minWidth: Breakpoints.Laptop, value: 15 },
                      maxWidth: 1200,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-start',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        gap: 10,
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-between',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCorrect={true}
                      onChangeText={newTextInputValue => {
                        try {
                          if (!newTextInputValue) {
                            setKeywordSearch(newTextInputValue);
                          } else {
                          }

                          setKeywordSearch_raw(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onChangeTextDelayed={newTextInputValue => {
                        try {
                          setKeywordSearch(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      autoCapitalize={'sentences'}
                      changeTextDelay={300}
                      clearButtonMode={'while-editing'}
                      placeholder={'Search...'}
                      returnKeyType={'search'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)[
                            'Common TextInput'
                          ].style,
                          { backgroundColor: palettes.App['Custom Color 7'] }
                        ),
                        dimensions.width
                      )}
                      value={keywordSearch_raw}
                    />
                    {/* target search */}
                    <FuzzyInputWithDataBlock
                      getSuggestions={searchKeyword => {
                        const handler = async () => {
                          try {
                            const resultTargets = (
                              await AdminGroupApi.eventTargetGET(Constants, {
                                keyword: searchKeyword,
                              })
                            )?.json;
                            const suggestion_targets =
                              transformToSearchInputFormat(
                                resultTargets?.items,
                                'event_target',
                                'event_target',
                                undefined
                              );
                            setSuggestionTargets(suggestion_targets);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      onClear={() => {
                        try {
                          setTarget(null);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSelect={selectedResult => {
                        try {
                          setTarget(selectedResult);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      customPlaceholder={'Target search...'}
                      defaultValue={gics}
                      multiSelect={false}
                      selectedItem={target}
                      suggestionsList={suggestionTargets}
                    />
                  </View>
                  {/* Filter Row 1 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Status */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          gap: { minWidth: Breakpoints.Laptop, value: 5 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Status'}
                      </Text>
                      <CustomMultiSelectPickerBlock
                        onChange={selectedValues => {
                          try {
                            setStatus_filter(selectedValues);
                            setNextPage(1);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        options={Constants['EVENT_STATUS_OPTIONS']}
                        placeholder={'Select status'}
                        selectedOptions={status_filter}
                      />
                    </View>
                    {/* Country */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          gap: { minWidth: Breakpoints.Laptop, value: 5 },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Country'}
                      </Text>
                      {/* Custom Multi Select Picker 2 */}
                      <CustomMultiSelectPickerBlock
                        onChange={selectedValues => {
                          try {
                            setCountry_filter(selectedValues);
                            setNextPage(1);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        options={Constants['COUNTRY_OPTIONS']}
                        placeholder={'Select country'}
                        selectedOptions={country_filter}
                      />
                    </View>
                    {/* Region */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          gap: { minWidth: Breakpoints.Laptop, value: 5 },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Region'}
                      </Text>
                      {/* Custom Multi Select Picker 2 */}
                      <CustomMultiSelectPickerBlock
                        onChange={selectedValues => {
                          try {
                            setRegion_filter(selectedValues);
                            setNextPage(1);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        options={Constants['REGION_OPTIONS']}
                        placeholder={'Select region'}
                        selectedOptions={region_filter}
                      />
                    </View>
                  </View>
                  {/* Filter Row 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Event Type */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          gap: { minWidth: Breakpoints.Laptop, value: 5 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Event Type'}
                      </Text>
                      <CustomMultiSelectPickerBlock
                        onChange={selectedValues => {
                          try {
                            setEvent_type_filters(selectedValues);
                            setNextPage(1);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        options={Constants['EVENT_TYPE_OPTIONS']}
                        placeholder={'Select event type'}
                        selectedOptions={event_type_filters}
                      />
                    </View>
                    {/* Gics 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          gap: { minWidth: Breakpoints.Laptop, value: 5 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'GICS'}
                      </Text>
                      <CustomMultiSelectPickerBlock
                        onChange={selectedValues => {
                          try {
                            setGics_filter(selectedValues);
                            setNextPage(1);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        options={gics_options}
                        placeholder={'Select gics'}
                        selectedOptions={gics_filter}
                      />
                    </View>
                    {/* Date */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          gap: { minWidth: Breakpoints.Laptop, value: 5 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Date'}
                      </Text>
                      <CustomRangeDatePickerBlock
                        onChange={(startDate, endDate) => {
                          try {
                            setStart_date_filter(startDate);
                            setEnd_date_filter(endDate);
                            setNextPage(1);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        endDate={end_date_filter}
                        startDate={start_date_filter}
                      />
                    </View>
                  </View>
                  {/* Blank */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: { minWidth: Breakpoints.Laptop, value: 1 },
                        gap: { minWidth: Breakpoints.Laptop, value: 5 },
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!shouldShowResetFilter() ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { gap: { minWidth: Breakpoints.Laptop, value: 5 } },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Reset filter'}
                          </Text>

                          <Pressable
                            onPress={() => {
                              try {
                                setStatus_filter([]);
                                setCountry_filter([]);
                                setRegion_filter([]);
                                setDate_range_filter([]);
                                setGics_filter([]);
                                setStart_date_filter(null);
                                setEnd_date_filter(null);
                                setEvent_type_filters([]);
                                setKeywordSearch(null);
                                setKeywordSearch_raw(null);
                                setTarget(null);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  backgroundColor: {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors.branding.primary,
                                  },
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 8,
                                  },
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 50,
                                  },
                                  justifyContent: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 50,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                size={24}
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App.White,
                                    },
                                  ],
                                  dimensions.width
                                )}
                                name={'MaterialCommunityIcons/window-close'}
                                size={StyleSheet.getWidthValue(
                                  [{ minWidth: Breakpoints.Laptop, value: 26 }],
                                  dimensions.width
                                )}
                              />
                            </View>
                          </Pressable>
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>
            )}
          </>
          {/* Table */}
          <View
            {...GlobalStyles.ViewStyles(theme)['events_list'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['events_list'].style,
                {
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
                }
              ),
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetEventsGET
                  country={
                    country_filter?.length > 0 ? country_filter : undefined
                  }
                  date_range={
                    convertDateRangeToFilters(
                      start_date_filter,
                      end_date_filter
                    )?.length > 0
                      ? JSON.stringify(
                          convertDateRangeToFilters(
                            start_date_filter,
                            end_date_filter
                          )
                        )
                      : undefined
                  }
                  event_type={event_type_filters}
                  gics_sub_industry={
                    gics_filter?.length > 0 ? gics_filter : undefined
                  }
                  handlers={{
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    onData: fetchData => {
                      try {
                        setEventsList(fetchData?.items);
                        setNumRecs(fetchData?.itemsTotal);
                        setNextPage(fetchData?.nextPage);
                        setShowSearchIcon(true);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  page={1}
                  per_page={25}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  region={region_filter?.length > 0 ? region_filter : undefined}
                  retry={true}
                  staleTime={0}
                  status={status_filter?.length > 0 ? status_filter : undefined}
                  target={target?.id}
                >
                  {({ loading, error, data, refetchGetEvents }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <HStack
                          {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.HStackStyles(theme)['H Stack'].style,
                            dimensions.width
                          )}
                        >
                          <>
                            {!numRecs ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Table Row']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Table Row']
                                      .style,
                                    theme.typography.body1,
                                    {
                                      marginLeft: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {numRecs}
                                {' records matching filter'}
                              </Text>
                            )}
                          </>
                          <>
                            {!showSearchIcon ? null : (
                              <IconButton
                                onPress={() => {
                                  try {
                                    setShowSearch(!showSearch);
                                    /* hidden 'Log to Console' action */
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                size={32}
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors.text.strong,
                                    },
                                  ],
                                  dimensions.width
                                )}
                                icon={'MaterialIcons/saved-search'}
                              />
                            )}
                          </>
                          {/* Icon Button 2 */}
                          <IconButton
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setIsRefetching(true);
                                  await refetchGetEvents();
                                  setIsRefetching(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            size={32}
                            color={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                            icon={'Feather/refresh-cw'}
                          />
                        </HStack>
                        {/* Table Header */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['Custom Color 7'],
                              borderColor: palettes.App.Outline,
                              borderWidth: 1,
                              flexDirection: 'row',
                              marginLeft: {
                                minWidth: Breakpoints.Laptop,
                                value: 20,
                              },
                              marginRight: {
                                minWidth: Breakpoints.Laptop,
                                value: 34,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Actions */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor: palettes.App.Outline,
                                borderRightWidth: 1,
                                flex: 0.4,
                                flexDirection: 'row',
                                padding: 10,
                              },
                              dimensions.width
                            )}
                          />
                          {/* Created at */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.7,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.7,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 0.3,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Created at'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Published */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.7,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.7,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 0.3,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Published'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Target */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.8,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.8,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Target'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Event Type */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.8,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.8,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Event Type'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Headline */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)['table_header']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)['table_header']
                                  .style,
                                { flex: 1 }
                              ),
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Table Title']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Table Title']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {'Headline'}
                            </Text>
                          </View>
                          {/* Summary */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    { flex: 1 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Summary'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Country */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.6,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.6,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Country'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* GICS */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.7,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'GICS'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Original Headline of Source */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Original headline of source'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Source Link */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.7,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Source Link'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Status */}
                          <>
                            {!(
                              dimensions.width >= Breakpoints.Laptop
                            ) ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'table_header'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'table_header'
                                    ].style,
                                    {
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 0.5,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Title'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Table Title'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Status'}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                        {/* Loading 2 */}
                        <>{!isRefetching ? null : <LoadingBlock />}</>
                        <SimpleStyleFlatList
                          data={eventsList}
                          decelerationRate={'normal'}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          listKey={
                            'Linear Gradient->Container->Table->Container->Table Content->Fetch->List'
                          }
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              console.log('List ON_END_REACHED Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_END_REACHED:0 CONDITIONAL_STOP'
                                );
                                if (nextPage === null) {
                                  return console.log(
                                    'Complete ON_END_REACHED:0 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:0 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:1 SET_VARIABLE'
                                );
                                setLoadingNextPage(true);
                                console.log(
                                  'Complete ON_END_REACHED:1 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:2 FETCH_REQUEST'
                                );
                                const newData = (
                                  await AdminGroupApi.getEventsGET(Constants, {
                                    country:
                                      country_filter?.length > 0
                                        ? country_filter
                                        : undefined,
                                    date_range:
                                      convertDateRangeToFilters(
                                        start_date_filter,
                                        end_date_filter
                                      )?.length > 0
                                        ? JSON.stringify(
                                            convertDateRangeToFilters(
                                              start_date_filter,
                                              end_date_filter
                                            )
                                          )
                                        : undefined,
                                    event_type: event_type_filters,
                                    gics_sub_industry:
                                      gics_filter?.length > 0
                                        ? gics_filter
                                        : undefined,
                                    keyword: keywordSearch,
                                    page: nextPage,
                                    per_page: 25,
                                    region:
                                      region_filter?.length > 0
                                        ? region_filter
                                        : undefined,
                                    status:
                                      status_filter?.length > 0
                                        ? status_filter
                                        : undefined,
                                    target: target?.id,
                                  })
                                )?.json;
                                console.log(
                                  'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                  { newData }
                                );
                                console.log(
                                  'Start ON_END_REACHED:3 SET_VARIABLE'
                                );
                                setNextPage(newData?.nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:3 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:4 SET_VARIABLE'
                                );
                                setLastPage(newData?.lastPage);
                                console.log(
                                  'Complete ON_END_REACHED:4 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:5 SET_VARIABLE'
                                );
                                setEventsList(
                                  eventsList.concat(newData?.items)
                                );
                                console.log(
                                  'Complete ON_END_REACHED:5 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:6 SET_VARIABLE'
                                );
                                setLoadingNextPage(false);
                                console.log(
                                  'Complete ON_END_REACHED:6 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'List ON_END_REACHED Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          pagingEnabled={false}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'auto',
                                    },
                                    backgroundColor: getColorByIndex(index),
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: 1,
                                    flexDirection: 'row',
                                    marginLeft: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 20,
                                    },
                                    marginRight: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 20,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Actions */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 0.4,
                                      flexDirection: 'row',
                                      gap: 10,
                                      height: '100%',
                                      justifyContent: 'center',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <TableActionsBlock
                                    onPressDelete={() => {
                                      try {
                                        setDeletingItem(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    onPressEdit={() => {
                                      try {
                                        navigation.navigate('EventNavigator', {
                                          screen: 'EventEditScreen',
                                          params: { id: listData?.id },
                                        });
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  />
                                </View>
                                {/* Created at */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.5,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.7,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 0.3,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        selectable={true}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {formatTimestampToCopenhagen(
                                          listData?.created_at,
                                          undefined
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Published */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.7,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 0.3,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        selectable={true}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {formatTimestampToCopenhagen(
                                          listData?.published,
                                          undefined
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Target */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.8,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setShowInlineEdit(true);
                                            setInlineField('target');
                                            setInlineFieldType('text');
                                            setInlinePage('events');
                                            setInlineRecID(listData?.id);
                                            setInlineValue(listData?.target);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          selectable={true}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.target}
                                        </Text>
                                      </Pressable>
                                    </View>
                                  )}
                                </>
                                {/* Event Type */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.8,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setShowInlineEdit(true);
                                            setInlineField('event_type');
                                            setInlineFieldType('picker');
                                            setInlineRecID(listData?.id);
                                            setInlineValue(
                                              listData?.event_type
                                            );
                                            setInlinePickerOptions(
                                              Constants['EVENT_TYPE_OPTIONS']
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          selectable={true}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.event_type}
                                        </Text>
                                      </Pressable>
                                    </View>
                                  )}
                                </>
                                {/* Headline */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 1,
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setShowInlineEdit(true);
                                        setInlineField('headline');
                                        setInlineFieldType('autosizetext');
                                        setInlineRecID(listData?.id);
                                        setInlineValue(listData?.headline);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Utils.CustomCodeErrorBoundary>
                                      <ReadMoreText.Index>
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          selectable={true}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.headline}
                                        </Text>
                                      </ReadMoreText.Index>
                                    </Utils.CustomCodeErrorBoundary>
                                  </Pressable>
                                </View>
                                {/* Summary */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 1,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setShowInlineEdit(true);
                                            setInlineField('description');
                                            setInlineFieldType('autosizetext');
                                            setInlineRecID(listData?.id);
                                            setInlineValue(
                                              listData?.description
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <Utils.CustomCodeErrorBoundary>
                                          <ReadMoreText.Index>
                                            {/* description */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Table Row'].props}
                                              selectable={true}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Table Row'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.description}
                                            </Text>
                                          </ReadMoreText.Index>
                                        </Utils.CustomCodeErrorBoundary>
                                      </Pressable>
                                    </View>
                                  )}
                                </>
                                {/* Country */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.6,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setShowInlineEdit(true);
                                            setInlineField('country');
                                            setInlineFieldType('picker');
                                            setInlineRecID(listData?.id);
                                            setInlineValue(listData?.country);
                                            setInlinePickerOptions(
                                              Constants['COUNTRY_OPTIONS']
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* country */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          selectable={true}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            dimensions.width
                                          )}
                                          suppressHighlighting={false}
                                        >
                                          {listData?.country}
                                        </Text>
                                      </Pressable>
                                    </View>
                                  )}
                                </>
                                {/* GICS */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0.7,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setShowInlineEdit(true);
                                            setInlineField('gics');
                                            setInlineFieldType('picker');
                                            setInlineRecID(listData?.id);
                                            setInlineValue(
                                              listData?.gics > 0
                                                ? listData?.gics
                                                : undefined
                                            );
                                            setInlinePickerOptions(
                                              Constants['GICS_OPTIONS']
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* gics */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          selectable={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              {
                                                color:
                                                  listData?.gics === 0
                                                    ? palettes.Brand.Error
                                                    : undefined,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.gics > 0
                                            ? listData
                                                ?._gics_sub_industry_single
                                                ?.GICS_Sector +
                                              ('-' +
                                                listData
                                                  ?._gics_sub_industry_single
                                                  ?.GICS_Sub_Industry)
                                            : 'NA'}
                                        </Text>
                                      </Pressable>
                                    </View>
                                  )}
                                </>
                                {/* Original headline */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setShowInlineEdit(true);
                                            setInlineField('original_headline');
                                            setInlineFieldType('autosizetext');
                                            setInlineRecID(listData?.id);
                                            setInlineValue(
                                              listData?.original_headline
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <Utils.CustomCodeErrorBoundary>
                                          <ReadMoreText.Index>
                                            {/* original headline */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Table Row'].props}
                                              selectable={true}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Table Row'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.original_headline}
                                            </Text>
                                          </ReadMoreText.Index>
                                        </Utils.CustomCodeErrorBoundary>
                                      </Pressable>
                                    </View>
                                  )}
                                </>
                                {/* Source Link */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0.7,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.source_link).includes(
                                          'https'
                                        ) ? null : (
                                          <Link
                                            accessible={true}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  if (Platform.OS === 'ios') {
                                                    if (
                                                      (listData?.source_link).includes(
                                                        'https'
                                                      )
                                                    ) {
                                                      await WebBrowser.openBrowserAsync(
                                                        `${listData?.source_link}`
                                                      );
                                                    }
                                                  } else {
                                                    openBrowserTab(
                                                      listData?.source_link
                                                    );
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            selectable={false}
                                            {...GlobalStyles.LinkStyles(theme)[
                                              'Link'
                                            ].props}
                                            numberOfLines={5}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.LinkStyles(theme)[
                                                'Link'
                                              ].style,
                                              dimensions.width
                                            )}
                                            title={`${newsLink(
                                              listData?.source_link
                                            )}`}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Status */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            {
                                              color: getColorForStatus(
                                                listData?.status
                                              ),
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.status}
                                      </Text>
                                      <Picker
                                        autoDismissKeyboard={true}
                                        dropDownBackgroundColor={
                                          theme.colors.background.base
                                        }
                                        dropDownBorderColor={
                                          theme.colors.border.base
                                        }
                                        dropDownBorderRadius={8}
                                        dropDownBorderWidth={1}
                                        dropDownTextColor={
                                          theme.colors.text.strong
                                        }
                                        iconSize={24}
                                        leftIconMode={'inset'}
                                        mode={'native'}
                                        onValueChange={newPickerValue => {
                                          const handler = async () => {
                                            const pickerValue = newPickerValue;
                                            try {
                                              (
                                                await adminGroupInlineEditPATCH.mutateAsync(
                                                  {
                                                    value: createInlineObject(
                                                      'status',
                                                      inlinePage,
                                                      listData?.id,
                                                      newPickerValue
                                                    ),
                                                  }
                                                )
                                              )?.json;
                                              toastMessage(
                                                'Event status updated!',
                                                'success'
                                              );
                                              await refetchGetEvents();
                                              /* hidden 'Set Variable' action */
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        selectedIconColor={
                                          theme.colors.text.strong
                                        }
                                        selectedIconName={'Feather/check'}
                                        selectedIconSize={20}
                                        type={'solid'}
                                        options={
                                          Constants['EVENT_STATUS_OPTIONS']
                                        }
                                        placeholder={'Change...'}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            theme.typography.body2,
                                            {
                                              color: {
                                                minWidth: Breakpoints.Laptop,
                                                value: getColorForStatus(
                                                  listData?.status
                                                ),
                                              },
                                              fontFamily: {
                                                minWidth: Breakpoints.Desktop,
                                                value: 'Quicksand_400Regular',
                                              },
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        value={pickerValue}
                                      />
                                    </View>
                                  )}
                                </>
                              </View>
                            );
                          }}
                          snapToAlignment={'start'}
                          keyboardShouldPersistTaps={'always'}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value:
                                  dimensions.width >= Breakpoints.Laptop
                                    ? 0
                                    : undefined,
                              },
                              maxHeight: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: dimensions.height - 250,
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: dimensions.height,
                                },
                              ],
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        <InlineEditModalBlock
                          onCancel={() => {
                            try {
                              setShowInlineEdit(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          onSubmit={() => {
                            const handler = async () => {
                              console.log('Inline Edit Modal onSubmit Start');
                              let error = null;
                              try {
                                console.log('Start null:0 SET_VARIABLE');
                                setInlineIsLoading(true);
                                console.log('Complete null:0 SET_VARIABLE');
                                console.log('Start null:1 FETCH_REQUEST');
                                (
                                  await adminGroupInlineEditPATCH.mutateAsync({
                                    value: createInlineObject(
                                      inlineField,
                                      inlinePage,
                                      inlineRecID,
                                      inlineValue
                                    ),
                                  })
                                )?.json;
                                console.log('Complete null:1 FETCH_REQUEST');
                                console.log('Start null:2 SET_VARIABLE');
                                setInlineIsLoading(false);
                                console.log('Complete null:2 SET_VARIABLE');
                                console.log('Start null:3 SET_VARIABLE');
                                setShowInlineEdit(false);
                                console.log('Complete null:3 SET_VARIABLE');
                                console.log('Start null:4 FETCH_REFETCH');
                                await refetchGetEvents();
                                console.log('Complete null:4 FETCH_REFETCH');
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'Inline Edit Modal onSubmit Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          inlineField={inlineField}
                          inlineFieldType={inlineFieldType}
                          inlinePage={inlinePage}
                          inlineRecID={inlineRecID}
                          inlineTextField={inlineValue}
                          inlineValue={inlineValue}
                          isLoadingConfirm={inlineIsLoading}
                          pickerOptions={inlinePickerOptions}
                          setInlineTextField={inlineTextField =>
                            setInlineValue(inlineTextField)
                          }
                          setShowInlineEdit={showInlineEdit =>
                            setShowInlineEdit(showInlineEdit)
                          }
                          showInlineEdit={showInlineEdit}
                          visible={showInlineEdit}
                        />
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetEventsGET>
                <>
                  {!loadingNextPage ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          justifyContent: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <CircularProgress
                        animationDuration={500}
                        color={theme.colors.branding.primary}
                        isAnimated={true}
                        lineCap={'round'}
                        showTrack={true}
                        startPosition={'top'}
                        thickness={10}
                        trackColor={theme.colors.border.base}
                        trackLineCap={'round'}
                        indeterminate={true}
                        style={StyleSheet.applyWidth(
                          {
                            minWidth: {
                              minWidth: Breakpoints.Laptop,
                              value: 50,
                            },
                            width: { minWidth: Breakpoints.Laptop, value: 50 },
                          },
                          dimensions.width
                        )}
                        thickness={StyleSheet.getWidthValue(
                          [{ minWidth: Breakpoints.Laptop, value: 5 }],
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteNewsletter = (
                  await adminGroupDeleteEventDELETE.mutateAsync({
                    event_id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteNewsletter?.error) {
                  toastMessage(resultDeleteNewsletter?.message, 'error');
                } else {
                  toastMessage('Delete event successfully', 'success');
                }

                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'event'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(EventListingScreen);
