import React from 'react';
import {
  Button,
  HStack,
  Icon,
  LinearGradient,
  Pressable,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CommonPackages from '../custom-files/CommonPackages';
import * as CustomPicker from '../custom-files/CustomPicker';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  addButtonLabel: 'Add',
  onPressAdd: () => {},
  showAddButton: null,
};

const CommonHeaderBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [localSelectedPage, setLocalSelectedPage] = props.setLocalSelectedPage
    ? [
        props.localSelectedPage !== undefined ? props.localSelectedPage : '',
        props.setLocalSelectedPage,
      ]
    : React.useState('');
  const linkTo = CommonPackages?.useLinkTo();
  React.useEffect(() => {
    const handler = async () => {
      try {
        const resultMe = (await AdminGroupApi.getMeGET(Constants))?.json;
        if (resultMe?.code) {
          toastMessage(resultMe?.message, 'error');
          setGlobalVariableValue({
            key: 'AUTH_HEADER',
            value: '',
          });
          setGlobalVariableValue({
            key: 'ME',
            value: null,
          });
          if (navigation.canGoBack()) {
            navigation.popToTop();
          }
          navigation.replace('LogInScreen');
        } else {
          setGlobalVariableValue({
            key: 'ME',
            value: resultMe,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, []);

  return (
    <Surface
      elevation={3}
      style={StyleSheet.applyWidth(
        {
          borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 1 },
          borderColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App['Custom Color'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
        color1={StyleSheet.getWidthValue(
          [
            {
              minWidth: Breakpoints.Laptop,
              value: theme.colors.branding.primary,
            },
          ],
          dimensions.width
        )}
        color2={StyleSheet.getWidthValue(
          [
            {
              minWidth: Breakpoints.Laptop,
              value: theme.colors.branding.primary,
            },
          ],
          dimensions.width
        )}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { borderRadius: { minWidth: Breakpoints.Tablet, value: 24 } },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  alignContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  justifyContent: 'space-between',
                  paddingBottom: { minWidth: Breakpoints.Tablet, value: 10 },
                  paddingLeft: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Tablet, value: 10 },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Tablet, value: 10 },
                  ],
                  paddingTop: { minWidth: Breakpoints.Tablet, value: 10 },
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: [
                    { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                    {
                      minWidth: Breakpoints.Mobile,
                      value:
                        dimensions.width >= Breakpoints.Tablet
                          ? 'flex-end'
                          : undefined,
                    },
                  ],
                  alignSelf: 'center',
                  flexDirection: [
                    { minWidth: Breakpoints.Mobile, value: 'row' },
                    {
                      minWidth: Breakpoints.Mobile,
                      value:
                        dimensions.width >= Breakpoints.Tablet
                          ? 'row'
                          : 'column',
                    },
                  ],
                  gap: 10,
                },
                dimensions.width
              )}
            >
              {/* Column */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 3,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['screen_title'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['screen_title'].style,
                      {
                        color: theme.colors.branding.secondary,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                      }
                    ),
                    dimensions.width
                  )}
                  suppressHighlighting={true}
                >
                  {'Admin page:'}
                </Text>
                <Utils.CustomCodeErrorBoundary>
                  <CustomPicker.Index
                    theme={theme}
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    mode={'dropdown-modal'}
                    placeholder={'Select page'}
                    rightIconName={'Ionicons/caret-down'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                  />
                </Utils.CustomCodeErrorBoundary>
              </View>
              {/* Add button */}
              <>
                {!(props.showAddButton ?? defaultProps.showAddButton) ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        props.onPressAdd?.();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: palettes.App.Orange,
                          fontFamily: 'Quicksand_500Medium',
                          maxHeight: 50,
                          minHeight: 50,
                          paddingLeft: 28,
                          paddingRight: 28,
                        }
                      ),
                      dimensions.width
                    )}
                    title={`${
                      props.addButtonLabel ?? defaultProps.addButtonLabel
                    }`}
                  />
                )}
              </>
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Mobile, value: 'stretch' },
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                  ],
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Pressable 2 */}
              <Pressable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'AUTH_HEADER',
                      value: null,
                    });
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('LogInScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon
                  size={24}
                  color={StyleSheet.getWidthValue(
                    [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors.text.strong,
                      },
                    ],
                    dimensions.width
                  )}
                  name={'MaterialCommunityIcons/logout'}
                />
              </Pressable>

              <Pressable
                onPress={() => {
                  try {
                    /* hidden 'Set Variable' action */
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('EventNavigator', {
                      screen: 'EventListingScreen',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(
                    Images['mainsightsfaviconlogo1024cropped']
                  )}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 50 }
                    ),
                    dimensions.width
                  )}
                />
              </Pressable>
            </View>
          </HStack>
        </View>
      </LinearGradient>
    </Surface>
  );
};

export default withTheme(CommonHeaderBlock);
