import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import NewsletterFormBlock from '../components/NewsletterFormBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getIds from '../global-functions/getIds';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id: 482 };

const NewsletterEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupUpdateNewsletterPATCH =
    AdminGroupApi.useUpdateNewsletterPATCH();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <AdminGroupApi.FetchGetNewsletterDetailGET
            id={props.route?.params?.id ?? defaultProps.id}
            refetchInterval={0}
            refetchOnMount={false}
            refetchOnReconnect={false}
            refetchOnWindowFocus={false}
            retry={true}
            staleTime={0}
          >
            {({ loading, error, data, refetchGetNewsletterDetail }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleKeyboardAwareScrollView
                  enableAutomaticScroll={false}
                  enableOnAndroid={false}
                  enableResetScrollToCoords={false}
                  keyboardShouldPersistTaps={'never'}
                  showsVerticalScrollIndicator={true}
                  viewIsInsideTabBar={false}
                  style={StyleSheet.applyWidth(
                    {
                      paddingBottom: {
                        minWidth: Breakpoints.Laptop,
                        value: 200,
                      },
                    },
                    dimensions.width
                  )}
                >
                  <CurrentEditingSectionBlock editingLabel={fetchData?.title} />
                  <NewsletterFormBlock
                    onSubmit={(
                      version,
                      title,
                      date,
                      teaser,
                      status,
                      potd,
                      events_dk_ids,
                      events_se_ids,
                      events_no_ids,
                      events_fi_ids,
                      events_dach_large_cap_ids,
                      events_dach_mid_ids,
                      events_dach_small_ids
                    ) => {
                      const handler = async () => {
                        try {
                          setIsSubmitting(true);
                          const resultUpdated = (
                            await adminGroupUpdateNewsletterPATCH.mutateAsync({
                              date: date,
                              events_dach_large_cap_ids: getIds(
                                events_dach_large_cap_ids
                              ),
                              events_dach_mid_ids: getIds(events_dach_mid_ids),
                              events_dach_small_ids: getIds(
                                events_dach_small_ids
                              ),
                              events_dk_ids: getIds(events_dk_ids),
                              events_fi_ids: getIds(events_fi_ids),
                              events_no_ids: getIds(events_no_ids),
                              events_se_ids: getIds(events_se_ids),
                              id: props.route?.params?.id ?? defaultProps.id,
                              potd: potd?.id,
                              status: status,
                              teaser: teaser,
                              title: title,
                              version: version,
                            })
                          )?.json;
                          setIsSubmitting(false);
                          if (resultUpdated?.error) {
                            toastMessage(resultUpdated?.message, 'error');
                          } else {
                            toastMessage(
                              'Update newsletter successfully',
                              'success'
                            );
                            navigation.goBack();
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    initialData={fetchData}
                    isSubmitting={isSubmitting}
                  />
                </SimpleStyleKeyboardAwareScrollView>
              );
            }}
          </AdminGroupApi.FetchGetNewsletterDetailGET>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(NewsletterEditScreen);
