import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as reactTextAutosize from '../custom-files/reactTextAutosize';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  maxRows: 4,
  minRows: 15,
  onChange: () => {},
  placeholder: null,
  value: '',
};

const AutoSizeInputBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View
      style={StyleSheet.applyWidth(
        { width: { minWidth: Breakpoints.Laptop, value: '100%' } },
        dimensions.width
      )}
    >
      <Utils.CustomCodeErrorBoundary>
        <reactTextAutosize.Index
          {...{
            value: props?.value ?? '',
            placeholder: props?.placeholder ?? 'Input...',
            onChange: props?.onChange,
            maxRows: props?.maxRows ?? 15,
            minRows: props?.minRows ?? 4,
          }}
        />
      </Utils.CustomCodeErrorBoundary>
    </View>
  );
};

export default withTheme(AutoSizeInputBlock);
