import React from 'react';
import {
  HStack,
  Icon,
  LinearGradient,
  MultiSelectPicker,
  NumberInput,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Stepper,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CustomMultiSelectPickerBlock from '../components/CustomMultiSelectPickerBlock';
import EmptyListStateBlock from '../components/EmptyListStateBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertToOptions from '../global-functions/convertToOptions';
import extractOptions from '../global-functions/extractOptions';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const PEPFListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [company, setCompany] = React.useState('');
  const [country_filter, setCountry_filter] = React.useState([]);
  const [currentStatus, setCurrentStatus] = React.useState('');
  const [current_holding_years, setCurrent_holding_years] =
    React.useState(null);
  const [current_holding_years_raw, setCurrent_holding_years_raw] =
    React.useState('');
  const [date_range_filter, setDate_range_filter] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [end_date_filter, setEnd_date_filter] = React.useState('');
  const [event_type_filters, setEvent_type_filters] = React.useState([]);
  const [gics, setGics] = React.useState('');
  const [gics_filter, setGics_filter] = React.useState([]);
  const [gics_options, setGics_options] = React.useState('');
  const [holdingFilter, setHoldingFilter] = React.useState('');
  const [holdingNumber, setHoldingNumber] = React.useState(0);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  const [nextPage, setNextPage] = React.useState(0);
  const [nextPageAvailable, setNextPageAvailable] = React.useState(false);
  const [numRecs, setNumRecs] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [pefirm_q, setPefirm_q] = React.useState([]);
  const [pepf, setPepf] = React.useState(false);
  const [pepfList, setPepfList] = React.useState([]);
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [region_filter, setRegion_filter] = React.useState('');
  const [screenCode, setScreenCode] = React.useState('');
  const [stage_filter, setStage_filter] = React.useState([]);
  const [stake_filter, setStake_filter] = React.useState([]);
  const [start_date_filter, setStart_date_filter] = React.useState('');
  const [status_filter, setStatus_filter] = React.useState('');
  const [stepperValue, setStepperValue] = React.useState('');
  const [suggestionPEFirms, setSuggestionPEFirms] = React.useState([]);
  const [suggestion_invs_list, setSuggestion_invs_list] = React.useState([]);
  const [target, setTarget] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const shouldShowResetFilter = () => {
    if (
      pefirm_q?.length > 0 ||
      stake_filter?.length > 0 ||
      country_filter?.length > 0 ||
      gics_filter?.length > 0 ||
      currentStatus?.length > 0 ||
      holdingFilter?.length > 0 ||
      holdingNumber > 0
    ) {
      return true;
    }
    return false;
  };
  const adminGroupDeletePEPFDELETE = AdminGroupApi.useDeletePEPFDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:0 SET_VARIABLE');
        /* hidden 'Set Variable' action */ console.log(
          'Complete ON_SCREEN_FOCUS:0 SET_VARIABLE'
        );
        console.log('Start ON_SCREEN_FOCUS:1 SET_VARIABLE');
        /* hidden 'Set Variable' action */ console.log(
          'Complete ON_SCREEN_FOCUS:1 SET_VARIABLE'
        );
        console.log('Start ON_SCREEN_FOCUS:2 FETCH_REQUEST');
        const gics_result = (
          await AdminGroupApi.getGicsSubIndustryGET(Constants, {})
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:2 FETCH_REQUEST', {
          gics_result,
        });
        console.log('Start ON_SCREEN_FOCUS:3 SET_VARIABLE');
        setGics_options(
          convertToOptions(
            gics_result,
            'GICS_Sector',
            'id',
            'GICS_Sub_Industry'
          )
        );
        console.log('Complete ON_SCREEN_FOCUS:3 SET_VARIABLE');
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('PEPFNavigator', {
                screen: 'PEPFCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'Add PEPF'}
          showAddButton={true}
        />
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              flex: { minWidth: Breakpoints.Tablet, value: 1 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { height: 50 }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
                {/* company search */}
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        const resultTargets = (
                          await AdminGroupApi.getPepfGET(Constants, {
                            company_name: searchKeyword,
                            keyword: '',
                            page: 1,
                          })
                        )?.json;
                        const suggestion_company = transformToSearchInputFormat(
                          resultTargets?.items,
                          'Company',
                          'id',
                          undefined
                        );
                        setSuggestionPEFirms(suggestion_company);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onClear={() => {
                    try {
                      setTarget(null);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onSelect={selectedResult => {
                    try {
                      setTarget(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  customPlaceholder={'Company...'}
                  defaultValue={gics}
                  multiSelect={false}
                  selectedItem={company}
                  suggestionsList={suggestionPEFirms}
                />
              </View>
              {/* Filter Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                {/* PE_Firm */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'PE Firm'}
                  </Text>
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        console.log(
                          'Fuzzy Input With Data getSuggestions Start'
                        );
                        let error = null;
                        try {
                          console.log('Start null:0 FETCH_REQUEST');
                          const inv_list = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          console.log('Complete null:0 FETCH_REQUEST', {
                            inv_list,
                          });
                          console.log('Start null:2 CUSTOM_FUNCTION');
                          const suggestion_invs = transformToSearchInputFormat(
                            inv_list?.items,
                            'name',
                            'id',
                            undefined
                          );
                          console.log('Complete null:2 CUSTOM_FUNCTION', {
                            suggestion_invs,
                          });
                          console.log('Start null:3 SET_VARIABLE');
                          setSuggestion_invs_list(suggestion_invs);
                          console.log('Complete null:3 SET_VARIABLE');
                        } catch (err) {
                          console.error(err);
                          error = err.message ?? err;
                        }
                        console.log(
                          'Fuzzy Input With Data getSuggestions Complete',
                          error ? { error } : 'no error'
                        );
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        /* hidden 'Log to Console' action */
                        setPefirm_q(extractOptions(selectedResult, 'id'));
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={pefirm_q}
                    suggestionsList={suggestion_invs_list}
                  />
                </View>
                {/* Stake Type */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Stake Type'}
                  </Text>
                  {/* Custom Multi Select Picker 2 */}
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setStake_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['PEPF_STAKE_TYPE_OPTIONS']}
                    placeholder={'Select stake type'}
                    selectedOptions={stake_filter}
                  />
                </View>
                {/* Country */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Country'}
                  </Text>
                  {/* Custom Multi Select Picker 2 */}
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setCountry_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['COUNTRY_OPTIONS']}
                    placeholder={'Select country'}
                    selectedOptions={country_filter}
                  />
                </View>
              </View>
              {/* Filter Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                {/* Gics */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'GICS'}
                  </Text>
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setGics_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['GICS_OPTIONS']}
                    placeholder={'Select gics'}
                    selectedOptions={gics_filter}
                  />
                </View>
                {/* Current or Exited */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Current or Exited?'}
                  </Text>
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        setCurrentStatus(newPickerValue);
                        setCurrentStatus(newPickerValue.toString());
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['Picker'].props}
                    options={[
                      { label: 'Current', value: 'Current' },
                      { label: 'Exited', value: 'Exited' },
                    ]}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.PickerStyles(theme)['Picker'].style,
                        theme.typography.body2,
                        {}
                      ),
                      dimensions.width
                    )}
                    value={currentStatus}
                  />
                </View>
                {/* Holding Period */}
                <View>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Holding Period'}
                  </Text>

                  <HStack
                    {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      dimensions.width
                    )}
                  >
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.base}
                      dropDownBorderColor={theme.colors.border.base}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setHoldingFilter(newPickerValue);
                          if (holdingFilter === '') {
                            setHoldingNumber(0);
                          } else {
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['Picker'].props}
                      options={Constants['RANGE_OPTIONS']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.PickerStyles(theme)['Picker'].style,
                          theme.typography.body2,
                          {}
                        ),
                        dimensions.width
                      )}
                      value={holdingFilter}
                    />
                    <>
                      {!holdingFilter ? null : (
                        <Stepper
                          iconColor={theme.colors.text.strong}
                          iconSize={24}
                          onChange={newStepperValue => {
                            try {
                              setHoldingNumber(newStepperValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          min={0}
                          value={holdingNumber}
                        />
                      )}
                    </>
                  </HStack>
                </View>
              </View>
              {/* Blank */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    gap: { minWidth: Breakpoints.Laptop, value: 5 },
                  },
                  dimensions.width
                )}
              >
                <>
                  {!shouldShowResetFilter() ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { gap: { minWidth: Breakpoints.Laptop, value: 5 } },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Reset filter'}
                      </Text>

                      <Pressable
                        onPress={() => {
                          try {
                            undefined;
                            setStake_filter([]);
                            setCountry_filter([]);
                            setGics_filter([]);
                            setCurrentStatus([]);
                            setHoldingFilter('');
                            setHoldingNumber(null);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors.branding.primary,
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 8,
                              },
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: 50,
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: 50,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={24}
                            color={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: palettes.App.White,
                                },
                              ],
                              dimensions.width
                            )}
                            name={'MaterialCommunityIcons/window-close'}
                            size={StyleSheet.getWidthValue(
                              [{ minWidth: Breakpoints.Laptop, value: 26 }],
                              dimensions.width
                            )}
                          />
                        </View>
                      </Pressable>
                    </View>
                  )}
                </>
              </View>
              <>
                {!numRecs ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        theme.typography.body1,
                        {}
                      ),
                      dimensions.width
                    )}
                  >
                    {numRecs}
                    {' records matching filter'}
                  </Text>
                )}
              </>
            </View>
          </View>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.4 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                />
                {/* Acquired Date */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.5 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Acquired Date'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Company */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Company'}
                  </Text>
                </View>
                {/* PE firm */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.5, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'PE firm'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Fund Entity */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Fund Entity'}
                  </Text>
                </View>
                {/* Country */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderColor: palettes.App.Outline,
                          flex: 0.5,
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Country'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Exit date */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Exit date'}
                      </Text>
                    </View>
                  )}
                </>
              </View>
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetPepfGET
                  company_name={target?.title}
                  country={country_filter}
                  currentStatus={currentStatus}
                  current_holding_years={holdingNumber}
                  gics={gics_filter}
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        /* hidden 'Log to Console' action */
                        setPepfList(fetchData?.json?.items);
                        setNumRecs(fetchData?.json?.itemsTotal);
                        setNextPage(fetchData?.json?.nextPage);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  holding_options={holdingFilter}
                  keyword={keywordSearch}
                  page={1}
                  pe_firm={pefirm_q}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  retry={true}
                  stake_type={stake_filter}
                  staleTime={0}
                >
                  {({ loading, error, data, refetchGetPepf }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <>
                          {!(pepfList?.length > 0) ? null : (
                            <SimpleStyleFlatList
                              data={pepfList}
                              decelerationRate={'normal'}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(listData)
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={
                                'Linear Gradient->View->Table->Container->Table Content->Fetch->List'
                              }
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReached={() => {
                                const handler = async () => {
                                  console.log('List ON_END_REACHED Start');
                                  let error = null;
                                  try {
                                    console.log(
                                      'Start ON_END_REACHED:0 CONDITIONAL_STOP'
                                    );
                                    if (nextPage === null) {
                                      return console.log(
                                        'Complete ON_END_REACHED:0 CONDITIONAL_STOP'
                                      );
                                    } else {
                                      console.log(
                                        'Skipped ON_END_REACHED:0 CONDITIONAL_STOP: condition not met'
                                      );
                                    }
                                    console.log(
                                      'Start ON_END_REACHED:1 FETCH_REQUEST'
                                    );
                                    const result = (
                                      await AdminGroupApi.getPepfGET(
                                        Constants,
                                        {
                                          company_name: target,
                                          country: country_filter,
                                          currentStatus: currentStatus,
                                          current_holding_years: holdingNumber,
                                          gics: gics_filter,
                                          holding_options: holdingFilter,
                                          keyword: keywordSearch,
                                          page: nextPage,
                                          pe_firm: pefirm_q,
                                          stake_type: stake_filter,
                                        }
                                      )
                                    )?.json;
                                    console.log(
                                      'Complete ON_END_REACHED:1 FETCH_REQUEST',
                                      { result }
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:2 SET_VARIABLE'
                                    );
                                    setNextPage(result?.nextPage);
                                    console.log(
                                      'Complete ON_END_REACHED:2 SET_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:3 SET_VARIABLE'
                                    );
                                    setPepfList(pepfList.concat(result?.items));
                                    console.log(
                                      'Complete ON_END_REACHED:3 SET_VARIABLE'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                    error = err.message ?? err;
                                  }
                                  console.log(
                                    'List ON_END_REACHED Complete',
                                    error ? { error } : 'no error'
                                  );
                                };
                                handler();
                              }}
                              pagingEnabled={false}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'auto',
                                        },
                                        backgroundColor: getColorByIndex(index),
                                        borderColor: palettes.App.Outline,
                                        borderLeftWidth: 1,
                                        flexDirection: 'row',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Actions */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.4,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          flexDirection: 'row',
                                          height: '100%',
                                          justifyContent: 'center',
                                          maxWidth: 150,
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <TableActionsBlock
                                        onPressDelete={() => {
                                          try {
                                            setDeletingItem(listData);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        onPressEdit={() => {
                                          try {
                                            navigation.navigate(
                                              'PEPFNavigator',
                                              {
                                                screen: 'PEPFEditScreen',
                                                params: { id: listData?.id },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        border={false}
                                      />
                                    </View>
                                    {/* acquired_date */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.5,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.acquired_date}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* company */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.Company}
                                      </Text>
                                    </View>
                                    {/* pe_firm */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.5,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?._pe_firm?.name}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* fund_entity */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.5,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?._fund_entity?.name}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Country */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.country}
                                      </Text>
                                    </View>
                                    {/* exit_date */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0.5,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.exit_date}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                );
                              }}
                              snapToAlignment={'start'}
                              onEndReachedThreshold={0.25}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: {
                                    minWidth: Breakpoints.Laptop,
                                    value:
                                      dimensions.width >= Breakpoints.Laptop
                                        ? 0
                                        : undefined,
                                  },
                                  maxHeight: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: dimensions.height - 250,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: dimensions.height - 280,
                                    },
                                  ],
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(pepfList?.length === 0) ? null : (
                            <EmptyListStateBlock />
                          )}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetPepfGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeletePepf = (
                  await adminGroupDeletePEPFDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeletePepf?.error) {
                  toastMessage(resultDeletePepf?.message, 'error');
                } else {
                  toastMessage('Delete CFS successfully', 'success');
                  setDeletingItem(null);
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'PEPF'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(PEPFListingScreen);
