import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const endpointForRequestingMagicLinkToLoginToApp$GET = async (
  Constants,
  { email },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (email !== undefined) {
    paramsDict['email'] = renderParam(email);
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:6hazS0TY/auth/request-magic-link${renderQueryString(
    paramsDict
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useEndpointForRequestingMagicLinkToLoginToApp$GET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['auth', args],
    () =>
      endpointForRequestingMagicLinkToLoginToApp$GET(
        Constants,
        args,
        handlers,
        timeout
      ),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['auths']),
    }
  );
};

export const FetchEndpointForRequestingMagicLinkToLoginToApp$GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEndpointForRequestingMagicLinkToLoginToApp$GET(
    { email },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchEndpointForRequestingMagicLinkToLoginToApp$: refetch,
  });
};

export const exchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST =
  async (Constants, { magic_token }, handlers, timeout) => {
    const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:6hazS0TY/auth/magic-login`;
    const controller = new AbortController();
    let timeoutObj;
    if (timeout) {
      timeoutObj = setTimeout(() => {
        const err = new Error(`Timeout after ${timeout}ms`);
        err.__type = 'TIMEOUT';
        controller.abort(err);
      }, timeout);
    }
    try {
      const res = await fetch(url, {
        body: JSON.stringify({ magic_token: magic_token }),
        headers: cleanHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        signal: controller.signal,
      });
      timeoutObj && clearTimeout(timeoutObj);
      return handleResponse(res, handlers);
    } catch (e) {
      if (e.__type === 'TIMEOUT') {
        handlers.onTimeout?.();
      } else if (timeoutObj) {
        clearTimeout(timeoutObj);
      }
      throw e;
    }
  };

export const useExchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    [
      'passwordResetProcessExchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST',
      args,
    ],
    () =>
      exchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST(
        Constants,
        args,
        handlers,
        timeout
      ),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'passwordResetProcessExchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POSTS',
        ]),
    }
  );
};

export const FetchExchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST =
  ({
    children,
    onData = () => {},
    handlers = {},
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    magic_token,
  }) => {
    const Constants = GlobalVariables.useValues();
    const isFocused = useIsFocused();
    const prevIsFocused = usePrevious(isFocused);

    const {
      isLoading: loading,
      data,
      error,
      refetch,
    } = useExchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST(
      { magic_token },
      {
        refetchInterval,
        refetchOnWindowFocus,
        refetchOnMount,
        refetchOnReconnect,
        retry,
        staleTime,
        timeout,
        handlers: { onData, ...handlers },
      }
    );

    React.useEffect(() => {
      if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
        refetch();
      }
    }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

    React.useEffect(() => {
      if (error) {
        console.error(error);
        if (error.status) {
          console.error(
            'Fetch error: ' + error.status + ' ' + error.statusText
          );
        }
      }
    }, [error]);
    return children({
      loading,
      data,
      error,
      refetchExchangesMagicTokenForAuthToken$LogsInUserWithTheLink$: refetch,
    });
  };

export const pOST$updatePasswordPOST = async (
  Constants,
  { confirm_password, password },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:6hazS0TY/update_password`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        password: password,
        confirm_password: confirm_password,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['RESET_AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const usePOST$updatePasswordPOST = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['passwordResetProcessPOST$updatePasswordPOST', args],
    () => pOST$updatePasswordPOST(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'passwordResetProcessPOST$updatePasswordPOSTS',
        ]),
    }
  );
};

export const FetchPOST$updatePasswordPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  confirm_password,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePOST$updatePasswordPOST(
    { confirm_password, password },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPOST$updatePassword: refetch,
  });
};

export const useThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGET =
  async (Constants, _args, handlers, timeout) => {
    const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:6hazS0TY/generate_jwt_secret`;
    const controller = new AbortController();
    let timeoutObj;
    if (timeout) {
      timeoutObj = setTimeout(() => {
        const err = new Error(`Timeout after ${timeout}ms`);
        err.__type = 'TIMEOUT';
        controller.abort(err);
      }, timeout);
    }
    try {
      const res = await fetch(url, {
        headers: cleanHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        signal: controller.signal,
      });
      timeoutObj && clearTimeout(timeoutObj);
      return handleResponse(res, handlers);
    } catch (e) {
      if (e.__type === 'TIMEOUT') {
        handlers.onTimeout?.();
      } else if (timeoutObj) {
        clearTimeout(timeoutObj);
      }
      throw e;
    }
  };

export const useUseThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGET =
  (
    args = {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers = {},
    } = {}
  ) => {
    const Constants = GlobalVariables.useValues();
    const queryClient = useQueryClient();
    return useQuery(
      [
        'passwordResetProcessUseThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGET',
        args,
      ],
      () =>
        useThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGET(
          Constants,
          args,
          handlers,
          timeout
        ),
      {
        refetchInterval,
        refetchOnWindowFocus,
        refetchOnMount,
        refetchOnReconnect,
        retry,
        staleTime,
        onSuccess: () =>
          queryClient.invalidateQueries([
            'passwordResetProcessUseThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGETS',
          ]),
      }
    );
  };

export const FetchUseThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGET =
  ({
    children,
    onData = () => {},
    handlers = {},
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
  }) => {
    const Constants = GlobalVariables.useValues();
    const isFocused = useIsFocused();
    const prevIsFocused = usePrevious(isFocused);

    const {
      isLoading: loading,
      data,
      error,
      refetch,
    } = useUseThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecretGET(
      {},
      {
        refetchInterval,
        refetchOnWindowFocus,
        refetchOnMount,
        refetchOnReconnect,
        retry,
        staleTime,
        timeout,
        handlers: { onData, ...handlers },
      }
    );

    React.useEffect(() => {
      if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
        refetch();
      }
    }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

    React.useEffect(() => {
      if (error) {
        console.error(error);
        if (error.status) {
          console.error(
            'Fetch error: ' + error.status + ' ' + error.statusText
          );
        }
      }
    }, [error]);
    return children({
      loading,
      data,
      error,
      refetchUseThisToGenerateAJWTSecretToSetAsYourEnvironmentVariable$MagicLinkJwtSecret:
        refetch,
    });
  };
