import TextareaAutosize from 'react-textarea-autosize';
import React, { useCallback, useState } from 'react';

import { View } from 'react-native';

const Index = ({
  onChange = () => {},
  // props = props,
  placeholder = placeholder,
  value: initialValue = '',
  minRows: initialMinRows = 4,
  maxRows: initialMaxRows = 15,
}) => {
  const [value, setValue] = useState(initialValue);
  const [minRows, setMinRows] = useState(initialMinRows);
  const [maxRows, setMaxRows] = useState(initialMaxRows);

  const handleChange = useCallback(
    input => {
      onChange(input.target.value);
    },
    [setValue, onChange]
  );

  return (
    <View>
      <TextareaAutosize
        minRows={minRows}
        maxRows={maxRows}
        defaultValue={value}
        placeholder={placeholder}
        style={{
          fontFamily: 'Quicksand_500Medium',
          fontSize: 15,
        }}
        onChange={handleChange}
      />
    </View>
  );
};

export { Index };
