import React from 'react';
import {
  Button,
  DatePicker,
  MultiSelectPicker,
  NumberInput,
  Picker,
  Pressable,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import AutoSizeInputBlock from '../components/AutoSizeInputBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  inlineField: null,
  inlineFieldType: null,
  inlinePage: null,
  inlineRecID: null,
  inlineValue: null,
  isLoadingConfirm: null,
  onCancel: () => {},
  onSubmit: () => {},
  pickerOptions: null,
  visible: null,
};

const InlineEditModalBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [inlineTextField, setInlineTextField] = props.setInlineTextField
    ? [
        props.inlineTextField !== undefined ? props.inlineTextField : '',
        props.setInlineTextField,
      ]
    : React.useState('');
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [showInlineEdit, setShowInlineEdit] = props.setShowInlineEdit
    ? [
        props.showInlineEdit !== undefined ? props.showInlineEdit : false,
        props.setShowInlineEdit,
      ]
    : React.useState(false);
  const [suggestionFuzzy, setSuggestionFuzzy] = props.setSuggestionFuzzy
    ? [
        props.suggestionFuzzy !== undefined ? props.suggestionFuzzy : [],
        props.setSuggestionFuzzy,
      ]
    : React.useState([]);
  const [textInputValue, setTextInputValue] = React.useState('');
  const createInlineObject = (
    inlineField,
    inlinePage,
    inlineRecID,
    inlineValue
  ) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return {
      table: inlinePage,
      record_id: inlineRecID,
      field: inlineField,
      value: inlineValue,
    };
  };

  return (
    <Modal
      supportedOrientations={['portrait', 'landscape']}
      animationType={'fade'}
      presentationStyle={StyleSheet.getWidthValue(
        [{ minWidth: Breakpoints.Laptop, value: 'pageSheet' }],
        dimensions.width
      )}
      transparent={true}
      visible={Boolean(props.visible ?? defaultProps.visible)}
    >
      <Pressable
        onPress={() => {
          try {
            setShowInlineEdit(false);
          } catch (err) {
            console.error(err);
          }
        }}
        androidRippleBorderless={StyleSheet.getWidthValue(
          [{ minWidth: Breakpoints.Laptop, value: true }],
          dimensions.width
        )}
        androidRippleColor={StyleSheet.getWidthValue(
          [
            {
              minWidth: Breakpoints.Laptop,
              value: theme.colors.branding.primary,
            },
          ],
          dimensions.width
        )}
        androidRippleForeground={StyleSheet.getWidthValue(
          [
            {
              minWidth: Breakpoints.Laptop,
              value: theme.colors.branding.primary,
            },
          ],
          dimensions.width
        )}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* Overlay */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.Overlay,
              height: '100%',
              position: 'absolute',
              width: '100%',
            },
            dimensions.width
          )}
        />
      </Pressable>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            alignItems: 'center',
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            flex: 1,
            justifyContent: 'center',
            position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
            top: { minWidth: Breakpoints.Laptop, value: '30%' },
            width: {
              minWidth: Breakpoints.Laptop,
              value:
                (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
                  'autosizetext' ||
                (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
                  'picker'
                  ? '75%'
                  : undefined,
            },
          },
          dimensions.width
        )}
      >
        {/* Card */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: theme.colors.border.base,
              },
              borderRadius: 15,
              gap: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 30 },
              ],
              paddingBottom: [
                { minWidth: Breakpoints.Mobile, value: 40 },
                { minWidth: Breakpoints.Laptop, value: 20 },
              ],
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 20 },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 20 },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Mobile, value: 40 },
                { minWidth: Breakpoints.Laptop, value: 20 },
              ],
              width: {
                minWidth: Breakpoints.Laptop,
                value:
                  (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
                    'autosizetext' ||
                  (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
                    'picker'
                    ? '75%'
                    : undefined,
              },
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Common Strong Inverse'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Common Strong Inverse'].style,
                {
                  color: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors.text.strong,
                  },
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: 'Quicksand_600SemiBold',
                    },
                  ],
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 18 },
                  ],
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Editing '}
            {props.inlineField ?? defaultProps.inlineField}
            {' of '}
            {props.inlinePage ?? defaultProps.inlinePage}
            {' record - '}
            {props.inlineRecID ?? defaultProps.inlineRecID}
          </Text>
          <>
            {!(
              (props.inlineFieldType ?? defaultProps.inlineFieldType) === 'text'
            ) ? null : (
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  const textInputValue = newTextInputValue;
                  try {
                    setInlineTextField(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    { backgroundColor: palettes.Brand.Strong_Inverse }
                  ),
                  dimensions.width
                )}
                value={inlineTextField}
              />
            )}
          </>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <>
              {!(
                (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
                'picker'
              ) ? null : (
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.base}
                  dropDownBorderColor={theme.colors.border.base}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setInlineTextField(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['Picker'].props}
                  options={props.pickerOptions ?? defaultProps.pickerOptions}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.PickerStyles(theme)['Picker'].style,
                      theme.typography.body2,
                      {
                        minWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: '100%',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                  value={inlineTextField}
                />
              )}
            </>
          </View>
          <>
            {!(
              (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
              'multi picker'
            ) ? null : (
              <MultiSelectPicker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.base}
                dropDownBorderColor={theme.colors.border.base}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                onValueChange={newMultiSelectPickerValue => {
                  try {
                    setInlineTextField(newMultiSelectPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Select an option'}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.MultiSelectPickerStyles(theme)[
                  'Multi Select Picker'
                ].props}
                options={props.pickerOptions ?? defaultProps.pickerOptions}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.MultiSelectPickerStyles(theme)[
                      'Multi Select Picker'
                    ].style,
                    theme.typography.body2,
                    {}
                  ),
                  dimensions.width
                )}
                value={inlineTextField}
              />
            )}
          </>
          <>
            {!(
              (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
              datePickerValue
            ) ? null : (
              <DatePicker
                autoDismissKeyboard={true}
                disabled={false}
                hideLabel={false}
                inline={false}
                label={'Date'}
                leftIconMode={'inset'}
                mode={'date'}
                onDateChange={newDatePickerValue => {
                  try {
                    setInlineTextField(newDatePickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                type={'solid'}
                {...GlobalStyles.DatePickerStyles(theme)['Date Picker'].props}
                date={inlineTextField}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.DatePickerStyles(theme)['Date Picker'].style,
                    theme.typography.body2,
                    {}
                  ),
                  dimensions.width
                )}
              />
            )}
          </>
          <>
            {!(
              (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
              'number'
            ) ? null : (
              <NumberInput
                changeTextDelay={500}
                onChangeText={newNumberInputValue => {
                  try {
                    setInlineTextField(newNumberInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a number...'}
                webShowOutline={true}
                {...GlobalStyles.NumberInputStyles(theme)['Number Input'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.NumberInputStyles(theme)['Number Input'].style,
                    theme.typography.body2,
                    {}
                  ),
                  dimensions.width
                )}
                value={inlineTextField}
              />
            )}
          </>
          <>
            {!(
              (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
              'autosizetext'
            ) ? null : (
              <AutoSizeInputBlock
                onChange={result => {
                  try {
                    setInlineTextField(result);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={props.inlineValue ?? defaultProps.inlineValue}
              />
            )}
          </>
          <>
            {!(
              (props.inlineFieldType ?? defaultProps.inlineFieldType) ===
              'fuzzy'
            ) ? null : (
              <FuzzyInputWithDataBlock
                getSuggestions={searchKeyword => {
                  const handler = async () => {
                    console.log('Fuzzy Input With Data getSuggestions Start');
                    let error = null;
                    try {
                      console.log('Start null:0 CONSOLE_LOG');
                      console.log(
                        props.inlineField ?? defaultProps.inlineField
                      );
                      console.log('Complete null:0 CONSOLE_LOG');
                      console.log('Start null:1 IF');
                      if (
                        (props.inlineField ?? defaultProps.inlineField) ===
                        'gics'
                      ) {
                        console.log('conditional passed');
                        const resultGICS = (
                          await AdminGroupApi.getGicsSubIndustryGET(Constants, {
                            keyword: searchKeyword,
                          })
                        )?.json;
                        const new_suggestions = transformToSearchInputFormat(
                          resultGICS,
                          'GICS_Sector',
                          'id',
                          'GICS_Sub_Industry'
                        );
                        setSuggestionFuzzy(new_suggestions);
                      } else {
                        console.log('conditional failed');
                      }
                      console.log('Complete null:1 IF');
                    } catch (err) {
                      console.error(err);
                      error = err.message ?? err;
                    }
                    console.log(
                      'Fuzzy Input With Data getSuggestions Complete',
                      error ? { error } : 'no error'
                    );
                  };
                  handler();
                }}
                onSelect={selectedResult => {
                  try {
                    setInlineTextField(selectedResult);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                multiSelect={false}
                selectedItem={props.inlineValue ?? defaultProps.inlineValue}
                suggestionsList={suggestionFuzzy}
              />
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: 'row', gap: 20 },
              dimensions.width
            )}
          >
            {/* Submit Button */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  /* hidden 'Run a Custom Function' action */
                  /* hidden 'Set Variable' action */
                  props.onSubmit?.();
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
              disabledOpacity={0.5}
              loading={Boolean(
                props.isLoadingConfirm ?? defaultProps.isLoadingConfirm
              )}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.Brand.Error,
                    },
                  }
                ),
                dimensions.width
              )}
              title={'Submit'}
            />
            {/* Cancel Button */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  setInlineTextField('');
                  props.onCancel?.();
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
                dimensions.width
              )}
              title={'Cancel'}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default withTheme(InlineEditModalBlock);
