import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const addNoteToPropCasePATCH = async (
  Constants,
  { note, prop_case_id, timestamp, user_admin_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_case/note/${encodeQueryParam(
    prop_case_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        timestamp: timestamp,
        note: note,
        user_admin_id: user_admin_id,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useAddNoteToPropCasePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addNoteToPropCasePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('prop_case', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('prop_case');
        queryClient.invalidateQueries('prop_cases');
      },
    }
  );
};

export const createAdvisorPOST = async (
  Constants,
  { name, type },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({ name: name, type: type }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateAdvisorPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createAdvisorPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('advisor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('advisor');
        queryClient.invalidateQueries('advisors');
      },
    }
  );
};

export const FetchCreateAdvisorPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  name,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAdvisorPOST(
    { name, type },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAdvisor: refetch });
};

export const createCFSPOST = async (
  Constants,
  {
    advisors_cf,
    advisors_legal,
    company,
    company_description,
    country,
    currency,
    ebitda,
    event,
    first_added,
    fy_end,
    gics,
    growth,
    link_financials,
    logo_url,
    note_financials,
    other_events,
    owners,
    peer_group,
    pepf,
    revenue,
    source_type,
    stage,
    strat_or_invf_owner,
    timeline,
    transacted,
    website,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        first_added: first_added,
        company: company,
        owners: owners,
        country: country,
        gics: gics,
        event: event,
        other_events: other_events,
        company_description: company_description,
        website: website,
        logo_url: logo_url,
        stage: stage,
        Timeline: timeline,
        pepf: pepf,
        peer_group: peer_group,
        source_type: source_type,
        revenue: revenue,
        ebitda: ebitda,
        growth: growth,
        fy_end: fy_end,
        currency: currency,
        link_financials: link_financials,
        note_financials: note_financials,
        advisors_cf: advisors_cf,
        advisors_legal: advisors_legal,
        transacted: transacted,
        strat_or_invf_owner: strat_or_invf_owner,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateCFSPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createCFSPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('cfs', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('cf');
        queryClient.invalidateQueries('cfs');
      },
    }
  );
};

export const FetchCreateCFSPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  advisors_cf,
  advisors_legal,
  company,
  company_description,
  country,
  currency,
  ebitda,
  event,
  first_added,
  fy_end,
  gics,
  growth,
  link_financials,
  logo_url,
  note_financials,
  other_events,
  owners,
  peer_group,
  pepf,
  revenue,
  source_type,
  stage,
  strat_or_invf_owner,
  timeline,
  transacted,
  website,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCFSPOST(
    {
      advisors_cf,
      advisors_legal,
      company,
      company_description,
      country,
      currency,
      ebitda,
      event,
      first_added,
      fy_end,
      gics,
      growth,
      link_financials,
      logo_url,
      note_financials,
      other_events,
      owners,
      peer_group,
      pepf,
      revenue,
      source_type,
      stage,
      strat_or_invf_owner,
      timeline,
      transacted,
      website,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateCFS: refetch });
};

export const createCrawlerSourcePOST = async (
  Constants,
  {
    base_url,
    crawl_attachments,
    in_production,
    language,
    source_name,
    user_email,
    user_password,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        in_production: in_production,
        source_name: source_name,
        base_url: base_url,
        user_email: user_email,
        user_password: user_password,
        language: language,
        crawl_attachments: crawl_attachments,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateCrawlerSourcePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createCrawlerSourcePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('crawler_source', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('crawler_source');
        queryClient.invalidateQueries('crawler_sources');
      },
    }
  );
};

export const FetchCreateCrawlerSourcePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  base_url,
  crawl_attachments,
  in_production,
  language,
  source_name,
  user_email,
  user_password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCrawlerSourcePOST(
    {
      base_url,
      crawl_attachments,
      in_production,
      language,
      source_name,
      user_email,
      user_password,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateCrawlerSource: refetch,
  });
};

export const createEventPOST = async (
  Constants,
  {
    below_league_threshold,
    buyer,
    buyside_cf,
    buyside_legal,
    country,
    currency_ev,
    currency_financials,
    description,
    ebit_local,
    ebitda_local,
    ev,
    ev_source,
    event_type,
    fy_end,
    gics,
    gross_profit_local,
    headline,
    include_in_multiples_db,
    link_to_financials,
    message_to_admin,
    note_financials,
    pefirm_behind_buyer,
    pepf,
    published,
    related_previous_coverage,
    revenue_local,
    reviewed_by,
    seller,
    sellside_cf,
    sellside_legal,
    source,
    source_link,
    source_type,
    status,
    strat_or_invf_buyer,
    strat_or_invf_seller,
    target,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        published: published,
        headline: headline,
        description: description,
        source: source,
        source_type: source_type,
        message_to_admin: message_to_admin,
        source_link: source_link,
        related_previous_coverage: related_previous_coverage,
        country: country,
        gics: gics,
        reviewed_by: reviewed_by,
        event_type: event_type,
        target: target,
        buyer: buyer,
        strat_or_invf_buyer: strat_or_invf_buyer,
        seller: seller,
        strat_or_invf_seller: strat_or_invf_seller,
        currency_financials: currency_financials,
        revenue_local: revenue_local,
        gross_profit_local: gross_profit_local,
        ebitda_local: ebitda_local,
        ebit_local: ebit_local,
        fy_end: fy_end,
        note_financials: note_financials,
        currency_ev: currency_ev,
        ev: ev,
        ev_source: ev_source,
        sellside_cf: sellside_cf,
        sellside_legal: sellside_legal,
        buyside_cf: buyside_cf,
        buyside_legal: buyside_legal,
        pepf: pepf,
        pefirm_behind_buyer: pefirm_behind_buyer,
        include_in_multiples_db: include_in_multiples_db,
        link_to_financials: link_to_financials,
        status: status,
        below_league_threshold: below_league_threshold,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateEventPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createEventPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('event', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('event');
        queryClient.invalidateQueries('events');
      },
    }
  );
};

export const FetchCreateEventPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  below_league_threshold,
  buyer,
  buyside_cf,
  buyside_legal,
  country,
  currency_ev,
  currency_financials,
  description,
  ebit_local,
  ebitda_local,
  ev,
  ev_source,
  event_type,
  fy_end,
  gics,
  gross_profit_local,
  headline,
  include_in_multiples_db,
  link_to_financials,
  message_to_admin,
  note_financials,
  pefirm_behind_buyer,
  pepf,
  published,
  related_previous_coverage,
  revenue_local,
  reviewed_by,
  seller,
  sellside_cf,
  sellside_legal,
  source,
  source_link,
  source_type,
  status,
  strat_or_invf_buyer,
  strat_or_invf_seller,
  target,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateEventPOST(
    {
      below_league_threshold,
      buyer,
      buyside_cf,
      buyside_legal,
      country,
      currency_ev,
      currency_financials,
      description,
      ebit_local,
      ebitda_local,
      ev,
      ev_source,
      event_type,
      fy_end,
      gics,
      gross_profit_local,
      headline,
      include_in_multiples_db,
      link_to_financials,
      message_to_admin,
      note_financials,
      pefirm_behind_buyer,
      pepf,
      published,
      related_previous_coverage,
      revenue_local,
      reviewed_by,
      seller,
      sellside_cf,
      sellside_legal,
      source,
      source_link,
      source_type,
      status,
      strat_or_invf_buyer,
      strat_or_invf_seller,
      target,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateEvent: refetch });
};

export const createFundPOST = async (
  Constants,
  {
    country_hq,
    description,
    fund_size_currency,
    fund_size_local,
    horizon,
    name,
    pe_firm,
    type,
    vintage_date,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        name: name,
        description: description,
        pe_firm: pe_firm,
        fund_size_local: fund_size_local,
        fund_size_currency: fund_size_currency,
        country_hq: country_hq,
        type: type,
        horizon: horizon,
        vintage_date: vintage_date,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateFundPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createFundPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('fund', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('fund');
        queryClient.invalidateQueries('funds');
      },
    }
  );
};

export const FetchCreateFundPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  country_hq,
  description,
  fund_size_currency,
  fund_size_local,
  horizon,
  name,
  pe_firm,
  type,
  vintage_date,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateFundPOST(
    {
      country_hq,
      description,
      fund_size_currency,
      fund_size_local,
      horizon,
      name,
      pe_firm,
      type,
      vintage_date,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateFund: refetch });
};

export const createInvestorPOST = async (
  Constants,
  { country_hq, name, type },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({ name: name, type: type, country_hq: country_hq }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateInvestorPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createInvestorPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('investor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('investor');
        queryClient.invalidateQueries('investors');
      },
    }
  );
};

export const FetchCreateInvestorPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  country_hq,
  name,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateInvestorPOST(
    { country_hq, name, type },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateInvestor: refetch });
};

export const createPEPFPOST = async (
  Constants,
  {
    Company,
    acquired_date,
    buyside_cf_entry,
    buyside_legal_entry,
    company_description,
    company_id,
    country,
    currency_financials,
    ebit_local,
    ebitda_local,
    exit_date,
    financial_year,
    financials_comment,
    fund_entity,
    gics,
    gross_profit_local,
    growth,
    investment_responsible,
    pe_firm,
    revenue_local,
    stake_type,
    website,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        acquired_date: acquired_date,
        Company: Company,
        company_id: company_id,
        country: country,
        gics: gics,
        pe_firm: pe_firm,
        fund_entity: fund_entity,
        investment_responsible: investment_responsible,
        company_description: company_description,
        website: website,
        buyside_cf_entry: buyside_cf_entry,
        buyside_legal_entry: buyside_legal_entry,
        exit_date: exit_date,
        revenue_local: revenue_local,
        gross_profit_local: gross_profit_local,
        ebitda_local: ebitda_local,
        ebit_local: ebit_local,
        growth: growth,
        currency_financials: currency_financials,
        financial_year: financial_year,
        financials_comment: financials_comment,
        stake_type: stake_type,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreatePEPFPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createPEPFPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('pepf', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('pepf');
        queryClient.invalidateQueries('pepfs');
      },
    }
  );
};

export const FetchCreatePEPFPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  Company,
  acquired_date,
  buyside_cf_entry,
  buyside_legal_entry,
  company_description,
  company_id,
  country,
  currency_financials,
  ebit_local,
  ebitda_local,
  exit_date,
  financial_year,
  financials_comment,
  fund_entity,
  gics,
  gross_profit_local,
  growth,
  investment_responsible,
  pe_firm,
  revenue_local,
  stake_type,
  website,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePEPFPOST(
    {
      Company,
      acquired_date,
      buyside_cf_entry,
      buyside_legal_entry,
      company_description,
      company_id,
      country,
      currency_financials,
      ebit_local,
      ebitda_local,
      exit_date,
      financial_year,
      financials_comment,
      fund_entity,
      gics,
      gross_profit_local,
      growth,
      investment_responsible,
      pe_firm,
      revenue_local,
      stake_type,
      website,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePEPF: refetch });
};

export const createPOTDPOST = async (
  Constants,
  {
    advisor,
    created_at,
    financials,
    gics,
    headline,
    hq,
    peer_group,
    stage,
    story_company_profile,
    story_comps,
    story_opportunity,
    target,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        created_at: created_at,
        headline: headline,
        story_company_profile: story_company_profile,
        story_opportunity: story_opportunity,
        story_comps: story_comps,
        target: target,
        financials: financials,
        advisor: advisor,
        stage: stage,
        hq: hq,
        peer_group: peer_group,
        gics: gics,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreatePOTDPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createPOTDPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('potd', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('potd');
        queryClient.invalidateQueries('potds');
      },
    }
  );
};

export const FetchCreatePOTDPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  advisor,
  created_at,
  financials,
  gics,
  headline,
  hq,
  peer_group,
  stage,
  story_company_profile,
  story_comps,
  story_opportunity,
  target,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePOTDPOST(
    {
      advisor,
      created_at,
      financials,
      gics,
      headline,
      hq,
      peer_group,
      stage,
      story_company_profile,
      story_comps,
      story_opportunity,
      target,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePOTD: refetch });
};

export const createPeerGroupPOST = async (
  Constants,
  { access_type, created_by, description, title },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        title: title,
        description: description,
        access_type: access_type,
        created_by: created_by,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreatePeerGroupPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createPeerGroupPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('peer_group', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('peer_group');
        queryClient.invalidateQueries('peer_groups');
      },
    }
  );
};

export const FetchCreatePeerGroupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  access_type,
  created_by,
  description,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePeerGroupPOST(
    { access_type, created_by, description, title },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePeerGroup: refetch });
};

export const createUsersClientsPOST = async (
  Constants,
  {
    Initial_Login_Complete,
    accessible_regions,
    account_activated_on,
    company,
    company_type,
    country,
    email,
    email_notification_dach,
    email_notification_nordic,
    expo_token,
    google_oauth,
    job_title,
    knack_id,
    last_login_android_app,
    last_login_ios_app,
    last_login_web_app,
    name,
    nordic_nl_section_1,
    nordic_nl_section_2,
    nordic_nl_section_3,
    nordic_nl_section_4,
    paid_dach,
    paid_nordic,
    password,
    push_notification_nl_dach,
    push_notification_nl_nordic,
    region,
    screenshot_notification_shown,
    temp_login,
    trial_end,
    trial_start,
    user_status,
    welcome_reminder_sent_on,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        country: country,
        accessible_regions: accessible_regions,
        job_title: job_title,
        company: company,
        company_type: company_type,
        trial_start: trial_start,
        trial_end: trial_end,
        paid_dach: paid_dach,
        paid_nordic: paid_nordic,
        user_status: user_status,
        Initial_Login_Complete: Initial_Login_Complete,
        push_notification_nl_nordic: push_notification_nl_nordic,
        push_notification_nl_dach: push_notification_nl_dach,
        welcome_reminder_sent_on: welcome_reminder_sent_on,
        nordic_nl_section_1: nordic_nl_section_1,
        nordic_nl_section_2: nordic_nl_section_2,
        nordic_nl_section_3: nordic_nl_section_3,
        nordic_nl_section_4: nordic_nl_section_4,
        email_notification_nordic: email_notification_nordic,
        email_notification_dach: email_notification_dach,
        region: region,
        google_oauth: google_oauth,
        last_login_web_app: last_login_web_app,
        last_login_android_app: last_login_android_app,
        last_login_ios_app: last_login_ios_app,
        temp_login: temp_login,
        knack_id: knack_id,
        account_activated_on: account_activated_on,
        expo_token: expo_token,
        screenshot_notification_shown: screenshot_notification_shown,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateUsersClientsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createUsersClientsPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('users_clients', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('users_client');
        queryClient.invalidateQueries('users_clients');
      },
    }
  );
};

export const FetchCreateUsersClientsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  Initial_Login_Complete,
  accessible_regions,
  account_activated_on,
  company,
  company_type,
  country,
  email,
  email_notification_dach,
  email_notification_nordic,
  expo_token,
  google_oauth,
  job_title,
  knack_id,
  last_login_android_app,
  last_login_ios_app,
  last_login_web_app,
  name,
  nordic_nl_section_1,
  nordic_nl_section_2,
  nordic_nl_section_3,
  nordic_nl_section_4,
  paid_dach,
  paid_nordic,
  password,
  push_notification_nl_dach,
  push_notification_nl_nordic,
  region,
  screenshot_notification_shown,
  temp_login,
  trial_end,
  trial_start,
  user_status,
  welcome_reminder_sent_on,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateUsersClientsPOST(
    {
      Initial_Login_Complete,
      accessible_regions,
      account_activated_on,
      company,
      company_type,
      country,
      email,
      email_notification_dach,
      email_notification_nordic,
      expo_token,
      google_oauth,
      job_title,
      knack_id,
      last_login_android_app,
      last_login_ios_app,
      last_login_web_app,
      name,
      nordic_nl_section_1,
      nordic_nl_section_2,
      nordic_nl_section_3,
      nordic_nl_section_4,
      paid_dach,
      paid_nordic,
      password,
      push_notification_nl_dach,
      push_notification_nl_nordic,
      region,
      screenshot_notification_shown,
      temp_login,
      trial_end,
      trial_start,
      user_status,
      welcome_reminder_sent_on,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateUsersClients: refetch });
};

export const createNewsletterPOST = async (
  Constants,
  {
    date,
    events_dach_large_cap_ids,
    events_dach_mid_ids,
    events_dach_small_ids,
    events_dk_ids,
    events_fi_ids,
    events_no_ids,
    events_se_ids,
    potd,
    status,
    teaser,
    title,
    version,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        version: version,
        title: title,
        date: date,
        teaser: teaser,
        status: status,
        potd: potd,
        events_dk: events_dk_ids,
        events_se: events_se_ids,
        events_no: events_no_ids,
        events_fi: events_fi_ids,
        events_dach_large_cap: events_dach_large_cap_ids,
        events_dach_mid: events_dach_mid_ids,
        events_dach_small: events_dach_small_ids,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useCreateNewsletterPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createNewsletterPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('newletters', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('newletter');
        queryClient.invalidateQueries('newletters');
      },
    }
  );
};

export const FetchCreateNewsletterPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  date,
  events_dach_large_cap_ids,
  events_dach_mid_ids,
  events_dach_small_ids,
  events_dk_ids,
  events_fi_ids,
  events_no_ids,
  events_se_ids,
  potd,
  status,
  teaser,
  title,
  version,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateNewsletterPOST(
    {
      date,
      events_dach_large_cap_ids,
      events_dach_mid_ids,
      events_dach_small_ids,
      events_dk_ids,
      events_fi_ids,
      events_no_ids,
      events_se_ids,
      potd,
      status,
      teaser,
      title,
      version,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateNewsletter: refetch });
};

export const deleteAdvisorDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteAdvisorDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAdvisorDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('advisor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('advisor');
        queryClient.invalidateQueries('advisors');
      },
    }
  );
};

export const deleteCFSDELETE = async (Constants, { id }, handlers, timeout) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteCFSDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteCFSDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('cfs', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('cf');
        queryClient.invalidateQueries('cfs');
      },
    }
  );
};

export const deleteEventDELETE = async (
  Constants,
  { event_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event/${encodeQueryParam(
    event_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteEventDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteEventDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('event', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('event');
        queryClient.invalidateQueries('events');
      },
    }
  );
};

export const deleteFundDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteFundDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteFundDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('fund', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('fund');
        queryClient.invalidateQueries('funds');
      },
    }
  );
};

export const deleteInvestorDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteInvestorDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteInvestorDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('investor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('investor');
        queryClient.invalidateQueries('investors');
      },
    }
  );
};

export const deleteNewsletterDELETE = async (
  Constants,
  { newsletter_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter/${encodeQueryParam(
    newsletter_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteNewsletterDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteNewsletterDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('newletters', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('newletter');
        queryClient.invalidateQueries('newletters');
      },
    }
  );
};

export const deletePEPFDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeletePEPFDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deletePEPFDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('pepf', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('pepf');
        queryClient.invalidateQueries('pepfs');
      },
    }
  );
};

export const deletePOTDDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeletePOTDDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deletePOTDDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('potd', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('potd');
        queryClient.invalidateQueries('potds');
      },
    }
  );
};

export const deletePeerGroupDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeletePeerGroupDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deletePeerGroupDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('peer_group', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('peer_group');
        queryClient.invalidateQueries('peer_groups');
      },
    }
  );
};

export const deletePropCaseDELETE = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_case/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeletePropCaseDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deletePropCaseDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('prop_case', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('prop_case');
        queryClient.invalidateQueries('prop_cases');
      },
    }
  );
};

export const deleteUsersClientsDELETE = async (
  Constants,
  { users_clients_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients/${encodeQueryParam(
    users_clients_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useDeleteUsersClientsDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUsersClientsDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('users_clients', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('users_client');
        queryClient.invalidateQueries('users_clients');
      },
    }
  );
};

export const editEventPATCH = async (
  Constants,
  {
    below_league_threshold,
    buyer,
    buyside_cf,
    buyside_legal,
    country,
    currency_ev,
    currency_financials,
    description,
    ebit_local,
    ebitda_local,
    ev,
    ev_source,
    event_type,
    fy_end,
    gics,
    gross_profit_local,
    headline,
    id,
    include_in_multiples_db,
    link_to_financials,
    note_financials,
    pefirm_behind_buyer,
    pepf,
    published,
    revenue_local,
    reviewed_by,
    seller,
    sellside_cf,
    sellside_legal,
    source,
    source_link,
    source_type,
    status,
    strat_or_invf_buyer,
    strat_or_invf_seller,
    target,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        published: published,
        headline: headline,
        description: description,
        source: source,
        source_type: source_type,
        source_link: source_link,
        country: country,
        gics: gics,
        reviewed_by: reviewed_by,
        event_type: event_type,
        target: target,
        buyer: buyer,
        strat_or_invf_buyer: strat_or_invf_buyer,
        seller: seller,
        strat_or_invf_seller: strat_or_invf_seller,
        currency_financials: currency_financials,
        revenue_local: revenue_local,
        gross_profit_local: gross_profit_local,
        ebitda_local: ebitda_local,
        ebit_local: ebit_local,
        fy_end: fy_end,
        note_financials: note_financials,
        currency_ev: currency_ev,
        ev: ev,
        ev_source: ev_source,
        sellside_cf: sellside_cf,
        sellside_legal: sellside_legal,
        buyside_cf: buyside_cf,
        buyside_legal: buyside_legal,
        pepf: pepf,
        pefirm_behind_buyer: pefirm_behind_buyer,
        include_in_multiples_db: include_in_multiples_db,
        link_to_financials: link_to_financials,
        below_league_threshold: below_league_threshold,
        status: status,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useEditEventPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => editEventPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('event', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('event');
        queryClient.invalidateQueries('events');
      },
    }
  );
};

export const getAdvisorGET = async (Constants, { id }, handlers, timeout) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetAdvisorGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['advisor', args],
    () => getAdvisorGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['advisors']),
    }
  );
};

export const FetchGetAdvisorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdvisorGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAdvisor: refetch });
};

export const getAdvisorsGET = async (
  Constants,
  { keyword, page, per_page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetAdvisorsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['advisors', args],
    () => getAdvisorsGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetAdvisorsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdvisorsGET(
    { keyword, page, per_page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAdvisors: refetch });
};

export const getCFSGET = async (
  Constants,
  {
    country,
    first_added,
    first_added_options,
    gics_sector,
    gics_sub_industry,
    keyword_search,
    last_updated,
    last_updated_options,
    page,
    region,
    stage,
    target,
  },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  if (target !== undefined) {
    paramsDict['target'] = target;
  }
  if (last_updated !== undefined) {
    paramsDict['last_updated'] = last_updated;
  }
  if (region !== undefined) {
    paramsDict['region'] = region;
  }
  if (stage !== undefined) {
    paramsDict['stage'] = stage;
  }
  if (gics_sector !== undefined) {
    paramsDict['gics_sector'] = gics_sector;
  }
  if (country !== undefined) {
    paramsDict['country'] = country;
  }
  if (gics_sub_industry !== undefined) {
    paramsDict['gics_sub_industry'] = gics_sub_industry;
  }
  if (last_updated_options !== undefined) {
    paramsDict['last_updated_options'] = last_updated_options;
  }
  if (first_added !== undefined) {
    paramsDict['first_added'] = first_added;
  }
  if (first_added_options !== undefined) {
    paramsDict['first_added_options'] = first_added_options;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCFSGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['cfs', args],
    () => getCFSGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetCFSGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  country,
  first_added,
  first_added_options,
  gics_sector,
  gics_sub_industry,
  keyword_search,
  last_updated,
  last_updated_options,
  page,
  region,
  stage,
  target,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCFSGET(
    {
      country,
      first_added,
      first_added_options,
      gics_sector,
      gics_sub_industry,
      keyword_search,
      last_updated,
      last_updated_options,
      page,
      region,
      stage,
      target,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCFS: refetch });
};

export const getCFSDetailGET = async (Constants, { id }, handlers, timeout) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCFSDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['cf', args],
    () => getCFSDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['cfs']),
    }
  );
};

export const FetchGetCFSDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCFSDetailGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCFSDetail: refetch });
};

export const getCrawlerKeywordGET = async (
  Constants,
  { keyword_search, page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_keyword${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCrawlerKeywordGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['crawler_keywords', args],
    () => getCrawlerKeywordGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetCrawlerKeywordGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerKeywordGET(
    { keyword_search, page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCrawlerKeyword: refetch });
};

export const getCrawlerResultGET = async (
  Constants,
  { keyword_search, page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_result${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCrawlerResultGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['crawler_results', args],
    () => getCrawlerResultGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetCrawlerResultGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerResultGET(
    { keyword_search, page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCrawlerResult: refetch });
};

export const getCrawlerResultDetailGET = async (
  Constants,
  { crawler_result_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_result/${encodeQueryParam(
    crawler_result_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCrawlerResultDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['crawler_result', args],
    () => getCrawlerResultDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['crawler_results']),
    }
  );
};

export const FetchGetCrawlerResultDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  crawler_result_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerResultDetailGET(
    { crawler_result_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetCrawlerResultDetail: refetch,
  });
};

export const getCrawlerSourceGET = async (
  Constants,
  { page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCrawlerSourceGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['crawler_sources', args],
    () => getCrawlerSourceGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetCrawlerSourceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerSourceGET(
    { page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCrawlerSource: refetch });
};

export const getCrawlerSourceDetailGET = async (
  Constants,
  { crawler_source_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source/${encodeQueryParam(
    crawler_source_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetCrawlerSourceDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['crawler_source', args],
    () => getCrawlerSourceDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['crawler_sources']),
    }
  );
};

export const FetchGetCrawlerSourceDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  crawler_source_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerSourceDetailGET(
    { crawler_source_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetCrawlerSourceDetail: refetch,
  });
};

export const getEventGET = async (Constants, { id }, handlers, timeout) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetEventGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['event', args],
    () => getEventGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['events']),
    }
  );
};

export const FetchGetEventGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEventGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetEvent: refetch });
};

export const getEventsGET = async (
  Constants,
  {
    country,
    date_range,
    event_type,
    gics_sub_industry,
    keyword,
    page,
    per_page,
    region,
    status,
    target,
  },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (status !== undefined) {
    paramsDict['status'] = status;
  }
  if (country !== undefined) {
    paramsDict['country'] = country;
  }
  if (region !== undefined) {
    paramsDict['region'] = region;
  }
  if (gics_sub_industry !== undefined) {
    paramsDict['gics_sub_industry'] = gics_sub_industry;
  }
  if (date_range !== undefined) {
    paramsDict['date_range'] = date_range;
  }
  if (region !== undefined) {
    paramsDict['region'] = region;
  }
  if (country !== undefined) {
    paramsDict['country'] = country;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  if (event_type !== undefined) {
    paramsDict['event_type'] = event_type;
  }
  if (target !== undefined) {
    paramsDict['target'] = target;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetEventsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['events', args],
    () => getEventsGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetEventsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  country,
  date_range,
  event_type,
  gics_sub_industry,
  keyword,
  page,
  per_page,
  region,
  status,
  target,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEventsGET(
    {
      country,
      date_range,
      event_type,
      gics_sub_industry,
      keyword,
      page,
      per_page,
      region,
      status,
      target,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetEvents: refetch });
};

export const getFundGET = async (
  Constants,
  { keyword, page, pe_firm, per_page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (pe_firm !== undefined) {
    paramsDict['pe_firm'] = pe_firm;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetFundGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['funds', args],
    () => getFundGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetFundGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
  page,
  pe_firm,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFundGET(
    { keyword, page, pe_firm, per_page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFund: refetch });
};

export const getFundDetailGET = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetFundDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['fund', args],
    () => getFundDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['funds']),
    }
  );
};

export const FetchGetFundDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFundDetailGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFundDetail: refetch });
};

export const getGicsSubIndustryGET = async (
  Constants,
  { keyword },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/gics_sub_industry${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetGicsSubIndustryGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['gics_sub_industries', args],
    () => getGicsSubIndustryGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetGicsSubIndustryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetGicsSubIndustryGET(
    { keyword },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetGicsSubIndustry: refetch });
};

export const getInvestorGET = async (
  Constants,
  { investor_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor/${encodeQueryParam(
    investor_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetInvestorGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['investor', args],
    () => getInvestorGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['investors']),
    }
  );
};

export const FetchGetInvestorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  investor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInvestorGET(
    { investor_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInvestor: refetch });
};

export const getInvestorsGET = async (
  Constants,
  { country_hq, keyword, page, per_page, type },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (type !== undefined) {
    paramsDict['type'] = type;
  }
  if (country_hq !== undefined) {
    paramsDict['country_hq'] = country_hq;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetInvestorsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['investors', args],
    () => getInvestorsGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetInvestorsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  country_hq,
  keyword,
  page,
  per_page,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInvestorsGET(
    { country_hq, keyword, page, per_page, type },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInvestors: refetch });
};

export const getMeGET = async (Constants, _args, handlers, timeout) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/auth/me`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetMeGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['me', args],
    () => getMeGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['us']),
    }
  );
};

export const FetchGetMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetMeGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetMe: refetch });
};

export const getNewsletterDetailGET = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetNewsletterDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newletter', args],
    () => getNewsletterDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['newletters']),
    }
  );
};

export const FetchGetNewsletterDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNewsletterDetailGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetNewsletterDetail: refetch,
  });
};

export const getNewslettersGET = async (
  Constants,
  { dach, keyword, newsletter, nordic, page, weekly_report },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (nordic !== undefined) {
    paramsDict['nordic'] = nordic;
  }
  if (dach !== undefined) {
    paramsDict['dach'] = dach;
  }
  if (newsletter !== undefined) {
    paramsDict['newsletter'] = newsletter;
  }
  if (weekly_report !== undefined) {
    paramsDict['weekly_report'] = weekly_report;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetNewslettersGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['newletters', args],
    () => getNewslettersGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetNewslettersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  dach,
  keyword,
  newsletter,
  nordic,
  page,
  weekly_report,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNewslettersGET(
    { dach, keyword, newsletter, nordic, page, weekly_report },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetNewsletters: refetch });
};

export const getPEPFDetailGET = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPEPFDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['pepf', args],
    () => getPEPFDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['pepfs']),
    }
  );
};

export const FetchGetPEPFDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPEPFDetailGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPEPFDetail: refetch });
};

export const getPOTDGET = async (
  Constants,
  { keyword, page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPOTDGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['potds', args],
    () => getPOTDGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetPOTDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPOTDGET(
    { keyword, page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPOTD: refetch });
};

export const getPOTDDetailGET = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPOTDDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['potd', args],
    () => getPOTDDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['potds']),
    }
  );
};

export const FetchGetPOTDDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPOTDDetailGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPOTDDetail: refetch });
};

export const getPeerGroupGET = async (
  Constants,
  { created_by, keyword, page, per_page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (created_by !== undefined) {
    paramsDict['created_by'] = created_by;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPeerGroupGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['peer_groups', args],
    () => getPeerGroupGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetPeerGroupGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  created_by,
  keyword,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPeerGroupGET(
    { created_by, keyword, page, per_page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPeerGroup: refetch });
};

export const getPeerGroupDetailGET = async (
  Constants,
  { id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPeerGroupDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['peer_group', args],
    () => getPeerGroupDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['peer_groups']),
    }
  );
};

export const FetchGetPeerGroupDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPeerGroupDetailGET(
    { id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPeerGroupDetail: refetch });
};

export const getPepfGET = async (
  Constants,
  {
    company_name,
    country,
    currentStatus,
    current_holding_years,
    gics,
    holding_options,
    keyword,
    page,
    pe_firm,
    stake_type,
  },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  if (currentStatus !== undefined) {
    paramsDict['currentStatus'] = currentStatus;
  }
  if (company_name !== undefined) {
    paramsDict['company_name'] = company_name;
  }
  if (gics !== undefined) {
    paramsDict['gics'] = gics;
  }
  if (pe_firm !== undefined) {
    paramsDict['pe_firm'] = pe_firm;
  }
  if (holding_options !== undefined) {
    paramsDict['holding_options'] = holding_options;
  }
  if (stake_type !== undefined) {
    paramsDict['stake_type'] = stake_type;
  }
  if (current_holding_years !== undefined) {
    paramsDict['current_holding_years'] = current_holding_years;
  }
  if (country !== undefined) {
    paramsDict['country'] = country;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPepfGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['pepfs', args],
    () => getPepfGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetPepfGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  company_name,
  country,
  currentStatus,
  current_holding_years,
  gics,
  holding_options,
  keyword,
  page,
  pe_firm,
  stake_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPepfGET(
    {
      company_name,
      country,
      currentStatus,
      current_holding_years,
      gics,
      holding_options,
      keyword,
      page,
      pe_firm,
      stake_type,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPepf: refetch });
};

export const getPropCaseGET = async (
  Constants,
  { keyword, page, per_page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_case${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPropCaseGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['prop_cases', args],
    () => getPropCaseGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetPropCaseGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPropCaseGET(
    { keyword, page, per_page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPropCase: refetch });
};

export const getPropScheduleGET = async (
  Constants,
  { keyword_search, page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_schedule${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetPropScheduleGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['prop_schedules', args],
    () => getPropScheduleGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetPropScheduleGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPropScheduleGET(
    { keyword_search, page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPropSchedule: refetch });
};

export const getTrendingTopicGET = async (
  Constants,
  { keyword_search, page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/trending_topic${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetTrendingTopicGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['trending_topics', args],
    () => getTrendingTopicGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetTrendingTopicGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTrendingTopicGET(
    { keyword_search, page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTrendingTopic: refetch });
};

export const getUserAdminGET = async (Constants, _args, handlers, timeout) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/user_admin`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetUserAdminGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['user_admins', args],
    () => getUserAdminGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetUserAdminGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserAdminGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserAdmin: refetch });
};

export const getUsersClientsGET = async (
  Constants,
  { keyword, only_nkp, page, per_page },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (only_nkp !== undefined) {
    paramsDict['only_nkp'] = only_nkp;
  }
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetUsersClientsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['users_clients', args],
    () => getUsersClientsGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetUsersClientsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
  only_nkp,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUsersClientsGET(
    { keyword, only_nkp, page, per_page },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUsersClients: refetch });
};

export const getUsersClientsDetailGET = async (
  Constants,
  { users_clients_id },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients/${encodeQueryParam(
    users_clients_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useGetUsersClientsDetailGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['users_client', args],
    () => getUsersClientsDetailGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['users_clients']),
    }
  );
};

export const FetchGetUsersClientsDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  users_clients_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUsersClientsDetailGET(
    { users_clients_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetUsersClientsDetail: refetch,
  });
};

export const loginAdminPOST = async (
  Constants,
  { email, password },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/auth/login`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({ email: email, password: password }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useLoginAdminPOST = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user', args],
    () => loginAdminPOST(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['users']),
    }
  );
};

export const FetchLoginAdminPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginAdminPOST(
    { email, password },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchLoginAdmin: refetch });
};

export const sendNotificationForScreenshotPOST = async (
  Constants,
  { details, email, name, ts },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/screenshot_notification`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        user_name: name,
        user_email: email,
        taken_at: ts,
        screenshot_details: details,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useSendNotificationForScreenshotPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      sendNotificationForScreenshotPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('notification', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('notification');
        queryClient.invalidateQueries('notifications');
      },
    }
  );
};

export const FetchSendNotificationForScreenshotPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  details,
  email,
  name,
  ts,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSendNotificationForScreenshotPOST(
    { details, email, name, ts },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendNotificationForScreenshot: refetch,
  });
};

export const stocksGET = async (Constants, { keyword }, handlers, timeout) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/stock${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useStocksGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['stocks', args],
    () => stocksGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchStocksGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStocksGET(
    { keyword },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchStocks: refetch });
};

export const updateAdvisorPATCH = async (
  Constants,
  { advisor_id, name, type },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor/${encodeQueryParam(
    advisor_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({ name: name, type: type }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateAdvisorPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateAdvisorPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('advisor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('advisor');
        queryClient.invalidateQueries('advisors');
      },
    }
  );
};

export const updateCFSPATCH = async (
  Constants,
  {
    advisors_cf,
    advisors_legal,
    company,
    company_description,
    country,
    currency,
    ebitda,
    event,
    first_added,
    fy_end,
    gics,
    growth,
    id,
    link_financials,
    logo,
    logo_url,
    note_financials,
    other_events,
    owners,
    peer_group,
    pepf,
    revenue,
    source_type,
    stage,
    strat_or_invf_owner,
    timeline,
    transacted,
    website,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        first_added: first_added,
        company: company,
        owners: owners,
        country: country,
        gics: gics,
        event: event,
        other_events: other_events,
        company_description: company_description,
        website: website,
        logo: logo,
        logo_url: logo_url,
        stage: stage,
        Timeline: timeline,
        pepf: pepf,
        peer_group: peer_group,
        source_type: source_type,
        revenue: revenue,
        ebitda: ebitda,
        growth: growth,
        fy_end: fy_end,
        currency: currency,
        link_financials: link_financials,
        note_financials: note_financials,
        advisors_cf: advisors_cf,
        advisors_legal: advisors_legal,
        transacted: transacted,
        strat_or_invf_owner: strat_or_invf_owner,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateCFSPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateCFSPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('cfs', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('cf');
        queryClient.invalidateQueries('cfs');
      },
    }
  );
};

export const updateCrawlerKeywordPATCH = async (
  Constants,
  { crawler_keyword_id, language, search_pdfs, string_ },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_keyword/${encodeQueryParam(
    crawler_keyword_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        string: string_,
        language: language,
        search_pdfs: search_pdfs,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateCrawlerKeywordPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateCrawlerKeywordPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('crawler_keyword', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('crawler_keyword');
        queryClient.invalidateQueries('crawler_keywords');
      },
    }
  );
};

export const updateCrawlerSourcePATCH = async (
  Constants,
  {
    base_url,
    crawl_attachments,
    crawler_source_id,
    in_production,
    language,
    source_name,
    user_email,
    user_password,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source/${encodeQueryParam(
    crawler_source_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        in_production: in_production,
        source_name: source_name,
        base_url: base_url,
        user_email: user_email,
        user_password: user_password,
        language: language,
        crawl_attachments: crawl_attachments,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateCrawlerSourcePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateCrawlerSourcePATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('crawler_source', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('crawler_source');
        queryClient.invalidateQueries('crawler_sources');
      },
    }
  );
};

export const updateFundPATCH = async (
  Constants,
  {
    country_hq,
    description,
    fund_id,
    fund_size_currency,
    fund_size_local,
    horizon,
    name,
    pe_firm,
    type,
    vintage_date,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund/${encodeQueryParam(
    fund_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        name: name,
        description: description,
        pe_firm: pe_firm,
        fund_size_local: fund_size_local,
        fund_size_currency: fund_size_currency,
        country_hq: country_hq,
        type: type,
        horizon: horizon,
        vintage_date: vintage_date,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateFundPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateFundPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('fund', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('fund');
        queryClient.invalidateQueries('funds');
      },
    }
  );
};

export const updateInvestorPATCH = async (
  Constants,
  { country_hq, investor_id, name, type },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor/${encodeQueryParam(
    investor_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({ name: name, type: type, country_hq: country_hq }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateInvestorPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateInvestorPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('investor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('investor');
        queryClient.invalidateQueries('investors');
      },
    }
  );
};

export const updatePEPFPATCH = async (
  Constants,
  {
    Company,
    acquired_date,
    buyside_cf_entry,
    buyside_legal_entry,
    company_description,
    company_id,
    country,
    currency_financials,
    ebit_local,
    ebitda_local,
    exit_date,
    financial_year,
    financials_comment,
    fund_entity,
    gics,
    gross_profit_local,
    growth,
    id,
    investment_responsible,
    pe_firm,
    revenue_local,
    stake_type,
    website,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        acquired_date: acquired_date,
        Company: Company,
        company_id: company_id,
        country: country,
        gics: gics,
        pe_firm: pe_firm,
        fund_entity: fund_entity,
        investment_responsible: investment_responsible,
        company_description: company_description,
        website: website,
        buyside_cf_entry: buyside_cf_entry,
        buyside_legal_entry: buyside_legal_entry,
        exit_date: exit_date,
        revenue_local: revenue_local,
        gross_profit_local: gross_profit_local,
        ebitda_local: ebitda_local,
        ebit_local: ebit_local,
        growth: growth,
        currency_financials: currency_financials,
        financial_year: financial_year,
        financials_comment: financials_comment,
        stake_type: stake_type,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdatePEPFPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updatePEPFPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('pepf', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('pepf');
        queryClient.invalidateQueries('pepfs');
      },
    }
  );
};

export const updatePOTDPATCH = async (
  Constants,
  {
    advisor,
    created_at,
    financials,
    gics,
    headline,
    hq,
    id,
    peer_group,
    stage,
    story_company_profile,
    story_comps,
    story_opportunity,
    target,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        created_at: created_at,
        headline: headline,
        story_company_profile: story_company_profile,
        story_opportunity: story_opportunity,
        story_comps: story_comps,
        target: target,
        financials: financials,
        advisor: advisor,
        stage: stage,
        hq: hq,
        peer_group: peer_group,
        gics: gics,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdatePOTDPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updatePOTDPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('potd', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('potd');
        queryClient.invalidateQueries('potds');
      },
    }
  );
};

export const updatePeerGroupPATCH = async (
  Constants,
  { access_type, created_by, description, peer_group_id, title },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group/${encodeQueryParam(
    peer_group_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        title: title,
        description: description,
        created_by: created_by,
        access_type: access_type,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdatePeerGroupPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePeerGroupPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('peer_group', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('peer_group');
        queryClient.invalidateQueries('peer_groups');
      },
    }
  );
};

export const updatePropSchedulePATCH = async (
  Constants,
  { note, potd_dach, potd_nordic, prop_schedule_id, reg_dach, reg_nordic },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_schedule/${encodeQueryParam(
    prop_schedule_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        note: note,
        potd_nordic: potd_nordic,
        reg_nordic: reg_nordic,
        potd_dach: potd_dach,
        reg_dach: reg_dach,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdatePropSchedulePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePropSchedulePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('prop_schedule', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('prop_schedule');
        queryClient.invalidateQueries('prop_schedules');
      },
    }
  );
};

export const updateUsersClientsPATCH = async (
  Constants,
  {
    Initial_Login_Complete,
    accessible_regions,
    account_activated_on,
    company,
    company_type,
    country,
    email,
    email_notification_dach,
    email_notification_nordic,
    expo_token,
    google_oauth,
    job_title,
    knack_id,
    last_login_android_app,
    last_login_ios_app,
    last_login_web_app,
    name,
    nordic_nl_section_1,
    nordic_nl_section_2,
    nordic_nl_section_3,
    nordic_nl_section_4,
    paid_dach,
    paid_nordic,
    password,
    push_notification_nl_dach,
    push_notification_nl_nordic,
    region,
    screenshot_notification_shown,
    temp_login,
    trial_end,
    trial_start,
    user_status,
    users_clients_id,
    welcome_reminder_sent_on,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients/${encodeQueryParam(
    users_clients_id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        country: country,
        accessible_regions: accessible_regions,
        job_title: job_title,
        company: company,
        company_type: company_type,
        trial_start: trial_start,
        trial_end: trial_end,
        paid_dach: paid_dach,
        paid_nordic: paid_nordic,
        user_status: user_status,
        Initial_Login_Complete: Initial_Login_Complete,
        push_notification_nl_nordic: push_notification_nl_nordic,
        push_notification_nl_dach: push_notification_nl_dach,
        welcome_reminder_sent_on: welcome_reminder_sent_on,
        nordic_nl_section_1: nordic_nl_section_1,
        nordic_nl_section_2: nordic_nl_section_2,
        nordic_nl_section_3: nordic_nl_section_3,
        nordic_nl_section_4: nordic_nl_section_4,
        email_notification_nordic: email_notification_nordic,
        email_notification_dach: email_notification_dach,
        region: region,
        google_oauth: google_oauth,
        last_login_web_app: last_login_web_app,
        last_login_android_app: last_login_android_app,
        last_login_ios_app: last_login_ios_app,
        temp_login: temp_login,
        knack_id: knack_id,
        account_activated_on: account_activated_on,
        expo_token: expo_token,
        screenshot_notification_shown: screenshot_notification_shown,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateUsersClientsPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateUsersClientsPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('users_clients', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('users_client');
        queryClient.invalidateQueries('users_clients');
      },
    }
  );
};

export const updateNewsletterPATCH = async (
  Constants,
  {
    date,
    events_dach_large_cap_ids,
    events_dach_mid_ids,
    events_dach_small_ids,
    events_dk_ids,
    events_fi_ids,
    events_no_ids,
    events_se_ids,
    id,
    potd,
    status,
    teaser,
    title,
    version,
  },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter/${encodeQueryParam(
    id
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({
        version: version,
        title: title,
        date: date,
        teaser: teaser,
        status: status,
        potd: potd,
        events_dk: events_dk_ids,
        events_se: events_se_ids,
        events_no: events_no_ids,
        events_fi: events_fi_ids,
        events_dach_large_cap: events_dach_large_cap_ids,
        events_dach_mid: events_dach_mid_ids,
        events_dach_small: events_dach_small_ids,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useUpdateNewsletterPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateNewsletterPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('newletters', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('newletter');
        queryClient.invalidateQueries('newletters');
      },
    }
  );
};

export const eventTargetGET = async (
  Constants,
  { keyword },
  handlers,
  timeout
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event_target${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useEventTargetGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    timeout,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['events', args],
    () => eventTargetGET(Constants, args, handlers, timeout),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchEventTargetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  timeout,
  keyword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEventTargetGET(
    { keyword },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      timeout,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused && refetchOnWindowFocus !== false) {
      refetch();
    }
  }, [isFocused, prevIsFocused, refetchOnWindowFocus]);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      if (error.status) {
        console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      }
    }
  }, [error]);
  return children({ loading, data, error, refetchEventTarget: refetch });
};

export const inlineEditPATCH = async (
  Constants,
  { value },
  handlers,
  timeout
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/inline_edit`;
  const controller = new AbortController();
  let timeoutObj;
  if (timeout) {
    timeoutObj = setTimeout(() => {
      const err = new Error(`Timeout after ${timeout}ms`);
      err.__type = 'TIMEOUT';
      controller.abort(err);
    }, timeout);
  }
  try {
    const res = await fetch(url, {
      body: JSON.stringify({ data: value }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER'],
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      signal: controller.signal,
    });
    timeoutObj && clearTimeout(timeoutObj);
    return handleResponse(res, handlers);
  } catch (e) {
    if (e.__type === 'TIMEOUT') {
      handlers.onTimeout?.();
    } else if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    throw e;
  }
};

export const useInlineEditPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => inlineEditPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('inline_edit', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('inline_edit');
        queryClient.invalidateQueries('inline_edits');
      },
    }
  );
};
