import React from 'react';
import { Button, withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  isSubmitting: null,
  onCancel: () => {},
  onSubmit: () => {},
};

const FormActionsBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 10,
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 10,
        },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            gap: 15,
            maxWidth: 1200,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Cancel */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            try {
              props.onCancel?.();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
          disabledOpacity={0.5}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
              { height: 40, maxHeight: 40, minHeight: 40 }
            ),
            dimensions.width
          )}
          title={'CANCEL'}
        />
        {/* Submit */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            try {
              props.onSubmit?.();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
          disabled={Boolean(props.isSubmitting ?? defaultProps.isSubmitting)}
          loading={Boolean(props.isSubmitting ?? defaultProps.isSubmitting)}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
              {
                backgroundColor: palettes.App.Orange,
                height: 40,
                maxHeight: 40,
                minHeight: 40,
              }
            ),
            dimensions.width
          )}
          title={'SUBMIT'}
        />
      </View>
    </View>
  );
};

export default withTheme(FormActionsBlock);
