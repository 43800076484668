import React from 'react';
import {
  Button,
  KeyboardAvoidingView,
  Link,
  LoadingIndicator,
  ScreenContainer,
  Surface,
  TextInput,
  VStack,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Keyboard, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PasswordResetProcessApi from '../apis/PasswordResetProcessApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const defaultProps = {
  token:
    'eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.QylzeuB7Mdf4A0FPIvKCSUeBdnd725WG51ax0TEKBG1_6Jmuh6eW-U5PSIo6Dmxa3oIm_OoyTISN3ESwa-NiBAFWw3Ahjpql.gFmA7YiRNp7srD1M0cpeoQ.iFXQ1GC4n1OLNJg1mUeJBCfvVOLSszQSvdmkxOC5HaZZn4LNwSR3Lq95dAVrnjlFyWppeZxb2DtMV_VycWOrY4twha1UA9d5oxALAw5Xx327iH5TpQukjeSLShUUL2Je.Myh4wGa1nEkkLgG43PmwqGt95hcrynfJzupmPAhRBAY',
};

const ResetPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [authenticated, setAuthenticated] = React.useState(false);
  const [confirm_password, setConfirm_password] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [showForgot, setShowForgot] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const magic_auth = (
          await PasswordResetProcessApi.exchangesMagicTokenForAuthToken$LogsInUserWithTheLink$POST(
            Constants,
            { magic_token: props.route?.params?.token ?? defaultProps.token }
          )
        )?.json;
        if (magic_auth?.authToken) {
          setAuthenticated(true);

          const reset_test = magic_auth?.authToken;
          setGlobalVariableValue({
            key: 'RESET_AUTH_HEADER',
            value: reset_test,
          });
          console.log(reset_test);
        } else {
          toastMessage(magic_auth?.message, 'error');
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const keyboardAvoidingViewLoginWindowSurfaceViewConfirmPasswordRef =
    React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App['Custom Color'],
          },
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            maxWidth: { minWidth: Breakpoints.Tablet, value: 380 },
            width: [
              { minWidth: Breakpoints.Tablet, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: '50%' },
            ],
          },
          dimensions.width
        )}
      >
        <VStack
          {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.VStackStyles(theme)['V Stack'].style,
              { gap: 5 }
            ),
            dimensions.width
          )}
        >
          {/* NKP Logo */}
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['mainsightslogonew'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { width: 300 }
              ),
              dimensions.width
            )}
          />
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['screen_title'].props}
            disabled={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['screen_title'].style,
                {
                  color: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Orange,
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: palettes.App.Orange,
                    },
                  ],
                  fontFamily: [
                    { minWidth: Breakpoints.Mobile, value: 'Poppins_900Black' },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Poppins_900Black',
                    },
                  ],
                  fontSize: 30,
                  lineHeight: 30,
                  paddingBottom: { minWidth: Breakpoints.Desktop, value: 0 },
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
            suppressHighlighting={true}
          >
            {dimensions.width >= Breakpoints.Laptop
              ? 'M&A INSIGHTS'
              : 'M&A\nINSIGHTS'}
          </Text>
        </VStack>
      </View>

      <KeyboardAvoidingView
        keyboardVerticalOffset={0}
        behavior={'position'}
        enabled={true}
      >
        {/* Login Window */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: [
                { minWidth: Breakpoints.Tablet, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              width: [
                { minWidth: Breakpoints.Tablet, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={2}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                {
                  margin: { minWidth: Breakpoints.Laptop, value: null },
                  maxWidth: { minWidth: Breakpoints.Tablet, value: 380 },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }
              ),
              dimensions.width
            )}
          >
            <>
              {!authenticated ? null : (
                <View>
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['screen_title'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['screen_title'].style,
                        {
                          alignSelf: 'center',
                          fontFamily: 'Quicksand_700Bold',
                          fontSize: 25,
                          margin: 10,
                          padding: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Reset Password'}
                  </Text>
                  {/* Password */}
                  <TextInput
                    autoCapitalize={'none'}
                    changeTextDelay={500}
                    onBlur={() => {
                      try {
                        /* hidden 'Dismiss Keyboard' action */
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onChangeText={newPasswordValue => {
                      try {
                        setPassword(newPasswordValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        keyboardAvoidingViewLoginWindowSurfaceViewConfirmPasswordRef.current.focus();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    autoComplete={'current-password'}
                    autoCorrect={false}
                    enablesReturnKeyAutomatically={true}
                    placeholder={'Enter password...'}
                    placeholderTextColor={theme.colors.text.medium}
                    returnKeyLabel={'Login'}
                    returnKeyType={'go'}
                    secureTextEntry={true}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors.text.strong,
                        fontFamily: 'Quicksand_400Regular',
                        margin: 10,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                    value={password}
                  />
                  {/* Confirm Password */}
                  <TextInput
                    autoCapitalize={'none'}
                    changeTextDelay={500}
                    onChangeText={newConfirmPasswordValue => {
                      try {
                        setConfirm_password(newConfirmPasswordValue);
                        setErrorMessage('');
                        if (password === newConfirmPasswordValue) {
                          return;
                        }
                        setErrorMessage("Passwords don't match");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    autoComplete={'email'}
                    autoCorrect={false}
                    autoFocus={false}
                    clearButtonMode={'while-editing'}
                    keyboardType={'email-address'}
                    numberOfLines={1}
                    placeholder={'Confirm password...'}
                    placeholderTextColor={theme.colors.text.medium}
                    ref={
                      keyboardAvoidingViewLoginWindowSurfaceViewConfirmPasswordRef
                    }
                    returnKeyType={'next'}
                    secureTextEntry={true}
                    selectionColor={theme.colors.text.strong}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors.text.strong,
                        borderStyle: 'solid',
                        fontFamily: 'Quicksand_400Regular',
                        margin: 10,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                    value={confirm_password}
                  />
                  {/* err message */}
                  <>
                    {!errorMessage ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['screen_title']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['screen_title']
                              .style,
                            {
                              color: theme.colors.background.danger,
                              fontFamily: 'Quicksand_700Bold',
                              marginLeft: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </>
                  {/* Reset */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setIsLoading(true);
                          setAuthenticated(false);
                          const reset_password = (
                            await PasswordResetProcessApi.pOST$updatePasswordPOST(
                              Constants,
                              {
                                confirm_password: confirm_password,
                                password: password,
                              }
                            )
                          )?.json;
                          if (
                            reset_password?.message?.message ===
                            'Password updated'
                          ) {
                            toastMessage(
                              'Your password has been sucessfully reset!',
                              'success'
                            );
                            await waitUtil({ milliseconds: 1000 });
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('LogInScreen');
                          } else {
                            toastMessage(
                              'Something went wrong. Please contact support!',
                              'error'
                            );
                            setErrorMessage(
                              'Something went wrong. Please contact support'
                            );
                          }

                          setIsLoading(false);
                          setAuthenticated(true);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    disabled={Boolean(
                      password !== confirm_password || password === ''
                    )}
                    disabledOpacity={0.5}
                    loading={Boolean(isLoading)}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          fontFamily: 'Quicksand_600SemiBold',
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Submit'}
                  />
                </View>
              )}
            </>
            {/* View 2 */}
            <>
              {authenticated ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      padding: { minWidth: Breakpoints.Laptop, value: 10 },
                    },
                    dimensions.width
                  )}
                >
                  <LoadingIndicator
                    color={theme.colors.branding.primary}
                    size={30}
                    type={'circleFade'}
                    type={StyleSheet.getWidthValue(
                      [{ minWidth: Breakpoints.Laptop, value: 'wave' }],
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        theme.typography.body1,
                        {}
                      ),
                      dimensions.width
                    )}
                  >
                    {'Please wait...'}
                  </Text>
                </View>
              )}
            </>
          </Surface>
        </View>
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

export default withTheme(ResetPasswordScreen);
