import * as React from 'react';
import { List, arrayMove } from 'react-movable';

const SuperSimple = () => {
  const [items, setItems] = React.useState(['Item 1', 'Item 2', 'Item 3']);
  const handleChange =
    ((oldIndex, newIndex) => {
      console.log(oldIndex, newIndex);
      // ({ oldIndex, newIndex }) =>
      // setItems(arrayMove(items, oldIndex, newIndex));
      // console.log(items);
    },
    [setItems]);
  return (
    <List
      values={items}
      onChange={
        ({ oldIndex, newIndex }) =>
          setItems(arrayMove(items, oldIndex, newIndex))
        // handleChange(oldIndex, newIndex)
      }
      renderList={({ children, props }) => <ul {...props}>{children}</ul>}
      renderItem={({ value, props }) => <li {...props}>{value}</li>}
    />
  );
};

export { SuperSimple };
