import * as CommonPackages from '../custom-files/CommonPackages';

const formatTimestampToCopenhagen = (timestamp, type) => {
  // console.log(timestamp);
  const moment = CommonPackages?.moment;

  if (type === 'start') {
    return moment(timestamp).tz('Europe/Copenhagen').startOf('day').unix();
  } else if (type === 'end') {
    return moment(timestamp).tz('Europe/Copenhagen').endOf('day').unix();
  } else if (type === 'onlyDate') {
    return moment(timestamp).format('DD/MM/YYYY');
  } else {
    return moment(timestamp).tz('Europe/Copenhagen').format('DD/MM/YY HH:mm');
  }
};

export default formatTimestampToCopenhagen;
