import React from 'react';
import {
  Checkbox,
  DatePicker,
  Picker,
  Pressable,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import AutoSizeInputBlock from '../components/AutoSizeInputBlock';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import MonthYearPickerBlock from '../components/MonthYearPickerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: {},
  isSubmitting: null,
  onSubmit: () => {},
};

const EventFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [below_league_threshold, setBelow_league_threshold] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.below_league_threshold
  );
  const [buyer, setBuyer] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.buyer,
      'name',
      'id',
      undefined
    )
  );
  const [buyside_cf, setBuyside_cf] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.buyside_cf,
      'name',
      'id',
      undefined
    )
  );
  const [buyside_legal, setBuyside_legal] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.buyside_legal,
      'name',
      'id',
      undefined
    )
  );
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState(false);
  const [checkboxValue3, setCheckboxValue3] = React.useState(false);
  const [country, setCountry] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.country
  );
  const [created_at, setCreated_at] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.created_at
  );
  const [currency_ev, setCurrency_ev] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.currency_ev
  );
  const [currency_financials, setCurrency_financials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.currency_financials
  );
  const [current_time, setCurrent_time] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.reviewed_timestamp
  );
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [datePickerValue3, setDatePickerValue3] = React.useState(new Date());
  const [description, setDescription] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.description
  );
  const [ebit_local, setEbit_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ebit_local
  );
  const [ebitda_local, setEbitda_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ebitda_local
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [ev, setEv] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ev
  );
  const [ev_source, setEv_source] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ev_source
  );
  const [event_type, setEvent_type] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.event_type
  );
  const [fy_end, setFy_end] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.fy_end
  );
  const [gics, setGics] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)
        ?._gics_sub_industry_single,
      'GICS_Sector',
      'id',
      'GICS_Sub_Industry'
    )
  );
  const [gross_profit_local, setGross_profit_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.gross_profit_local
  );
  const [headline, setHeadline] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.headline
  );
  const [include_in_multiples_db, setInclude_in_multiples_db] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.include_in_multiples_db
  );
  const [link_to_financials, setLink_to_financials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.link_to_financials
  );
  const [message_to_admin, setMessage_to_admin] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.message_to_admin
  );
  const [note_financials, setNote_financials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.note_financials
  );
  const [pefirm_behind_buyer, setPefirm_behind_buyer] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._pefirm_behind_buyer,
      'name',
      'id',
      undefined
    )
  );
  const [pepf, setPepf] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._pepf,
      'Company',
      'id',
      undefined
    )
  );
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [pickerValue3, setPickerValue3] = React.useState('');
  const [pickerValue4, setPickerValue4] = React.useState('');
  const [published, setPublished] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.published
  );
  const [related_previous_coverage, setRelated_previous_coverage] =
    React.useState(
      (props.initialData ?? defaultProps.initialData)?.related_previous_coverage
    );
  const [revenue_local, setRevenue_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.revenue_local
  );
  const [reviewed_by, setReviewed_by] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.reviewed_by
  );
  const [seller, setSeller] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.seller,
      'name',
      'id',
      undefined
    )
  );
  const [sellside_cf, setSellside_cf] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.sellside_cf,
      'name',
      'id',
      undefined
    )
  );
  const [sellside_legal, setSellside_legal] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.sellside_legal,
      'name',
      'id',
      undefined
    )
  );
  const [source, setSource] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.source
  );
  const [source_link, setSource_link] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.source_link
  );
  const [source_type, setSource_type] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.source_type
  );
  const [status, setStatus] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.status
  );
  const [strat_or_invf_buyer, setStrat_or_invf_buyer] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.strat_or_invf_buyer
  );
  const [strat_or_invf_seller, setStrat_or_invf_seller] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.strat_or_invf_seller
  );
  const [suggestionBuySideCF, setSuggestionBuySideCF] = React.useState([]);
  const [suggestionBuySideLegal, setSuggestionBuySideLegal] = React.useState(
    []
  );
  const [suggestionBuyer, setSuggestionBuyer] = React.useState([]);
  const [suggestionGics, setSuggestionGics] = React.useState([]);
  const [suggestionPEPF, setSuggestionPEPF] = React.useState([]);
  const [suggestionPEfirmBehindBuyer, setSuggestionPEfirmBehindBuyer] =
    React.useState([]);
  const [suggestionSellSideCF, setSuggestionSellSideCF] = React.useState([]);
  const [suggestionSellSideLegal, setSuggestionSellSideLegal] = React.useState(
    []
  );
  const [suggestionSeller, setSuggestionSeller] = React.useState([]);
  const [target, setTarget] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.target
  );
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const addYearOptions = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    let startDate = new Date().getFullYear() - 5,
      endDate = new Date().getFullYear() + 1,
      dateArr = [];

    for (let i = startDate; i <= endDate; i++) {
      dateArr.push({ label: 'Dec 31, ' + i, value: i + '-12-31' });
    }

    return dateArr;
  };

  const validateForm = () => {
    const requiredFields = {
      headline: 'Headline',
      description: 'Summary',
      reviewed_by: 'Reviewed By',
      published: 'Published',
      status: 'Status',
      source: 'Source',
      source_type: 'Source Type',
      gics: 'GICS Sub Industry',
      target: 'Target',
      event_type: 'Event Type',
      country: 'Country',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => {
        const value = eval(key);
        return Array.isArray(value) ? value.length === 0 : !value;
      })
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) {
      setErrorMessage('');
      return true;
    }
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: palettes.App.Outline,
                  borderWidth: 1,
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.7, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        {/* Surface 6duplicated */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              {
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
              }
            ),
            dimensions.width
          )}
        >
          {/* Column */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              },
              dimensions.width
            )}
          >
            {/* Created at */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Created at'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <>
                  {!created_at ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {formatTimestampToCopenhagen(created_at, undefined)}
                    </Text>
                  )}
                </>
              </View>
            </View>
            {/* Reviewed by* */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Reviewed by *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setReviewed_by(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={reviewed_by}
                />
              </View>
            </View>
            {/* Reviewed Time */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Reviewed Time'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <>
                  {!current_time ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {formatTimestampToCopenhagen(current_time, undefined)}
                    </Text>
                  )}
                </>
              </View>
            </View>
          </View>
          {/* Column 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Laptop, value: 2 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
              },
              dimensions.width
            )}
          >
            {/* Headline */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: [
                    { minWidth: Breakpoints.Mobile, value: 1 },
                    { minWidth: Breakpoints.Laptop, value: 0.5 },
                  ],
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Headline *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <AutoSizeInputBlock
                  onChange={result => {
                    try {
                      setHeadline(result);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Headline...'}
                  value={headline}
                />
              </View>
            </View>
            {/* Summary */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: [
                    { minWidth: Breakpoints.Mobile, value: 1 },
                    { minWidth: Breakpoints.Laptop, value: 1 },
                  ],
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Summary *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <AutoSizeInputBlock
                  onChange={result => {
                    try {
                      setDescription(result);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  maxRows={13}
                  placeholder={'Summary...'}
                  value={description}
                />
              </View>
            </View>
          </View>
          {/* Column 3 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' } },
              dimensions.width
            )}
          >
            {/* Related previous Coverage */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Related previous Coverage'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    overflow: { minWidth: Breakpoints.Laptop, value: 'hidden' },
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {related_previous_coverage}
                </Text>
              </View>
            </View>
            {/* Message to admin */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Message to admin'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {message_to_admin}
                </Text>
              </View>
            </View>
            {/* Published */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Published *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  inline={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    try {
                      setPublished(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  {...GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                    .props}
                  date={customFormatDate(published)}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                      .style,
                    dimensions.width
                  )}
                />
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 2 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Status */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Status *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        setStatus(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    options={Constants['EVENT_STATUS_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={status}
                  />
                </View>
              </View>
              {/* Target */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Target *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setTarget(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={target}
                  />
                </View>
              </View>
            </View>
            {/* Column 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Source */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 1 },
                    ],
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Source *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setSource(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={source}
                  />
                </View>
              </View>
              {/* Event Type */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Event type *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        setEvent_type(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    options={Constants['EVENT_TYPE_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={event_type}
                  />
                </View>
              </View>
            </View>
            {/* Column 3 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Source Type */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 1 },
                    ],
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Source type *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        setSource_type(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    options={Constants['SOURCE_TYPE_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={source_type}
                  />
                </View>
              </View>
              {/* Country */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 1 },
                    ],
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Country *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        setCountry(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    options={Constants['COUNTRY_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={country}
                  />
                </View>
              </View>
            </View>
            {/* Column 4 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Source Link */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 1 },
                    ],
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Source link'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setSource_link(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={source_link}
                  />
                </View>
              </View>
            </View>
          </View>
          {/* Row 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* GICs */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: [
                    { minWidth: Breakpoints.Mobile, value: 1 },
                    { minWidth: Breakpoints.Laptop, value: 0.75 },
                  ],
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'GICS Sub Industry *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        /* hidden 'Log to Console' action */
                        const resultGics = (
                          await AdminGroupApi.getGicsSubIndustryGET(Constants, {
                            keyword: searchKeyword,
                          })
                        )?.json;
                        const suggestion_gics = transformToSearchInputFormat(
                          resultGics,
                          'GICS_Sector',
                          'id',
                          'GICS_Sub_Industry'
                        );
                        setSuggestionGics(suggestion_gics);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onSelect={selectedResult => {
                    try {
                      setGics(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  defaultValue={gics}
                  multiSelect={false}
                  selectedItem={gics}
                  suggestionsList={suggestionGics}
                />
              </View>
            </View>
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 0.25 } },
                dimensions.width
              )}
            />
          </View>
        </Surface>
        {/* Surface 3 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Buyer */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Buyer'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultInvestors = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              country_hq: [],
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              type: [],
                            })
                          )?.json;
                          const new_suggestions_buyer =
                            transformToSearchInputFormat(
                              resultInvestors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionBuyer(new_suggestions_buyer);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setBuyer(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={buyer}
                    suggestionsList={suggestionBuyer}
                  />
                </View>
              </View>
              {/* Seller */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Seller'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultInvestors = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              country_hq: [],
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              type: [],
                            })
                          )?.json;
                          const new_suggestion_investors =
                            transformToSearchInputFormat(
                              resultInvestors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionSeller(new_suggestion_investors);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setSeller(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={seller}
                    suggestionsList={suggestionSeller}
                  />
                </View>
              </View>
              {/* PE Portfolio firm */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'PE Portfolio firm'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultPEPF = (
                            await AdminGroupApi.getPepfGET(Constants, {
                              company_name: '',
                              country: [],
                              current_holding_years: null,
                              gics: [],
                              holding_options: '=',
                              keyword: searchKeyword,
                              page: 1,
                              pe_firm: [],
                              stake_type: [],
                            })
                          )?.json;
                          const new_suggestion_pepf =
                            transformToSearchInputFormat(
                              resultPEPF?.items,
                              'Company',
                              'id',
                              undefined
                            );
                          setSuggestionPEPF(new_suggestion_pepf);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setPepf(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={pepf}
                    multiSelect={false}
                    selectedItem={pepf}
                    suggestionsList={suggestionPEPF}
                  />
                </View>
              </View>
            </View>
            {/* Column 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 2 } },
                dimensions.width
              )}
            >
              {/* strat_or_invf_buyer */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: { minWidth: Breakpoints.Laptop, value: 10 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        {
                          fontSize: { minWidth: Breakpoints.Laptop, value: 12 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Name of strategic/other Investment firm buyer (only used if Buyer is not PE/private individual)'
                    }
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: { minWidth: Breakpoints.Laptop, value: 10 },
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setStrat_or_invf_buyer(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={strat_or_invf_buyer}
                  />
                </View>
              </View>
              {/* strat_or_invf_seller */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        {
                          fontSize: { minWidth: Breakpoints.Laptop, value: 12 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Name of Strategic/other Investment firm seller (only used if Seller is not PE/private individual)'
                    }
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setStrat_or_invf_seller(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={strat_or_invf_seller}
                  />
                </View>
              </View>
              {/* PE firm behind the buyer */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'PE firm behind the buyer'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultInvestors = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              country_hq: [],
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              type: [],
                            })
                          )?.json;
                          setSuggestionPEfirmBehindBuyer(
                            transformToSearchInputFormat(
                              resultInvestors?.items,
                              'name',
                              'id',
                              undefined
                            )
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setPefirm_behind_buyer(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={pefirm_behind_buyer}
                    multiSelect={false}
                    selectedItem={pefirm_behind_buyer}
                    suggestionsList={suggestionPEfirmBehindBuyer}
                  />
                </View>
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 4 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 4 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Row */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: {
                    minWidth: Breakpoints.Desktop,
                    value: 'row',
                  },
                },
                dimensions.width
              )}
            >
              {/* Column */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                {/* Revenue */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Revenue (m)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setRevenue_local(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={revenue_local}
                    />
                  </View>
                </View>
                {/* Currency financials */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Currency financials'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.base}
                      dropDownBorderColor={theme.colors.border.base}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setCurrency_financials(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                      options={Constants['CURRENCY_OPTIONS']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['app picker'].style,
                        dimensions.width
                      )}
                      value={currency_financials}
                    />
                  </View>
                </View>
                {/* Enterprise Value */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Enterprise Value'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setEv(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={ev}
                    />
                  </View>
                </View>
              </View>
              {/* Column 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                {/* Gross Profit */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 1 },
                        { minWidth: Breakpoints.Laptop, value: 1 },
                      ],
                      flexDirection: rowOnlySmallDevice(dimensions),
                    },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Gross Profit (m)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setGross_profit_local(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={gross_profit_local}
                    />
                  </View>
                </View>
                {/* Fiscal year */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Fiscal year'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <MonthYearPickerBlock
                      onChange={newMonthYearValue => {
                        try {
                          setFy_end(newMonthYearValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    />
                  </View>
                </View>
                {/* Currency  EV */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 1 },
                        { minWidth: Breakpoints.Laptop, value: 1 },
                      ],
                      flexDirection: rowOnlySmallDevice(dimensions),
                    },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Currency EV\n'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.base}
                      dropDownBorderColor={theme.colors.border.base}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setCurrency_ev(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                      options={Constants['CURRENCY_OPTIONS']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['app picker'].style,
                        dimensions.width
                      )}
                      value={currency_ev}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* Column 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Desktop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Column */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                    dimensions.width
                  )}
                >
                  {/* EBITDA */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        flexDirection: rowOnlySmallDevice(dimensions),
                      },
                      dimensions.width
                    )}
                  >
                    {/* Field */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderLeftWidth: 1,
                          borderRightWidth: 1,
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.3 },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: flexOnlySmallDevice(dimensions, 0.3),
                            },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'EBITDA (m)'}
                      </Text>
                    </View>
                    {/* Value */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderRightWidth: 1,
                          flex: 0.7,
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newTextInputValue => {
                          try {
                            setEbitda_local(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)[
                          'Common TextInput'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Common TextInput'
                          ].style,
                          dimensions.width
                        )}
                        value={ebitda_local}
                      />
                    </View>
                  </View>
                </View>
                {/* Column 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                    dimensions.width
                  )}
                >
                  {/* EBIT */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        flexDirection: rowOnlySmallDevice(dimensions),
                      },
                      dimensions.width
                    )}
                  >
                    {/* Field */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderLeftWidth: 1,
                          borderRightWidth: 1,
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.3 },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: flexOnlySmallDevice(dimensions, 0.3),
                            },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'EBIT (m)'}
                      </Text>
                    </View>
                    {/* Value */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderRightWidth: 1,
                          flex: 0.7,
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newTextInputValue => {
                          try {
                            setEbit_local(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)[
                          'Common TextInput'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Common TextInput'
                          ].style,
                          dimensions.width
                        )}
                        value={ebit_local}
                      />
                    </View>
                  </View>
                </View>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Desktop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* link_to_financials */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Link To Financials'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setLink_to_financials(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={link_to_financials}
                    />
                  </View>
                </View>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Desktop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Column */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                    dimensions.width
                  )}
                >
                  {/* EV Source */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        flexDirection: rowOnlySmallDevice(dimensions),
                      },
                      dimensions.width
                    )}
                  >
                    {/* Field */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderLeftWidth: 1,
                          borderRightWidth: 1,
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.3 },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: flexOnlySmallDevice(dimensions, 0.3),
                            },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'EV Source'}
                      </Text>
                    </View>
                    {/* Value */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderRightWidth: 1,
                          flex: 0.7,
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Picker
                        autoDismissKeyboard={true}
                        dropDownBackgroundColor={theme.colors.background.base}
                        dropDownBorderColor={theme.colors.border.base}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        dropDownTextColor={theme.colors.text.strong}
                        iconSize={24}
                        leftIconMode={'inset'}
                        mode={'native'}
                        onValueChange={newPickerValue => {
                          try {
                            setEv_source(newPickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Select an option'}
                        selectedIconColor={theme.colors.text.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        {...GlobalStyles.PickerStyles(theme)['app picker']
                          .props}
                        options={Constants['EV_SOURCE_OPTIONS']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.PickerStyles(theme)['app picker'].style,
                          dimensions.width
                        )}
                        value={ev_source}
                      />
                    </View>
                  </View>
                </View>
                {/* Column 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                    dimensions.width
                  )}
                >
                  {/* Include in multiples */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        flexDirection: rowOnlySmallDevice(dimensions),
                      },
                      dimensions.width
                    )}
                  >
                    {/* Field */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderLeftWidth: 1,
                          borderRightWidth: 1,
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.3 },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: flexOnlySmallDevice(dimensions, 0.3),
                            },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Include in multiples'}
                      </Text>
                    </View>
                    {/* Value */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Outline,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: borderColorSmallDevice(dimensions),
                            },
                          ],
                          borderRightWidth: 1,
                          flex: 0.7,
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Checkbox
                        onPress={newCheckboxValue => {
                          try {
                            setInclude_in_multiples_db(newCheckboxValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...StyleSheet.applyWidth(
                          GlobalStyles.CheckboxStyles(theme)['default_checkbox']
                            .props,
                          dimensions.width
                        )}
                        status={include_in_multiples_db}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.CheckboxStyles(theme)['default_checkbox']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* note_financials */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  flex: [
                    { minWidth: Breakpoints.Mobile, value: 0.3 },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: flexOnlySmallDevice(dimensions, 0.3),
                    },
                  ],
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Table Title'].style,
                  dimensions.width
                )}
              >
                {'Note for financials'}
              </Text>
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  flex: 0.7,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setNote_financials(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Common TextInput'].style,
                  dimensions.width
                )}
                value={note_financials}
              />
            </View>
          </View>
        </Surface>
        {/* Surface 5 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 5 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Column 4 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Buy-side CF */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 1 },
                    ],
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Buy-side CF'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultAdvisors = (
                            await AdminGroupApi.getAdvisorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const new_suggestion_advisors =
                            transformToSearchInputFormat(
                              resultAdvisors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionBuySideCF(new_suggestion_advisors);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setBuyside_cf(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={buyside_cf}
                    suggestionsList={suggestionBuySideCF}
                  />
                </View>
              </View>
              {/* Sell-side CF */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Sell-side CF'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultAdvisors = (
                            await AdminGroupApi.getAdvisorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const suggestion_sellside_cf =
                            transformToSearchInputFormat(
                              resultAdvisors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionSellSideCF(suggestion_sellside_cf);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setSellside_cf(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={sellside_cf}
                    suggestionsList={suggestionSellSideCF}
                  />
                </View>
              </View>
            </View>
            {/* Column 3 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Buy-side Legal */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Buy-side Legal'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultAdvisors = (
                            await AdminGroupApi.getAdvisorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const new_suggestion_buyside_legal =
                            transformToSearchInputFormat(
                              resultAdvisors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionBuySideLegal(
                            new_suggestion_buyside_legal
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setBuyside_legal(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={buyside_legal}
                    suggestionsList={suggestionBuySideLegal}
                  />
                </View>
              </View>
              {/* Sell-side Legal */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Sell-side Legal'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultAdvisors = (
                            await AdminGroupApi.getAdvisorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const new_suggestion_sell_side_legal =
                            transformToSearchInputFormat(
                              resultAdvisors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionSellSideLegal(
                            new_suggestion_sell_side_legal
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setSellside_legal(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={sellside_legal}
                    suggestionsList={suggestionSellSideLegal}
                  />
                </View>
              </View>
            </View>
          </View>
          {/* Below League Threshold */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                flexDirection: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: rowOnlySmallDevice(dimensions),
                  },
                  { minWidth: Breakpoints.Laptop, value: 'row' },
                ],
                gap: { minWidth: Breakpoints.Laptop, value: 10 },
              },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  flex: flexOnlySmallDevice(dimensions, 0.3),
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Checkbox
                onPress={newCheckboxValue => {
                  try {
                    setBelow_league_threshold(newCheckboxValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...StyleSheet.applyWidth(
                  GlobalStyles.CheckboxStyles(theme)['default_checkbox'].props,
                  dimensions.width
                )}
                status={below_league_threshold}
                style={StyleSheet.applyWidth(
                  GlobalStyles.CheckboxStyles(theme)['default_checkbox'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    setBelow_league_threshold(
                      below_league_threshold ? false : true
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Below League Threshold?'}
                </Text>
              </Pressable>
            </View>
          </View>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingTop: 15,
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(
                headline,
                reviewed_by,
                status,
                source,
                source_type,
                source_link,
                target,
                event_type,
                country,
                gics,
                buyer,
                seller,
                strat_or_invf_buyer,
                strat_or_invf_seller,
                pepf,
                pefirm_behind_buyer,
                revenue_local,
                gross_profit_local,
                ebitda_local,
                ebit_local,
                currency_financials,
                fy_end,
                note_financials,
                ev,
                currency_ev,
                ev_source,
                include_in_multiples_db,
                buyside_cf,
                buyside_legal,
                sellside_cf,
                sellside_legal,
                published,
                link_to_financials,
                description,
                below_league_threshold
              );
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(EventFormBlock);
