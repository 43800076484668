import React from 'react';
import {
  DatePicker,
  Picker,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: null,
  isSubmitting: null,
  onSubmit: () => {},
};

const FundFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [country_hq, setCountry_hq] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.country_hq
  );
  const [description, setDescription] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.description
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [fund_size_currency, setFund_size_currency] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.fund_size_currency
  );
  const [fund_size_local, setFund_size_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.fund_size_local
  );
  const [horizon, setHorizon] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.horizon
  );
  const [name, setName] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.name
  );
  const [pe_firm, setPe_firm] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._pe_firm,
      'name',
      'id',
      undefined
    )
  );
  const [suggestionPEFirm, setSuggestionPEFirm] = React.useState([]);
  const [type, setType] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.type
  );
  const [vintage_date, setVintage_date] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.vintage_date
  );
  const validateForm = () => {
    const requiredFields = {
      name: 'Fund name',
      pe_firm: 'PE firm',
      description: 'Fund description',
      country_hq: 'Country',
      type: 'Type',
      horizon: 'Horizon',
      vintage_date: 'Vintage date',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => {
        const value = eval(key);
        return Array.isArray(value) ? value.length === 0 : !value;
      })
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) {
      setErrorMessage('');
      return true;
    }
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: palettes.App.Outline,
                  borderWidth: 1,
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.7, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 1 */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                },
                dimensions.width
              )}
            >
              {/* Fund Name */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    flexDirection: rowOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Fund name *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setName(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={name}
                  />
                </View>
              </View>
              {/* PE firm */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    flexDirection: rowOnlySmallDevice(dimensions),
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'PE firm *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: { minWidth: Breakpoints.Laptop, value: 10 },
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultInvestors = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              country_hq: [],
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              type: [],
                            })
                          )?.json;
                          const suggestionsList = transformToSearchInputFormat(
                            resultInvestors?.items,
                            'name',
                            'id',
                            undefined
                          );
                          setSuggestionPEFirm(suggestionsList);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setPe_firm(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={pe_firm}
                    multiSelect={false}
                    selectedItem={pe_firm}
                    suggestionsList={suggestionPEFirm}
                  />
                </View>
              </View>
            </View>
            {/* Fund description */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Fund description *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.7 },
                      { minWidth: Breakpoints.Laptop, value: 1 },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newTextAreaValue => {
                    try {
                      setDescription(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  numberOfLines={5}
                  placeholder={'Input description here'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextArea']
                        .style,
                      { flex: { minWidth: Breakpoints.Laptop, value: 1 } }
                    ),
                    dimensions.width
                  )}
                  value={description}
                />
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 2 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Fund size */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Fund size (m)'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setFund_size_local(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={fund_size_local}
                />
              </View>
            </View>
            {/* Fund currency* */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Fund currency'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.base}
                  dropDownBorderColor={theme.colors.border.base}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newPickerValue => {
                    try {
                      setFund_size_currency(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  mode={'native'}
                  options={Constants['CURRENCY_OPTIONS']}
                  rightIconName={'Feather/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={fund_size_currency}
                />
              </View>
            </View>
            {/* Country* */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Country *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.base}
                  dropDownBorderColor={theme.colors.border.base}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newPickerValue => {
                    try {
                      setCountry_hq(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  mode={'native'}
                  options={Constants['COUNTRY_OPTIONS']}
                  rightIconName={'Feather/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={country_hq}
                />
              </View>
            </View>
          </View>
          {/* Row 3 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Type */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Type *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.base}
                  dropDownBorderColor={theme.colors.border.base}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setType(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  options={Constants['FUND_TYPE_OPTIONS']}
                  rightIconName={'Feather/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={type}
                />
              </View>
            </View>
            {/* Horizon * */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Horizon *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.base}
                  dropDownBorderColor={theme.colors.border.base}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newPickerValue => {
                    try {
                      setHorizon(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  mode={'native'}
                  options={Constants['HORIZON_OPTIONS']}
                  rightIconName={'Feather/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={horizon}
                />
              </View>
            </View>
            {/* Vintage date * */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Vintage date *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  inline={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    try {
                      setVintage_date(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  {...GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                    .props}
                  date={customFormatDate(vintage_date)}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                      .style,
                    dimensions.width
                  )}
                />
              </View>
            </View>
          </View>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: 15,
                  textAlign: { minWidth: Breakpoints.Laptop, value: 'center' },
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(
                name,
                description,
                pe_firm,
                fund_size_local,
                fund_size_currency,
                country_hq,
                type,
                horizon,
                vintage_date
              );
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(FundFormBlock);
