import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as FuzzySearchMovable from '../custom-files/FuzzySearchMovable';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  customPlaceholder: 'testing...',
  getSuggestions: () => {},
  multiSelect: true,
  onClear: () => {},
  onSelect: () => {},
  reorder: false,
  selectedItem: null,
  selectedItems: [],
  suggestionsList: [],
};

const FuzzyInputMovableBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  React.useEffect(() => {
    try {
      props.getSuggestions?.(undefined);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <View>
      <Utils.CustomCodeErrorBoundary>
        <FuzzySearchMovable.Index
          {...{
            options: props?.suggestionsList ?? [],
            onSelect: props?.onSelect,
            getSuggestions: props?.getSuggestions,
            onClear: props?.onClear,
            multiSelect: props?.multiSelect ?? true,
            reorder: props?.reorder ?? false,
            selectedItem: props?.selectedItem ?? null,
            selectedItems: props?.selectedItems ?? [],
            customPlaceholder: props?.customPlaceholder ?? 'Search...',
          }}
        />
      </Utils.CustomCodeErrorBoundary>
    </View>
  );
};

export default withTheme(FuzzyInputMovableBlock);
