import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CustomMultiSelectPickerBlock from '../components/CustomMultiSelectPickerBlock';
import LoadingBlock from '../components/LoadingBlock';
import LoadingNextPageBlock from '../components/LoadingNextPageBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertToOptions from '../global-functions/convertToOptions';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as DateUtils from '../utils/DateUtils';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const FundListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [dach, setDach] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [fund, setFund] = React.useState(false);
  const [fundList, setFundList] = React.useState([]);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [loadingNextPage, setLoadingNextPage] = React.useState(false);
  const [nextPage, setNextPage] = React.useState(0);
  const [nordic, setNordic] = React.useState(false);
  const [numRecs, setNumRecs] = React.useState('');
  const [pe_firm_filter, setPe_firm_filter] = React.useState([]);
  const [pe_firm_filter_options, setPe_firm_filter_options] = React.useState(
    []
  );
  const [screenCode, setScreenCode] = React.useState('');
  const [weeklyReport, setWeeklyReport] = React.useState(false);
  const adminGroupDeleteFundDELETE = AdminGroupApi.useDeleteFundDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        /* hidden 'Set Variable' action */
        /* hidden 'Set Variable' action */
        const resultInvestors = (
          await AdminGroupApi.getInvestorsGET(Constants, {
            country_hq: [],
            keyword: '',
            page: 1,
            per_page: 10000,
            type: [],
          })
        )?.json;
        setPe_firm_filter_options(
          convertToOptions(resultInvestors?.items, 'name', 'id', undefined)
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('FundNavigator', {
                screen: 'FundCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD FUND'}
          showAddButton={true}
        />
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
                <CustomMultiSelectPickerBlock
                  onChange={selectedValues => {
                    try {
                      setPe_firm_filter(selectedValues);
                      setNextPage(1);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  options={pe_firm_filter_options}
                  placeholder={'PE firm managing fund'}
                  selectedOptions={pe_firm_filter}
                />
              </View>
            </View>
          </View>
          <>
            {!numRecs ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Table Row'].style,
                    theme.typography.body1,
                    { marginLeft: 20 }
                  ),
                  dimensions.width
                )}
              >
                {numRecs}
                {' records matching filter'}
              </Text>
            )}
          </>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 0.4,
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                />
                {/* Fund name */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Fund name'}
                      </Text>
                    </View>
                  )}
                </>
                {/* PE Firm */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'PE Firm'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Type */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                        { minWidth: Breakpoints.Tablet, value: 1 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Type'}
                  </Text>
                </View>
                {/* Horizon */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Horizon'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Country */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Country'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Vintage date */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.25 },
                        { minWidth: Breakpoints.Tablet, value: 1 },
                        { minWidth: Breakpoints.Laptop, value: 0.25 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Vintage date'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetFundGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== fundList) {
                          setFundList(fetchData?.json?.items);
                          setNumRecs(fetchData?.json?.itemsTotal);
                        } else {
                        }

                        /* hidden 'Log to Console' action */
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    onData: fetchData => {
                      try {
                        console.log(fetchData);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  page={1}
                  pe_firm={
                    pe_firm_filter?.length > 0 ? pe_firm_filter : undefined
                  }
                  per_page={25}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  retry={true}
                  staleTime={0}
                >
                  {({ loading, error, data, refetchGetFund }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <SimpleStyleFlatList
                          data={fundList}
                          decelerationRate={'normal'}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={
                            'Linear Gradient->View->Table->Container->Table Content->Fetch->List'
                          }
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              console.log('List ON_END_REACHED Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                /* hidden 'Log to Console' action */ console.log(
                                  'Complete ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(
                                  'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                                if (nextPage === null) {
                                  return console.log(
                                    'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:2 SET_VARIABLE'
                                );
                                setLoadingNextPage(true);
                                console.log(
                                  'Complete ON_END_REACHED:2 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:3 FETCH_REQUEST'
                                );
                                const newData = (
                                  await AdminGroupApi.getFundGET(Constants, {
                                    keyword: keywordSearch,
                                    page: nextPage,
                                    pe_firm:
                                      pe_firm_filter?.length > 0
                                        ? pe_firm_filter
                                        : undefined,
                                    per_page: 25,
                                  })
                                )?.json;
                                console.log(
                                  'Complete ON_END_REACHED:3 FETCH_REQUEST',
                                  { newData }
                                );
                                console.log(
                                  'Start ON_END_REACHED:4 SET_VARIABLE'
                                );
                                setNextPage(newData?.nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:4 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:5 SET_VARIABLE'
                                );
                                setLastPage(newData?.pageTotal);
                                console.log(
                                  'Complete ON_END_REACHED:5 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:6 SET_VARIABLE'
                                );
                                setLoadingNextPage(false);
                                console.log(
                                  'Complete ON_END_REACHED:6 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:7 CONDITIONAL_STOP'
                                );
                                if (
                                  fetchData?.items ===
                                  (0 || fundList !== fetchData?.items)
                                ) {
                                  return console.log(
                                    'Complete ON_END_REACHED:7 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:7 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:8 SET_VARIABLE'
                                );
                                setFundList(fundList.concat(newData?.items));
                                console.log(
                                  'Complete ON_END_REACHED:8 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'List ON_END_REACHED Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          pagingEnabled={false}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    alignSelf: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'auto',
                                    },
                                    backgroundColor: getColorByIndex(index),
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: 1,
                                    flexDirection: 'row',
                                    width: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 1200,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Actions */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 0.4,
                                      flexDirection: 'row',
                                      height: '100%',
                                      justifyContent: 'center',
                                      maxWidth: 150,
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <TableActionsBlock
                                    onPressDelete={() => {
                                      try {
                                        setDeletingItem(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    onPressEdit={() => {
                                      try {
                                        navigation.navigate('FundNavigator', {
                                          screen: 'FundEditScreen',
                                          params: { id: listData?.id },
                                        });
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    border={false}
                                  />
                                </View>
                                {/* Fund name */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.name}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* PE Firm */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?._pe_firm?.name}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Type */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.5,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                      height: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.type}
                                  </Text>
                                </View>
                                {/* Horizon */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* description */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.horizon}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Country */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.country_hq}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Vintage date */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.25,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 1,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.25,
                                        },
                                      ],
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {DateUtils.format(
                                      listData?.vintage_date,
                                      'DD/MM/YYYY'
                                    )}
                                  </Text>
                                </View>
                              </View>
                            );
                          }}
                          snapToAlignment={'start'}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value:
                                  dimensions.width >= Breakpoints.Laptop
                                    ? 0
                                    : undefined,
                              },
                              maxHeight: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: dimensions.height - 250,
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: dimensions.height - 280,
                                },
                              ],
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        <>
                          {!loadingNextPage ? null : <LoadingNextPageBlock />}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetFundGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteNewsletter = (
                  await adminGroupDeleteFundDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteNewsletter?.error) {
                  toastMessage(resultDeleteNewsletter?.message, 'error');
                } else {
                  toastMessage(
                    'Delete peer group admin successfully',
                    'success'
                  );
                }

                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'fund'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(FundListingScreen);
