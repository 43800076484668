import React, { useState, useCallback, useRef } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  FlatList,
} from 'react-native';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import moment from 'moment-timezone';

const Index = ({
  onChange,
  minWidth = 300,
  maxWidth = 300,
  placeholder = 'Select date range',
  startDate: initialStartDate = null,
  endDate: initialEndDate = null,
}) => {
  const [startDate, setStartDate] = useState(
    initialStartDate ? moment(initialStartDate).tz('Europe/Copenhagen') : null
  );
  const [endDate, setEndDate] = useState(
    initialEndDate ? moment(initialEndDate).tz('Europe/Copenhagen') : null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownLayout, setDropdownLayout] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(
    moment().tz('Europe/Copenhagen')
  );
  const pickerRef = useRef(null);

  React.useEffect(() => {
    setStartDate(
      initialStartDate ? moment(initialStartDate).tz('Europe/Copenhagen') : null
    );
    setEndDate(
      initialEndDate ? moment(initialEndDate).tz('Europe/Copenhagen') : null
    );
  }, [initialStartDate, initialEndDate]);

  const toggleDropdown = useCallback(() => {
    if (!isOpen) {
      pickerRef.current.measure((x, y, width, height, pageX, pageY) => {
        setDropdownLayout({ width, height, pageX, pageY: pageY + height });
      });
    }
    setIsOpen(prev => !prev);
  }, [isOpen]);

  const handleDatePress = date => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
      onChange(date.utc().format(), null);
    } else if (date.isBefore(startDate)) {
      setStartDate(date);
      setEndDate(null);
      onChange(date.utc().format(), null);
    } else {
      setEndDate(date);
      onChange(startDate.utc().format(), date.utc().format());
      setIsOpen(false);
    }
  };

  const handleOutsidePress = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    onChange(null, null);
  };

  const renderCalendar = () => {
    const startOfMonth = currentMonth.clone().startOf('month');
    const endOfMonth = currentMonth.clone().endOf('month');
    const days = [];

    // Add days from previous month to start on Sunday
    const startDay = startOfMonth.day();
    for (let i = startDay; i > 0; i--) {
      days.push(startOfMonth.clone().subtract(i, 'days'));
    }

    // Add days of current month
    for (
      let day = startOfMonth.clone();
      day.isSameOrBefore(endOfMonth);
      day = day.clone().add(1, 'd')
    ) {
      days.push(day);
    }

    // Add days from next month to complete the last week
    const endDay = endOfMonth.day();
    for (let i = 1; i < 7 - endDay; i++) {
      days.push(endOfMonth.clone().add(i, 'days'));
    }

    return (
      <View style={styles.calendar}>
        <View style={styles.calendarHeader}>
          <TouchableOpacity
            onPress={() =>
              setCurrentMonth(currentMonth.clone().subtract(1, 'month'))
            }
          >
            <Ionicons name="chevron-back" size={24} color="black" />
          </TouchableOpacity>
          <Text style={styles.monthYearText}>
            {currentMonth.format('MMMM YYYY')}
          </Text>
          <TouchableOpacity
            onPress={() =>
              setCurrentMonth(currentMonth.clone().add(1, 'month'))
            }
          >
            <Ionicons name="chevron-forward" size={24} color="black" />
          </TouchableOpacity>
        </View>
        <View style={styles.weekDaysContainer}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <Text key={day} style={styles.weekDayText}>
              {day}
            </Text>
          ))}
        </View>
        <FlatList
          data={days}
          numColumns={7}
          scrollEnabled={false}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={[
                styles.dayButton,
                item.isSame(currentMonth, 'month')
                  ? styles.currentMonthDay
                  : styles.otherMonthDay,
                startDate &&
                  item.isSame(startDate, 'day') &&
                  styles.selectedStartDate,
                endDate &&
                  item.isSame(endDate, 'day') &&
                  styles.selectedEndDate,
                startDate &&
                  endDate &&
                  item.isBetween(startDate, endDate) &&
                  styles.betweenDates,
                item.isSame(moment().tz('Europe/Copenhagen'), 'day') &&
                  styles.todayButton, // Add this line
              ]}
              onPress={() => handleDatePress(item)}
            >
              <Text
                style={[
                  styles.dayText,
                  item.isSame(currentMonth, 'month')
                    ? styles.currentMonthDayText
                    : styles.otherMonthDayText,
                  (startDate && item.isSame(startDate, 'day')) ||
                  (endDate && item.isSame(endDate, 'day'))
                    ? styles.selectedDayText
                    : null,
                  item.isSame(moment().tz('Europe/Copenhagen'), 'day') &&
                    styles.todayText, // Add this line
                ]}
              >
                {item.format('D')}
              </Text>
            </TouchableOpacity>
          )}
          keyExtractor={item => item.format('YYYY-MM-DD')}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        ref={pickerRef}
        onPress={toggleDropdown}
        style={[styles.picker, minWidth ? { minWidth } : null]}
        activeOpacity={1}
      >
        <View style={styles.dateRangeContainer}>
          {startDate && endDate ? (
            <Text style={styles.dateRangeText}>
              {startDate.tz('Europe/Copenhagen').format('MMM D, YYYY')} -{' '}
              {endDate.tz('Europe/Copenhagen').format('MMM D, YYYY')}
            </Text>
          ) : startDate ? (
            <Text style={styles.dateRangeText}>
              {startDate.tz('Europe/Copenhagen').format('MMM D, YYYY')} - Select
            </Text>
          ) : (
            <Text style={styles.placeholder}>{placeholder}</Text>
          )}
        </View>
        <View style={styles.pickerControls}>
          {startDate && (
            <TouchableOpacity onPress={handleReset} style={styles.resetButton}>
              <MaterialIcons name="clear" size={20} color="gray" />
            </TouchableOpacity>
          )}
          <Ionicons
            name={isOpen ? 'chevron-up' : 'chevron-down'}
            size={24}
            color="gray"
          />
        </View>
      </TouchableOpacity>
      <Modal
        visible={isOpen}
        transparent={true}
        animationType="none"
        onRequestClose={() => setIsOpen(false)}
      >
        <TouchableWithoutFeedback onPress={handleOutsidePress}>
          <View style={styles.modalOverlay}>
            {dropdownLayout && (
              <TouchableWithoutFeedback>
                <View
                  style={[
                    styles.dropdown,
                    {
                      top: dropdownLayout.pageY,
                      left: dropdownLayout.pageX,
                      width: dropdownLayout.width,
                    },
                  ]}
                >
                  {renderCalendar()}
                </View>
              </TouchableWithoutFeedback>
            )}
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1000,
  },
  picker: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 12,
    backgroundColor: 'white',
  },
  dateRangeContainer: {
    flex: 1,
  },
  dateRangeText: {
    fontSize: 16,
  },
  placeholder: {
    color: '#999',
    fontSize: 16,
  },
  pickerControls: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  resetButton: {
    marginRight: 8,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  dropdown: {
    position: 'absolute',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: 10,
  },
  calendar: {
    width: '100%',
  },
  calendarHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
    paddingHorizontal: 10,
  },
  monthYearText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  weekDaysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 10,
  },
  weekDayText: {
    fontSize: 12,
    color: '#666',
    width: 40,
    textAlign: 'center',
  },
  dayButton: {
    flex: 1,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayText: {
    fontSize: 14,
  },
  currentMonthDay: {
    backgroundColor: 'white',
  },
  otherMonthDay: {
    backgroundColor: '#f0f0f0',
  },
  currentMonthDayText: {
    color: 'black',
  },
  otherMonthDayText: {
    color: '#999',
  },
  selectedStartDate: {
    backgroundColor: '#1B62F8',
    borderRadius: 10,
  },
  selectedEndDate: {
    backgroundColor: '#1B62F8',
    borderRadius: 10,
  },
  betweenDates: {
    backgroundColor: '#E6EFFF',
  },
  selectedDayText: {
    color: 'white',
    fontWeight: 'bold',
  },
  todayButton: {},
  todayText: {
    color: 'red',
    fontWeight: 'bold',
  },
});

export { Index };
