import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  acc_pressed: false,
  activePage: {
    cfs: false,
    pepf: false,
    peers: false,
    events: false,
    stocks: false,
    advisors: false,
    newsletter: true,
  },
  AUTH_HEADER: null,
  currentScreen: 'Newsletters',
  ME: null,
  originPage: '',
  pageName: 'M&A Insights',
  RESET_AUTH_HEADER: '',
  return_to_event: false,
  screenParamName: '',
  screenParamValue: '',
  selectedPage: '',
  subPage: false,
  top_nav_pressed: false,
  __env__: 'Production',
};
export const AppVariables = {
  ACCESSIBLE_REGIONS_OPTIONS: [
    { label: 'Nordic', value: 'Nordic' },
    { label: 'DACH', value: 'DACH' },
    { label: 'RoW', value: 'RoW' },
  ],
  ADVISOR_TYPE_OPTIONS: [
    { label: 'Corporate Finance', value: 'Corporate Finance' },
    { label: 'Legal Counsel', value: 'Legal Counsel' },
  ],
  ALL_INVESTORS_OPTIONS: [],
  CFS_STAGE_OPTIONS: [
    { label: 'Coming', value: 'Coming' },
    { label: 'Live', value: 'Live' },
    { label: 'Paused', value: 'Paused' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Not Relevant', value: 'Not relevant' },
  ],
  COMPANY_TYPE_OPTIONS: [
    { label: 'Private Equity', value: 'Private Equity' },
    {
      label: 'Private Debt/Leveraged Finance',
      value: 'Private Debt/Leveraged Finance',
    },
    { label: 'Strategy Consulting', value: 'Strategy Consulting' },
    { label: 'Financial DD', value: 'Financial DD' },
    { label: 'Law Firm', value: 'Law Firm' },
    { label: 'M&A Insurance', value: 'M&A Insurance' },
    { label: 'Wealth Management', value: 'Wealth Management' },
    { label: 'Corporate Finance', value: 'Corporate Finance' },
    { label: 'Private Debt', value: 'Private Debt' },
  ],
  COUNTRY_OPTIONS: [
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Sweden', value: 'Sweden' },
    { label: 'Norway', value: 'Norway' },
    { label: 'Finland', value: 'Finland' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Austria', value: 'Austria' },
    { label: 'RoW', value: 'RoW' },
  ],
  CURRENCY_OPTIONS: [
    { label: 'DKK', value: 'DKK' },
    { label: 'SEK', value: 'SEK' },
    { label: 'NOK', value: 'NOK' },
    { label: 'EUR', value: 'EUR' },
    { label: 'CHF', value: 'CHF' },
    { label: 'USD', value: 'USD' },
    { label: 'GBP', value: 'GBP' },
    { label: 'n.m.', value: 'n.m.' },
  ],
  EVENT_STATUS_OPTIONS: [
    { label: 'Staging', value: 'Staging' },
    { label: 'Not Relevant', value: 'Not Relevant' },
    { label: 'Published', value: 'Published' },
    { label: 'Production', value: 'Production' },
  ],
  EVENT_TYPE_OPTIONS: [
    { label: 'Acquisition Agenda', value: 'Acquisition Agenda' },
    { label: 'Capital Raise', value: 'Capital Raise' },
    { label: 'Debt Issue', value: 'Debt Issue' },
    { label: 'Future Opportunity', value: 'Future Opportunity' },
    { label: 'IPO', value: 'IPO' },
    { label: 'Majority Sale', value: 'Majority Sale' },
    { label: 'Minority Sale', value: 'Minority Sale' },
    { label: 'Other', value: 'Other' },
    { label: 'Share Issue', value: 'Share Issue' },
  ],
  EV_SOURCE_OPTIONS: [
    { label: 'NKP Proprietary', value: 'NKP Proprietary' },
    { label: 'Publicly Confirmed', value: 'Publicly Confirmed' },
    { label: 'Media Intelligence', value: 'Media Intelligence' },
    { label: 'n.m.', value: 'n.m.' },
  ],
  FUND_TYPE_OPTIONS: [
    { label: 'Buyout Fund', value: 'Buyout Fund' },
    { label: 'Growth Equity Fund', value: 'Growth Equity Fund' },
    { label: 'Venture Capital Fund', value: 'Venture Capital Fund' },
  ],
  GICS_OPTIONS: [
    { label: 'Communication Services - Advertising', value: 1 },
    { label: 'Communication Services - Alternative Carriers', value: 8 },
    { label: 'Communication Services - Broadcasting', value: 19 },
    { label: 'Communication Services - Cable & Satellite', value: 21 },
    {
      label: 'Communication Services - Integrated Telecommunication Services',
      value: 89,
    },
    {
      label: 'Communication Services - Interactive Home Entertainment',
      value: 90,
    },
    {
      label: 'Communication Services - Interactive Media & Services',
      value: 91,
    },
    { label: 'Communication Services - Movies & Entertainment', value: 106 },
    { label: 'Communication Services - Publishing', value: 125 },
    {
      label: 'Communication Services - Wireless Telecommunication Services',
      value: 158,
    },
    { label: 'Consumer Discretionary - Apparel Retail', value: 11 },
    {
      label: 'Consumer Discretionary - Apparel, Accessories & Luxury Goods',
      value: 10,
    },
    { label: 'Consumer Discretionary - Auto Parts & Equipment', value: 16 },
    { label: 'Consumer Discretionary - Automobile Manufacturers', value: 14 },
    { label: 'Consumer Discretionary - Automotive Retail', value: 15 },
    { label: 'Consumer Discretionary - Broadline Retail', value: 159 },
    { label: 'Consumer Discretionary - Casinos & Gaming', value: 22 },
    {
      label: 'Consumer Discretionary - Computer & Electronics Retail',
      value: 27,
    },
    { label: 'Consumer Discretionary - Consumer Electronics', value: 31 },
    { label: 'Consumer Discretionary - Distributors', value: 37 },
    { label: 'Consumer Discretionary - Education Services', value: 46 },
    { label: 'Consumer Discretionary - Footwear', value: 57 },
    { label: 'Consumer Discretionary - Home Furnishings', value: 73 },
    { label: 'Consumer Discretionary - Home Improvement Retail', value: 74 },
    { label: 'Consumer Discretionary - Homebuilding', value: 71 },
    { label: 'Consumer Discretionary - Homefurnishing Retail', value: 72 },
    {
      label: 'Consumer Discretionary - Hotels, Resorts & Cruise Lines',
      value: 76,
    },
    { label: 'Consumer Discretionary - Household Appliances', value: 77 },
    { label: 'Consumer Discretionary - Housewares & Specialties', value: 79 },
    { label: 'Consumer Discretionary - Leisure Facilities', value: 96 },
    { label: 'Consumer Discretionary - Leisure Products', value: 97 },
    { label: 'Consumer Discretionary - Motorcycle Manufacturers', value: 105 },
    { label: 'Consumer Discretionary - Restaurants', value: 135 },
    {
      label: 'Consumer Discretionary - Specialized Consumer Services',
      value: 142,
    },
    { label: 'Consumer Discretionary - Specialty Stores', value: 146 },
    { label: 'Consumer Discretionary - Textiles', value: 151 },
    { label: 'Consumer Discretionary - Tires & Rubber', value: 153 },
    { label: 'Consumer Staples - Agricultural Products', value: 4 },
    { label: 'Consumer Staples - Brewers', value: 18 },
    {
      label: 'Consumer Staples - Consumer Staples Merchandise Retail',
      value: 160,
    },
    { label: 'Consumer Staples - Distillers & Vintners', value: 36 },
    { label: 'Consumer Staples - Drug Retail', value: 45 },
    { label: 'Consumer Staples - Food Distributors', value: 55 },
    { label: 'Consumer Staples - Food Retail', value: 56 },
    { label: 'Consumer Staples - Household Products', value: 78 },
    { label: 'Consumer Staples - Packaged Foods & Meats', value: 118 },
    { label: 'Consumer Staples - Personal Products', value: 121 },
    { label: 'Consumer Staples - Soft Drinks', value: 141 },
    { label: 'Consumer Staples - Tobacco', value: 154 },
    { label: 'Energy - Coal & Consumable Fuels', value: 23 },
    { label: 'Energy - Integrated Oil & Gas', value: 88 },
    { label: 'Energy - Oil & Gas Drilling', value: 112 },
    { label: 'Energy - Oil & Gas Equipment & Services', value: 113 },
    { label: 'Energy - Oil & Gas Exploration & Production', value: 114 },
    { label: 'Energy - Oil & Gas Refining & Marketing', value: 115 },
    { label: 'Energy - Oil & Gas Storage & Transportation', value: 116 },
    { label: 'Financials - Asset Management & Custody Banks', value: 13 },
    { label: 'Financials - Consumer Finance', value: 32 },
    { label: 'Financials - Diversified Banks', value: 38 },
    { label: 'Financials - Diversified Capital Markets', value: 39 },
    { label: 'Financials - Financial Exchanges & Data', value: 54 },
    { label: 'Financials - Insurance Brokers', value: 87 },
    { label: 'Financials - Investment Banking & Brokerage', value: 94 },
    { label: 'Financials - Life & Health Insurance', value: 98 },
    { label: 'Financials - Mortgage REITs', value: 104 },
    { label: 'Financials - Multi-line Insurance', value: 107 },
    { label: 'Financials - Multi-Sector Holdings', value: 108 },
    { label: 'Financials - Other Diversified Financial Services', value: 117 },
    { label: 'Financials - Property & Casualty Insurance', value: 124 },
    { label: 'Financials - Regional Banks', value: 130 },
    { label: 'Financials - Reinsurance', value: 131 },
    { label: 'Financials - Specialized Finance', value: 143 },
    { label: 'Financials - Thrifts & Mortgage Finance', value: 152 },
    {
      label: 'Financials - Transaction & Payment Processing Services',
      value: 167,
    },
    { label: 'Health Care - Biotechnology', value: 17 },
    { label: 'Health Care - Health Care Distributors', value: 62 },
    { label: 'Health Care - Health Care Equipment', value: 63 },
    { label: 'Health Care - Health Care Facilities', value: 64 },
    { label: 'Health Care - Health Care Services', value: 66 },
    { label: 'Health Care - Health Care Supplies', value: 67 },
    { label: 'Health Care - Health Care Technology', value: 68 },
    { label: 'Health Care - Life Sciences Tools & Services', value: 99 },
    { label: 'Health Care - Managed Health Care', value: 100 },
    { label: 'Health Care - Pharmaceuticals', value: 122 },
    { label: 'Industrials - Aerospace & Defense', value: 2 },
    { label: 'Industrials - Agricultural & Farm Machinery', value: 3 },
    { label: 'Industrials - Air Freight & Logistics', value: 5 },
    { label: 'Industrials - Airlines', value: 6 },
    { label: 'Industrials - Airport Services', value: 7 },
    { label: 'Industrials - Building Products', value: 20 },
    { label: 'Industrials - Commercial Printing', value: 24 },
    { label: 'Industrials - Construction & Engineering', value: 28 },
    { label: 'Industrials - Construction Machinery & Heavy Trucks', value: 29 },
    { label: 'Industrials - Diversified Support Services', value: 44 },
    { label: 'Industrials - Electrical Components & Equipment', value: 47 },
    { label: 'Industrials - Environmental & Facilities Services', value: 52 },
    { label: 'Industrials - Heavy Electrical Equipment', value: 69 },
    { label: 'Industrials - Highways & Railtracks', value: 70 },
    { label: 'Industrials - Human Resource & Employment Services', value: 80 },
    { label: 'Industrials - Industrial Conglomerates', value: 83 },
    { label: 'Industrials - Industrial Machinery', value: 85 },
    { label: 'Industrials - Marine', value: 101 },
    { label: 'Industrials - Marine Ports & Services', value: 102 },
    { label: 'Industrials - Office Services & Supplies', value: 111 },
    { label: 'Industrials - Passenger Ground Transportation', value: 162 },
    { label: 'Industrials - Railroads', value: 126 },
    { label: 'Industrials - Research & Consulting Services', value: 133 },
    { label: 'Industrials - Security & Alarm Services', value: 137 },
    { label: 'Industrials - Trading Companies & Distributors', value: 155 },
    { label: 'Industrials - Trucking', value: 156 },
    { label: 'Information Technology - Application Software', value: 12 },
    { label: 'Information Technology - Communications Equipment', value: 26 },
    {
      label: 'Information Technology - Data Processing & Outsourced Services',
      value: 34,
    },
    { label: 'Information Technology - Electronic Components', value: 49 },
    {
      label: 'Information Technology - Electronic Equipment & Instruments',
      value: 50,
    },
    {
      label: 'Information Technology - Electronic Manufacturing Services',
      value: 51,
    },
    {
      label: 'Information Technology - Internet Services & Infrastructure',
      value: 93,
    },
    {
      label: 'Information Technology - IT Consulting & Other Services',
      value: 95,
    },
    { label: 'Information Technology - Semiconductor Equipment', value: 138 },
    { label: 'Information Technology - Semiconductors', value: 139 },
    { label: 'Information Technology - Systems Software', value: 148 },
    { label: 'Information Technology - Technology Distributors', value: 149 },
    {
      label:
        'Information Technology - Technology Hardware, Storage & Peripherals',
      value: 150,
    },
    { label: 'Materials - Aluminum', value: 9 },
    { label: 'Materials - Commodity Chemicals', value: 25 },
    { label: 'Materials - Construction Materials', value: 30 },
    { label: 'Materials - Copper', value: 33 },
    { label: 'Materials - Diversified Chemicals', value: 40 },
    { label: 'Materials - Diversified Metals & Mining', value: 41 },
    { label: 'Materials - Fertilizers & Agricultural Chemicals', value: 53 },
    { label: 'Materials - Forest Products', value: 58 },
    { label: 'Materials - Gold', value: 61 },
    { label: 'Materials - Industrial Gases', value: 84 },
    { label: 'Materials - Metal & Glass Containers', value: 103 },
    { label: 'Materials - Paper Packaging', value: 119 },
    { label: 'Materials - Paper Products', value: 120 },
    { label: 'Materials - Precious Metals & Minerals', value: 123 },
    { label: 'Materials - Silver', value: 140 },
    { label: 'Materials - Specialty Chemicals', value: 145 },
    { label: 'Materials - Steel', value: 147 },
    { label: 'Real Estate - Data Center REITs', value: 161 },
    { label: 'Real Estate - Diversified Real Estate Activities', value: 42 },
    { label: 'Real Estate - Diversified REITs', value: 43 },
    { label: 'Real Estate - Health Care REITs', value: 65 },
    { label: 'Real Estate - Hotel & Resort REITs', value: 75 },
    { label: 'Real Estate - Industrial REITs', value: 86 },
    { label: 'Real Estate - Office REITs', value: 110 },
    { label: 'Real Estate - Real Estate Development', value: 127 },
    { label: 'Real Estate - Real Estate Operating Companies', value: 128 },
    { label: 'Real Estate - Real Estate Services', value: 129 },
    { label: 'Real Estate - Residential REITs', value: 134 },
    { label: 'Real Estate - Retail REITs', value: 136 },
    { label: 'Real Estate - Self-Storage REITs', value: 163 },
    { label: 'Real Estate - Single-Family Residential REITs', value: 164 },
    { label: 'Real Estate - Specialized REITs', value: 144 },
    { label: 'Real Estate - Telecom Tower REITs', value: 165 },
    { label: 'Real Estate - Timber REITs', value: 166 },
    { label: 'Utilities - Electric Utilities', value: 48 },
    { label: 'Utilities - Gas Utilities', value: 59 },
    {
      label: 'Utilities - Independent Power Producers & Energy Traders',
      value: 82,
    },
    { label: 'Utilities - Multi-Utilities', value: 109 },
    { label: 'Utilities - Renewable Electricity', value: 132 },
    { label: 'Utilities - Water Utilities', value: 157 },
  ],
  HORIZON_OPTIONS: [
    { label: 'Close-end', value: 'Close-end' },
    { label: 'Open-end', value: 'Open-end' },
  ],
  INVESTOR_TYPE_OPTIONS: [
    { label: 'Private Equity Firm', value: 'Private Equity Firm' },
    { label: 'Private Individual', value: 'Private Individual' },
    { label: 'Strategic', value: 'Strategic' },
    { label: 'Public Markets', value: 'Public Markets' },
    { label: 'Other', value: 'Other' },
    { label: 'Other Investment Firms', value: 'Other Investment Firms' },
    { label: 'Government', value: 'Government' },
  ],
  LANGUAGE_OPTIONS: [
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'English', value: 'English' },
    { label: 'French', value: 'French' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Finnish', value: 'Finnish' },
    { label: 'German', value: 'German' },
  ],
  PAGES_LINKING_OPTIONS: [
    { label: 'Event', value: 'event' },
    { label: 'Newsletter', value: 'newsletter' },
    { label: 'Potd', value: 'potd' },
    { label: 'Peer Group', value: 'peer_group' },
    { label: 'CFS', value: 'cfs' },
    { label: 'PEPF', value: 'pepf' },
    { label: 'Fund', value: 'fund' },
    { label: 'Investor', value: 'investor' },
    { label: 'Advisors', value: 'advisor' },
  ],
  PAGES_OPTIONS: '',
  PEER_GROUP_ACCESS_TYPE_OPTIONS: [
    { label: 'NKP comps', value: 'NKP comps' },
    { label: 'Private', value: 'Private' },
  ],
  PEPF_STAKE_TYPE_OPTIONS: [
    { label: 'Majority', value: 'Majority' },
    { label: 'Minority', value: 'Minority' },
  ],
  RANGE_OPTIONS: [
    { label: '>', value: '>' },
    { label: '>=', value: '>=' },
    { label: '<', value: '<' },
    { label: '<=', value: '<=' },
    { label: '==', value: '=' },
  ],
  REGION_OPTIONS: [
    { label: 'Nordic', value: 'Nordic' },
    { label: 'DACH', value: 'DACH' },
    { label: 'RoW', value: 'RoW' },
  ],
  SEND_SS_NOTIF: '',
  SOURCE_TYPE_OPTIONS: [
    { label: 'NKP Proprietary', value: 'NKP Proprietary' },
    { label: 'Publicly Confirmed', value: 'Publicly Confirmed' },
    { label: 'Media Intelligence', value: 'Media Intelligence' },
  ],
  SS_SCREEN_NAME: '',
  SS_SUBSCRIPTION: '',
  STATUS_OPTIONS: [
    { label: 'Draft', value: 'Draft' },
    { label: 'Published', value: 'Published' },
  ],
  TEST_SELECTED_ITEMS: [
    { id: 1, title: 'Item One' },
    { id: 2, title: 'Item Two' },
    { id: 3, title: 'Item Three' },
    { id: 4, title: 'Item Four' },
    { id: 5, title: 'Item Five' },
  ],
  USERS_CLIENTS_OPTIONS: [],
  USER_STATUS_OPTIONS: [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ],
  VERSION_DACH: 'DACH',
  VERSION_NORDIC: 'Nordic',
  WATCHED_EVENT_IDX: -1,
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
