import React from 'react';
import {
  DatePicker,
  IconButton,
  Picker,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import AutoSizeInputBlock from '../components/AutoSizeInputBlock';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import MonthYearPickerBlock from '../components/MonthYearPickerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: {},
  isSubmitting: false,
  onSubmit: () => {},
};

const CFSFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [advisors_cf, setAdvisors_cf] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.advisors_cf,
      'name',
      'id',
      undefined
    )
  );
  const [advisors_legal, setAdvisors_legal] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.advisors_legal,
      'name',
      'id',
      undefined
    )
  );
  const [company, setCompany] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.company
  );
  const [company_description, setCompany_description] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.company_description
  );
  const [country, setCountry] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.country
  );
  const [currency, setCurrency] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.currency
  );
  const [ebitda, setEbitda] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ebitda
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [event, setEvent] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._event,
      'headline',
      'id',
      undefined
    )
  );
  const [first_added, setFirst_added] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.first_added
  );
  const [fy_end, setFy_end] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.fy_end
  );
  const [gics, setGics] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)
        ?._gics_sub_industry_single,
      'GICS_Sector',
      'id',
      'GICS_Sub_Industry'
    )
  );
  const [growth, setGrowth] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.growth
  );
  const [link_financials, setLink_financials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.link_financials
  );
  const [logo_url, setLogo_url] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.logo_url
  );
  const [note_financials, setNote_financials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.note_financials
  );
  const [other_events, setOther_events] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.other_events,
      'headline',
      'id',
      undefined
    )
  );
  const [owners, setOwners] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.owners,
      'name',
      'id',
      undefined
    )
  );
  const [peer_group, setPeer_group] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._peer_group_single,
      'title',
      'id',
      undefined
    )
  );
  const [pepf, setPepf] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._pepf_single,
      'Company',
      'id',
      undefined
    )
  );
  const [revenue, setRevenue] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.revenue
  );
  const [source_type, setSource_type] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.source_type
  );
  const [stage, setStage] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.stage
  );
  const [strat_or_invf_owners, setStrat_or_invf_owners] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.strat_or_invf_owner
  );
  const [suggestionAdvisors, setSuggestionAdvisors] = React.useState([]);
  const [suggestionEvents, setSuggestionEvents] = React.useState([]);
  const [suggestionGics, setSuggestionGics] = React.useState([]);
  const [suggestionOwners, setSuggestionOwners] = React.useState([]);
  const [suggestionPeerGroup, setSuggestionPeerGroup] = React.useState([]);
  const [suggestionPepf, setSuggestionPepf] = React.useState([]);
  const [timeline, setTimeline] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.Timeline
  );
  const [transacted, setTransacted] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.transacted
  );
  const [website, setWebsite] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.website
  );
  const [date, setDate] = React.useState(new Date());
  const validateForm = () => {
    const requiredFields = {
      first_added: 'First Added',
      event: 'Main Event',
      stage: 'Stage',
      company: 'Company',
      owners: 'Owners',
      country: 'Country',
      gics: 'GICS',
      source_type: 'Source Type',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => {
        const value = eval(key);
        return Array.isArray(value) ? value.length === 0 : !value;
      })
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) {
      setErrorMessage('');
      return true;
    }
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: palettes.App.Outline,
                  borderWidth: 1,
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.7, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              },
              dimensions.width
            )}
          >
            {/* Column 1 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* First Added */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'First added *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.7),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <DatePicker
                    autoDismissKeyboard={true}
                    disabled={false}
                    hideLabel={false}
                    inline={false}
                    label={'Date'}
                    leftIconMode={'inset'}
                    mode={'date'}
                    onDateChange={newDatePickerValue => {
                      const date = newDatePickerValue;
                      try {
                        setFirst_added(newDatePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    type={'solid'}
                    {...GlobalStyles.DatePickerStyles(theme)[
                      'defaultDatePicker'
                    ].props}
                    date={customFormatDate(first_added)}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                        .style,
                      dimensions.width
                    )}
                  />
                </View>
              </View>
              {/* Company */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Company *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.7),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setCompany(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={company}
                  />
                </View>
              </View>
              {/* Owners */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Owners *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.7),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultInvestors = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              country_hq: [],
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              type: [],
                            })
                          )?.json;
                          setSuggestionOwners(
                            transformToSearchInputFormat(
                              resultInvestors?.items,
                              'name',
                              'id',
                              undefined
                            )
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setOwners(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItems={owners}
                    suggestionsList={suggestionOwners}
                  />
                </View>
              </View>
              {/* strat_or_invf_owners */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Strategic or Investment Firm Owners'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.7),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setStrat_or_invf_owners(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={strat_or_invf_owners}
                  />
                </View>
              </View>
            </View>
            {/* Column 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Main Event */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Main Event *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultEvents = (
                            await AdminGroupApi.getEventsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              status: ['Published'],
                            })
                          )?.json;
                          const eventsOptions = transformToSearchInputFormat(
                            resultEvents?.items,
                            'headline',
                            'id',
                            undefined
                          );
                          setSuggestionEvents(eventsOptions);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setEvent(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={event}
                    multiSelect={false}
                    selectedItem={event}
                    suggestionsList={suggestionEvents}
                  />
                </View>
              </View>
              {/* Other events */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Other events '}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultEvents = (
                            await AdminGroupApi.getEventsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                              status: ['Published'],
                            })
                          )?.json;
                          const suggestion_events =
                            transformToSearchInputFormat(
                              resultEvents?.items,
                              'headline',
                              'id',
                              undefined
                            );
                          setSuggestionEvents(suggestion_events);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setOther_events(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    multiSelect={true}
                    selectedItem={null}
                    selectedItems={other_events}
                    suggestionsList={suggestionEvents}
                  />
                </View>
              </View>
            </View>
            {/* Column 3 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Stage */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Stage *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newPickerValue => {
                      try {
                        setStage(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    mode={'native'}
                    options={Constants['CFS_STAGE_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={stage}
                  />
                </View>
              </View>
              {/* Timeline */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Timeline '}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setTimeline(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={timeline}
                  />
                </View>
              </View>
              {/* PE portfolio firm */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'PE portfolio firm'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultPepf = (
                            await AdminGroupApi.getPepfGET(Constants, {
                              company_name: '',
                              country: [],
                              current_holding_years: null,
                              gics: [],
                              holding_options: '=',
                              keyword: searchKeyword,
                              page: 1,
                              pe_firm: [],
                              stake_type: [],
                            })
                          )?.json;
                          const suggestion_pepf = transformToSearchInputFormat(
                            resultPepf?.items,
                            'Company',
                            'id',
                            undefined
                          );
                          setSuggestionPepf(suggestion_pepf);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setPepf(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={pepf}
                    multiSelect={false}
                    selectedItem={pepf}
                    suggestionsList={suggestionPepf}
                  />
                </View>
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 2 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Company description */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: rowOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderTopWidth: 0,
                  borderWidth: 1,
                  flex: [
                    { minWidth: Breakpoints.Mobile, value: 0.3 },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: flexOnlySmallDevice(dimensions, 0.3),
                    },
                  ],
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Table Title'].style,
                  dimensions.width
                )}
              >
                {'Company description '}
              </Text>
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  flex: 0.7,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <AutoSizeInputBlock
                onChange={result => {
                  try {
                    setCompany_description(result);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Company description...'}
                value={company_description}
              />
            </View>
          </View>
          {/* Row 1 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Country */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Country *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newPickerValue => {
                      try {
                        setCountry(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    mode={'native'}
                    options={Constants['COUNTRY_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={country}
                  />
                </View>
              </View>
              {/* GICS */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'GICS *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultGics = (
                            await AdminGroupApi.getGicsSubIndustryGET(
                              Constants,
                              { keyword: searchKeyword }
                            )
                          )?.json;
                          const suggestion_gics = transformToSearchInputFormat(
                            resultGics,
                            'GICS_Sector',
                            'id',
                            'GICS_Sub_Industry'
                          );
                          setSuggestionGics(suggestion_gics);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setGics(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={gics}
                    multiSelect={false}
                    selectedItem={gics}
                    suggestionsList={suggestionGics}
                  />
                </View>
              </View>
            </View>
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Website */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Website '}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setWebsite(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={website}
                  />
                </View>
              </View>
              {/* Row */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Logo */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Logo URL'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setLogo_url(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={logo_url}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Peer Group */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Peer Group'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultPeerGroup = (
                            await AdminGroupApi.getPeerGroupGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const suggestion_peer_group =
                            transformToSearchInputFormat(
                              resultPeerGroup?.items,
                              'title',
                              'id',
                              undefined
                            );
                          setSuggestionPeerGroup(suggestion_peer_group);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setPeer_group(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={peer_group}
                    multiSelect={false}
                    selectedItem={peer_group}
                    suggestionsList={suggestionPeerGroup}
                  />
                </View>
              </View>
              {/* Source type */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderTopWidth: 0,
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Source type *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.base}
                    dropDownBorderColor={theme.colors.border.base}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newPickerValue => {
                      try {
                        setSource_type(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    mode={'native'}
                    options={Constants['SOURCE_TYPE_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={source_type}
                  />
                </View>
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 3 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Revenue */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Revenue (m) '}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setRevenue(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={revenue}
                    />
                  </View>
                </View>
                {/* EBITDA */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'EBITDA (m) '}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setEbitda(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={ebitda}
                    />
                  </View>
                </View>
              </View>
              {/* Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Currency */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Currency '}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.base}
                      dropDownBorderColor={theme.colors.border.base}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      onValueChange={newPickerValue => {
                        try {
                          setCurrency(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                      mode={'native'}
                      options={Constants['CURRENCY_OPTIONS']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['app picker'].style,
                        dimensions.width
                      )}
                      value={currency}
                    />
                  </View>
                </View>
                {/* Link to financials */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Link to financials'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setLink_financials(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={link_financials}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Growth */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                      textBreakStrategy={'simple'}
                    >
                      {'Growth (decimal, e.g. 20% = 0.2)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setGrowth(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={growth}
                    />
                  </View>
                </View>
                {/* End of fiscal year */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderTopWidth: 0,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'End of fiscal year'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <MonthYearPickerBlock
                      onChange={newMonthYearValue => {
                        try {
                          setFy_end(
                            (() => {
                              const e = newMonthYearValue;
                              console.log(e);
                              return e;
                            })()
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    />
                  </View>
                </View>
              </View>
              {/* Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: columnOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* Note for financials */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderTopWidth: 0,
                  borderWidth: 1,
                  flex: [
                    { minWidth: Breakpoints.Mobile, value: 0.3 },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: flexOnlySmallDevice(dimensions, 0.3),
                    },
                  ],
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Table Title'].style,
                  dimensions.width
                )}
              >
                {'Note for financials'}
              </Text>
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  flex: 0.7,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setNote_financials(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                  .props}
                multiline={true}
                numberOfLines={3}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Common TextInput'].style,
                  dimensions.width
                )}
                value={note_financials}
              />
            </View>
          </View>
        </Surface>
        {/* Surface 4 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 3 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* CF advisor */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'CF advisor'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        const resultAdvisors = (
                          await AdminGroupApi.getAdvisorsGET(Constants, {
                            keyword: searchKeyword,
                            page: 1,
                            per_page: 25,
                          })
                        )?.json;
                        const suggestion_advisors =
                          transformToSearchInputFormat(
                            resultAdvisors?.items,
                            'name',
                            'id',
                            undefined
                          );
                        setSuggestionAdvisors(suggestion_advisors);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onSelect={selectedResult => {
                    try {
                      setAdvisors_cf(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  multiSelect={true}
                  selectedItem={null}
                  selectedItems={advisors_cf}
                  suggestionsList={suggestionAdvisors}
                />
              </View>
            </View>
            {/* Legal advisor */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Legal advisor'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        const resultAdvisors = (
                          await AdminGroupApi.getAdvisorsGET(Constants, {
                            keyword: searchKeyword,
                            page: 1,
                            per_page: 25,
                          })
                        )?.json;
                        const suggestionAdvisors = transformToSearchInputFormat(
                          resultAdvisors?.items,
                          'name',
                          'id',
                          undefined
                        );
                        setSuggestionAdvisors(suggestionAdvisors);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onSelect={selectedResult => {
                    try {
                      setAdvisors_legal(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  multiSelect={true}
                  selectedItem={null}
                  selectedItems={advisors_legal}
                  suggestionsList={suggestionAdvisors}
                />
              </View>
            </View>
          </View>
          {/* Row 4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Transacted date */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Transacted date'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    borderTopWidth: { minWidth: Breakpoints.Tablet, value: 1 },
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  inline={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    try {
                      setTransacted(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  {...GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                    .props}
                  date={customFormatDate(transacted)}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                      .style,
                    dimensions.width
                  )}
                />
                <>
                  {!transacted ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          height: { minWidth: Breakpoints.Desktop, value: 60 },
                          justifyContent: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          position: {
                            minWidth: Breakpoints.Desktop,
                            value: 'absolute',
                          },
                          right: { minWidth: Breakpoints.Desktop, value: 10 },
                          width: { minWidth: Breakpoints.Desktop, value: 60 },
                        },
                        dimensions.width
                      )}
                    >
                      <IconButton
                        onPress={() => {
                          try {
                            setTransacted(null);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        color={StyleSheet.getWidthValue(
                          [
                            {
                              minWidth: Breakpoints.Desktop,
                              value: theme.colors.text.strong,
                            },
                          ],
                          dimensions.width
                        )}
                        icon={'Ionicons/close-circle'}
                      />
                    </View>
                  )}
                </>
              </View>
            </View>
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            />
          </View>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: 15,
                  textAlign: { minWidth: Breakpoints.Laptop, value: 'center' },
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(
                first_added,
                event,
                stage,
                company,
                other_events,
                timeline,
                owners,
                pepf,
                country,
                company_description,
                peer_group,
                gics,
                website,
                undefined,
                source_type,
                revenue,
                ebitda,
                growth,
                fy_end,
                currency,
                link_financials,
                note_financials,
                advisors_cf,
                advisors_legal,
                transacted,
                strat_or_invf_owners,
                logo_url
              );
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(CFSFormBlock);
