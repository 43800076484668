import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import EmptyListStateBlock from '../components/EmptyListStateBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as HoverableView from '../custom-files/HoverableView';
import * as ReadMoreText from '../custom-files/ReadMoreText';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const TrendingTopicListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [hoverDetail, setHoverDetail] = React.useState(null);
  const [isFocusCard, setIsFocusCard] = React.useState(false);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [numRecs, setNumRecs] = React.useState('');
  const [screenCode, setScreenCode] = React.useState('');
  const [trendingTopic, setTrendingTopic] = React.useState(false);
  const [trendingTopicList, setTrendingTopicList] = React.useState([]);
  const adminGroupDeleteCFSDELETE = AdminGroupApi.useDeleteCFSDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      /* hidden 'Set Variable' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('CFSNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          showAddButton={false}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              <>
                {!numRecs ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        theme.typography.body1,
                        { marginLeft: 20 }
                      ),
                      dimensions.width
                    )}
                  >
                    {numRecs}
                    {' records matching filter'}
                  </Text>
                )}
              </>
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Hover me */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.4 },
                        { minWidth: Breakpoints.Laptop, value: 0.3 },
                      ],
                      flexDirection: 'row',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                />
                {/* Latest obs. 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.4 },
                        { minWidth: Breakpoints.Laptop, value: 0.3 },
                      ],
                      flexDirection: 'row',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Latest obs.'}
                  </Text>
                </View>
                {/* Topic */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.5 },
                            { minWidth: Breakpoints.Laptop, value: 0.3 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Topic'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Previous Coverage */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                        { minWidth: Breakpoints.Laptop, value: 1 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Previous Coverage'}
                  </Text>
                </View>
                {/* Pipeline Item */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.5 },
                            { minWidth: Breakpoints.Laptop, value: 1 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Pipeline Item'}
                      </Text>
                    </View>
                  )}
                </>
                {/* #users (all, L360) */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                        { minWidth: Breakpoints.Laptop, value: 0.3 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'#users (all, L360)\n'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetTrendingTopicGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== trendingTopicList) {
                          setTrendingTopicList(fetchData?.json?.items);
                          setNumRecs(fetchData?.json?.itemsTotal);
                        } else {
                        }

                        /* hidden 'Log to Console' action */
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword_search={keywordSearch}
                  page={1}
                  refetchInterval={0}
                  refetchOnMount={true}
                  refetchOnReconnect={true}
                  refetchOnWindowFocus={false}
                  retry={true}
                  staleTime={0}
                >
                  {({ loading, error, data, refetchGetTrendingTopic }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <SimpleStyleFlatList
                          data={trendingTopicList}
                          decelerationRate={'normal'}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={
                            'Linear Gradient->Container->Table->Container->Table Content->Fetch->List'
                          }
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              console.log('List ON_END_REACHED Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(
                                  'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                                if (nextPage === null) {
                                  return console.log(
                                    'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:2 FETCH_REQUEST'
                                );
                                const newData = (
                                  await AdminGroupApi.getTrendingTopicGET(
                                    Constants,
                                    {
                                      keyword_search: keywordSearch,
                                      page: nextPage,
                                    }
                                  )
                                )?.json;
                                console.log(
                                  'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                  { newData }
                                );
                                console.log(
                                  'Start ON_END_REACHED:3 SET_VARIABLE'
                                );
                                setNextPage(newData?.nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:3 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:4 SET_VARIABLE'
                                );
                                setLastPage(newData?.pageTotal);
                                console.log(
                                  'Complete ON_END_REACHED:4 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                                );
                                if (
                                  fetchData?.items ===
                                  (0 || trendingTopicList !== fetchData?.items)
                                ) {
                                  return console.log(
                                    'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:6 SET_VARIABLE'
                                );
                                setTrendingTopicList(
                                  trendingTopicList.concat(newData?.items)
                                );
                                console.log(
                                  'Complete ON_END_REACHED:6 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'List ON_END_REACHED Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          pagingEnabled={false}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    alignSelf: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'auto',
                                    },
                                    backgroundColor: getColorByIndex(index),
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: 1,
                                    flexDirection: 'row',
                                    width: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 1200,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Hover me */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.3,
                                            },
                                          ],
                                          height: {
                                            minWidth: Breakpoints.Laptop,
                                            value: '100%',
                                          },
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Utils.CustomCodeErrorBoundary>
                                        <HoverableView.Index
                                          onMouseEnter={() => {
                                            setHoverDetail(listData);
                                          }}
                                          onMouseLeave={() => {
                                            setHoverDetail(null);
                                          }}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignContent: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                alignItems: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                backgroundColor: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App[
                                                      'GetFit Orange'
                                                    ],
                                                },
                                                borderRadius: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 40,
                                                },
                                                height: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 40,
                                                },
                                                justifyContent: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                width: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 40,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Table Row'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Table Row'].style,
                                                  {
                                                    alignSelf: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'center',
                                                    },
                                                    color: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value:
                                                        theme.colors.background
                                                          .brand,
                                                    },
                                                    fontFamily: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value:
                                                        'Quicksand_600SemiBold',
                                                    },
                                                    fontSize: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 12,
                                                    },
                                                    textAlign: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'center',
                                                    },
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Notes'}
                                            </Text>
                                          </View>
                                        </HoverableView.Index>
                                      </Utils.CustomCodeErrorBoundary>
                                    </View>
                                  )}
                                </>
                                {/* timestamp_latest 2 */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.3,
                                            },
                                          ],
                                          height: {
                                            minWidth: Breakpoints.Laptop,
                                            value: '100%',
                                          },
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {formatTimestampToCopenhagen(
                                          listData?.timestamp_latest,
                                          undefined
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* topic */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.5,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.3,
                                        },
                                      ],
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.topic}
                                  </Text>
                                </View>
                                {/* previous_coverage_headlines */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 1,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Utils.CustomCodeErrorBoundary>
                                        <ReadMoreText.Index>
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              listData?.previous_coverage_headlines
                                            }
                                          </Text>
                                        </ReadMoreText.Index>
                                      </Utils.CustomCodeErrorBoundary>
                                    </View>
                                  )}
                                </>
                                {/* pipeline_item_title */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 1,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Utils.CustomCodeErrorBoundary>
                                        <ReadMoreText.Index>
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.pipeline_item_title}
                                          </Text>
                                        </ReadMoreText.Index>
                                      </Utils.CustomCodeErrorBoundary>
                                    </View>
                                  )}
                                </>
                                {/* user_all_countries_count_l360d */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.3,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          listData?.user_all_countries_count_l360d
                                        }
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            );
                          }}
                          snapToAlignment={'start'}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value:
                                  dimensions.width >= Breakpoints.Laptop
                                    ? 0
                                    : undefined,
                              },
                              maxHeight: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: dimensions.height - 250,
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: dimensions.height - 280,
                                },
                              ],
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        <>
                          {!(trendingTopicList?.length === 0) ? null : (
                            <EmptyListStateBlock />
                          )}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetTrendingTopicGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteCFS = (
                  await adminGroupDeleteCFSDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteCFS?.error) {
                  toastMessage(resultDeleteCFS?.message, 'error');
                } else {
                  toastMessage('Delete CFS successfully', 'success');
                  setDeletingItem(null);
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'CFS'}
          visible={deletingItem !== null}
        />
        {/* Modal Hover Detail */}
        <>
          {!hoverDetail ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.White,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App['Custom Color_18'],
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                  borderWidth: { minWidth: Breakpoints.Laptop, value: 2 },
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 25 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 25 },
                  position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
                  top: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.height / 3,
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '35%' },
                },
                dimensions.width
              )}
            >
              {/* Column */}
              <View
                style={StyleSheet.applyWidth(
                  { gap: { minWidth: Breakpoints.Laptop, value: 12 } },
                  dimensions.width
                )}
              >
                {/* Row */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (all, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_all_countries_count_l360d}
                  </Text>
                </View>
                {/* Row 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (DK, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_dk_count_l360d}
                  </Text>
                </View>
                {/* Row 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (SE, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_se_count_l360d}
                  </Text>
                </View>
                {/* Row 4 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (NO, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_no_count_l360d}
                  </Text>
                </View>
                {/* Row 5 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (FI, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_fi_count_l360d}
                  </Text>
                </View>
                {/* Row 6 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (DE, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_de_count_l360d}
                  </Text>
                </View>
                {/* Row 7 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (AT, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_at_count_l360d}
                  </Text>
                </View>
                {/* Row 8 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (RoW L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_row_count_l360d}
                  </Text>
                </View>
                {/* Row 9 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      gap: { minWidth: Breakpoints.Laptop, value: 6 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'#users (all, L360D):'}
                  </Text>
                  {/* Value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    selectable={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {hoverDetail?.user_dk_count_l360d}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(TrendingTopicListingScreen);
